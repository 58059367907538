import React, { useState } from 'react';

import CardCenter from '@components/Card/CardCenter';

import { ReactComponent as Cloche } from '@img/icons/cloche.svg';
import { ReactComponent as Email } from '@img/icons/email.svg';
import { ReactComponent as MDP } from '@img/icons/mot_de_passe.svg';

import './createAccount.scss';
import Step1 from './step1';
import Step2 from './step2/step2';
import Step3 from './step3';

export default function CreateAccount() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const stepList = {
    Step1: Step1,
    Step2: Step2,
    Step3: Step3,
  };

  const StepComponent = stepList[`Step${step}`];

  const nextStep = () => {
    setStep(step + 1);
  };

  const goToStep = (nb) => {
    setStep(nb);
  };

  return (
    <CardCenter
      col_class="create-account min-height-full-with-navbar flex-full-centered"
      data-cy="Card-createAccount"
    >
      <div className="card-header">
        <div className="steps">
          <div className={`step ${step >= 1 && 'active'}`}>
            <Email className="stroke-primary" />
          </div>
          <div className={`separator ${step >= 1 && 'active'}`}></div>
          <div className={`step ${step >= 2 && 'active'}`}>
            <MDP className="stroke-primary" />
          </div>
          <div className={`separator ${step >= 2 && 'active'}`}></div>
          <div className={`step ${step >= 3 && 'active'}`}>
            <Cloche className="stroke-primary" />
          </div>
        </div>
      </div>
      <div className="card-body w-90">
        <StepComponent
          nextStep={nextStep}
          goToStep={goToStep}
          setEmail={setEmail}
          setPassword={setPassword}
          getEmail={email}
          getPassword={password}
        />
      </div>
    </CardCenter>
  );
}
