import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button } from '../../atoms/Button/Button';

import './Notification.scss';

export const Notification = ({
  title,
  text,
  link,
  linkText,
  targetBlank = false,
  icon,
  borderColor = 'start-color',
  hasCloseButton = false,
  isOpen = true,
  onClose,
  ...props
}) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  if (!open) {
    return null;
  }

  return (
    <div
      className={`notification-ec card border-start h-100 border-${borderColor} ${
        hasCloseButton ? 'has-close-button' : ''
      }`}
      {...props}
    >
      <div className="card-body">
        <div className="row no-gutters align-items-md-center h-100 w-100">
          {icon && <div className="col-auto">{icon}</div>}
          <div
            className={`${
              icon ? 'col me-2' : ''
            } d-flex content-container flex-column flex-md-row align-items-baseline align-items-md-center`}
          >
            <div className="text">
              {title && (
                <div className="title h5 mb-0 fw-bold text-primary">
                  {title}
                </div>
              )}
              {text && (
                <div className="text-content text-xs text-primary text-uppercase font-italic">
                  <div dangerouslySetInnerHTML={{ __html: text }} />
                </div>
              )}
            </div>
            {link && linkText && (
              <Link
                to={{ pathname: link }}
                target={targetBlank ? '_blank' : ''}
              >
                <Button
                  label={linkText}
                  classNames="btn-block mt-3 mt-md-0"
                  type="primary"
                />
              </Link>
            )}
            {hasCloseButton && (
              <div className="close">
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    setOpen(false);
                    if (onClose) {
                      onClose();
                    }
                  }}
                ></button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  targetBlank: PropTypes.bool,
  icon: PropTypes.element,
  borderColor: PropTypes.string,
  hasCloseButton: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

Notification.defaultProps = {
  title: '',
  text: '',
  link: '',
  linkText: '',
  targetBlank: false,
  icon: null,
  borderColor: 'start-color',
  hasCloseButton: false,
  isOpen: true,
  onClose: null,
};
