import React from 'react';

import { ReactComponent as Loader } from '@img/loading.svg';

import './Loader.scss';

export default function LoaderComponent() {
  return (
    <div className="loader">
      <Loader className="img-fluid w-100 h-100" alt="Responsive image" />
    </div>
  );
}
