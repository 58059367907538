import React, { createContext, useState } from 'react';

const UserContext = createContext();

export { UserContext };

const UserContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const changeUserName = (prenom = user.prenom, nom = user.nom) => {
    let userState = { ...user };
    userState.prenom = prenom;
    userState.nom = nom;
    return setUser(userState);
  };

  return (
    <UserContext.Provider value={{ user, setUser, changeUserName }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
