import React, { useEffect, useState } from 'react';

import LoadingButton from '@components/LoadingButton/LoadingButton';
import Modal from '@components/Modal/ModalDefault';

import { useSkynetEmailPost } from '@hooks/useApi';

export default function ModalContactCDC({
  show,
  handleClose,
  clickedSubvention,
  returnModalContactCDC,
}) {
  const [textareaValue, setTextareaValue] = useState(``);

  useEffect(() => {
    if (clickedSubvention) {
      if (clickedSubvention.label === 'Chèque cadeau') {
        setTextareaValue(
          `Bonjour,\n\nJe rencontre des difficultés pour créer une campagne de chèque cadeau. Pouvez-vous m’aider à créer une nouvelle campagne ?\n\nCordialement`
        );
      } else {
        setTextareaValue(
          `Bonjour\n\nNotre CSE est intéressé ${
            clickedSubvention ? clickedSubvention.value.texte_mail : ''
          }. Pouvez-vous me contacter afin de m'en parler plus en détail ?\n\nCordialement`
        );
      }
    }
  }, [clickedSubvention]);

  const { mutate: sendMailMutation, isLoading: loadingSendMail } =
    useSkynetEmailPost({
      onSuccess: () => {
        handleClose();
      },
    });

  const sendMail = (e) => {
    let data = { motif: clickedSubvention.label, content: textareaValue };
    e.preventDefault();

    sendMailMutation({
      typeMail: 'cdc',
      data: data,
    });
  };

  return (
    <Modal
      show={show}
      handleClose={handleClose}
      handleReturn={() =>
        returnModalContactCDC(
          clickedSubvention.label === 'Chèque cadeau' ? '' : 'subvention'
        )
      }
    >
      <div className="text-primary modal-subvention">
        <h2 className="fw-bold text-primary">
          Contacter votre chargé de clientèle
        </h2>
        <form autoComplete="off" onSubmit={sendMail} className="text-primary">
          <div className="form-group">
            <label htmlFor="formControlTextarea1">
              <small>Saisissez votre message (obligatoire)</small>
            </label>
            <textarea
              className="form-control h3 text-primary"
              id="formControlTextarea1"
              rows="9"
              value={textareaValue}
              required
              onChange={(e) => setTextareaValue(e.target.value)}
            ></textarea>
          </div>
          <div className="text-center">
            <LoadingButton
              type="submit"
              classes="btn btn-primary rounded-pill fw-bold"
              text="Envoyer"
              loading={loadingSendMail}
              spinnerHeight="20"
              disabled={textareaValue.trim() === '' ? true : false}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}
