import React, { useState, useEffect } from 'react';

import $ from 'jquery';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import Feature from '@components/Feature/Feature';

import { ReactComponent as User } from '@img/icons/user.svg';

export default function Interlocuteurs({
  infoProject,
  infoResponsable,
  hasProject,
  setTitleModal,
  setTypeMail,
  setShowModalContact,
}) {
  const [numberSlide, setNumberSlide] = useState(0);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: numberSlide,
    slidesToScroll: 1,
    centerMode: false,
    enterPadding: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 667,
      },
      {
        breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
        settings: 'unslick',
      },
    ],
  };
  useEffect(() => {
    handleResize();

    function handleResize() {
      let containerWidth = $('.interlocuteurs').width();
      if (containerWidth !== 0) {
        $('.contact').outerWidth(containerWidth / 2 - 16);
        let itemLength = $('.contact').length;
        let itemWidth = $('.contact').outerWidth(true);
        let numberSlideCalc = containerWidth / itemWidth;
        setNumberSlide(
          numberSlideCalc > itemLength ? itemLength : numberSlideCalc
        );
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="col-12 interlocuteurs">
      <h5 className="fw-bold text-primary rect_info_title">
        Mes interlocuteurs
      </h5>
      <div className="h-100">
        <Slider {...settings} className={`slider slider-contacts contacts`}>
          <div className="contact">
            <div className="bg-info badge-ec">Vie de votre contrat</div>
            <div className="name">
              {infoResponsable ? (
                infoResponsable.prenom + ' ' + infoResponsable.nom
              ) : (
                <Skeleton color="#e256e6" highlightColor="#444" width={160} />
              )}
            </div>
            <div className="job">Chargé de clientèle</div>
            <div className="d-flex buttons">
              <button
                type="button"
                className="btn btn-primary rounded-pill"
                onClick={() => {
                  setTitleModal('Contacter votre chargé de clientèle');
                  setTypeMail('cdc');
                  setShowModalContact(true);
                }}
              >
                Contacter
              </button>
              <Feature name="RENDEZ_VOUS">
                <Link
                  to={{ pathname: `/appointment/create` }}
                  className="btn btn-primary rounded-pill ms-2"
                >
                  Rendez-vous
                </Link>
              </Feature>
            </div>
            <User />
          </div>
          {hasProject && (
            <div className="contact">
              <div className="bg-info badge-ec">Gestion de votre projet</div>
              <div className="name">
                {infoProject ? (
                  infoProject.leader.prenom + ' ' + infoProject.leader.nom
                ) : (
                  <Skeleton color="#e256e6" highlightColor="#444" width={160} />
                )}
              </div>
              <div className="job">Chef de projet</div>
              <button
                type="button"
                className="btn btn-primary rounded-pill"
                onClick={() => {
                  setTitleModal('Contacter votre chef de projet');
                  setTypeMail('cdp');
                  setShowModalContact(true);
                }}
              >
                Contacter
              </button>
              <User />
            </div>
          )}
        </Slider>
      </div>
    </div>
  );
}
