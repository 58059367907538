import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { pushToDataLayer } from '@helpers';

export default function GoogleTagManagement({ titlePage }) {
  let location = useLocation();
  const { dataLayer } = window;

  useEffect(() => {
    if (titlePage) {
      const filterDataLayer = dataLayer.filter(
        (item) => item.event === 'pageview'
      );
      filterDataLayer.sort((a, b) =>
        a['gtm.uniqueEventId'] < b['gtm.uniqueEventId']
          ? 1
          : b['gtm.uniqueEventId'] < a['gtm.uniqueEventId']
          ? -1
          : 0
      );
      if (
        filterDataLayer.length === 0 ||
        filterDataLayer[0].page.title !== `${titlePage}`
      ) {
        pushToDataLayer({ event: 'pageview', page: { title: `${titlePage}` } });
      }
    }
  }, [location, titlePage]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
