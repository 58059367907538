import React, { useState, useContext, useEffect } from 'react';

import dayjs from 'dayjs';
import $ from 'jquery';
import { createRoot } from 'react-dom/client';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Table from '@components/Table/TableDefault';

import { ToggleFeatureContext } from '@context/ToggleFeatureContext';

import { useSkynetReviewCriterias } from '@hooks/useApi';

import CalendarEmpty from '@img/icons/calendar_empty.svg';
import ClockIcon from '@img/icons/clock.svg';
import Distance from '@img/icons/distance.svg';
import EmojiBien from '@img/icons/emoji-bien.svg';
import EmojiGenial from '@img/icons/emoji-genial.svg';
//Emoji
import EmojiHorrible from '@img/icons/emoji-horrible.svg';
import EmojiPasTop from '@img/icons/emoji-pas-top.svg';
import EmojiTresBien from '@img/icons/emoji-tres-bien.svg';
import Presentiel from '@img/icons/presentiel.svg';
import { ReactComponent as Question } from '@img/icons/question.svg';
import Rappel from '@img/icons/rappel.svg';
import User from '@img/icons/user-gray.svg';

import ModalCongrat from '../modalOpinionAppointment/modalCongratOpinionAppointment';
import ModalOpinion from '../modalOpinionAppointment/modalOpinionAppointment';

import './rdv_passes.scss';

export default function RdvPasses({ data, setData, loading }) {
  const [datatableState, setDatatableState] = useState(null); // eslint-disable-line no-unused-vars
  const [showModalOpinion, setShowModalOpinion] = useState(false);
  const [showModalCongrat, setShowModalCongrat] = useState(false);
  const [dataRDVOpinion, setDataRDVOpinion] = useState(null);
  const { toggleFeature } = useContext(ToggleFeatureContext);
  const tooltipsRef = React.useRef([]);
  const history = useHistory();

  const { data: dataReviewCriterias } = useSkynetReviewCriterias();

  useEffect(() => {
    return () => {
      setTimeout(() => {
        tooltipsRef?.current?.forEach((tooltip) => {
          tooltip?.unmount();
        });
      });
    };
  }, []);

  const columns = [
    {
      title: 'Date',
      data: 'dateDebut',
      className: 'dt-date dt-left',
      type: 'date-euro',
      render: function (data, type, row) {
        const date = dayjs(data).format('DD/MM/YYYY');
        return date !== 'Invalid date'
          ? '<span class="d-flex"><span class="d-none">' +
              data +
              '</span><img src=' +
              CalendarEmpty +
              ' />' +
              date +
              '</span>'
          : '';
      },
    },
    {
      title: 'Hour',
      data: 'dateDebut',
      className: 'dt-left',
      orderable: false,
      // width: "125px",
      render: function (data, type, row) {
        const date = dayjs(data).format('HH:mm');
        return date !== 'Invalid date'
          ? '<span class="d-flex"><span class="d-none">' +
              data +
              '</span><img src="' +
              ClockIcon +
              '"/>' +
              date +
              '</span>'
          : '';
      },
      createdCell: function (td, cellData, rowData, row, col) {
        $(td).attr('data-search', cellData);
      },
    },
    {
      title: 'Format',
      data: 'sousTypeAction',
      className: 'dt-left',
      orderable: false,
      // width: "125px",
      createdCell: function (td, cellData, rowData, row, col) {
        $(td).attr('data-search', cellData);

        let image = Rappel;
        let text = rowData.description;
        if (cellData === 'RDV à distance') {
          image = Distance;
        }
        if (cellData === 'RDV physique') {
          image = Presentiel;
        }

        const tooltip = createRoot(td);

        tooltipsRef.current.push(tooltip);

        tooltip.render(
          cellData && (
            <span className="me-1 d-flex align-items-center">
              <img
                src={image}
                alt="logo type rendez-vous"
                data-tip
                data-for="type_rdv_tooltip"
              />
              <ReactTooltip
                id="type_rdv_tooltip"
                place="top"
                type="info"
                effect="solid"
                className="reactTooltipRDV"
              >
                <span>{cellData}</span>
              </ReactTooltip>
              {text.length && text}
            </span>
          )
        );
      },
    },
    {
      title: 'interlocuteur',
      data: 'utilisateursAssignes',
      className: 'dt-left',
      orderable: false,
      width: '185px',
      render: function (data, type, row) {
        return '<span class="me-1 d-flex"><img src="' + User + '"/>' + data[0];
      },
      createdCell: function (td, cellData, rowData, row, col) {
        $(td).attr('data-search', cellData);
      },
    },
    {
      title: 'Action',
      data: null,
      orderable: false,
      className: `dt-right button_rdv ${
        toggleFeature !== null && toggleFeature['AVIS_CLIENT']
          ? ' has-opinion'
          : ''
      }`,
      createdCell: (td, cellData, rowData, row, col) =>
        createRoot(td).render(
          <div
            className={`d-flex justify-content-around justify-content-xl-end bill-actions `}
          >
            {toggleFeature !== null &&
              toggleFeature['AVIS_CLIENT'] &&
              (!rowData.note ? (
                <button
                  type="button"
                  className="btn btn-white-border rounded-pill"
                  onClick={() => {
                    setShowModalOpinion(true);
                    setDataRDVOpinion(rowData);
                  }}
                  style={{
                    maxWidth: '130px',
                    whiteSpace: 'nowrap',
                  }}
                  data-cy="note-appointment-button"
                >
                  Donner mon avis
                </button>
              ) : (
                // getDivEmoji(rowData.note)
                ''
              ))}
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onClickButton()}
              style={{
                maxWidth: '190px',
                marginLeft: '10px',
                whiteSpace: 'nowrap',
              }}
            >
              Reprendre un rendez-vous
            </button>
          </div>
        ),
    },
  ];

  const onClickButton = () => {
    history.push('/appointment/create');
  };

  const getDivEmoji = (number) => {
    switch (number) {
      case 1:
        return (
          <span className="emoji">
            <img src={EmojiHorrible} alt="Emoji Horrible" />
            Horrible
          </span>
        );
      case 2:
        return (
          <span className="emoji">
            <img src={EmojiPasTop} alt="Emoji Pas Top" />
            Pas top...
          </span>
        );
      case 3:
        return (
          <span className="emoji">
            <img src={EmojiBien} alt="Emoji Bien" />
            Bien
          </span>
        );
      case 4:
        return (
          <span className="emoji">
            <img src={EmojiTresBien} alt="Emoji Tres Bien" />
            Très bien
          </span>
        );
      case 5:
        return (
          <span className="emoji">
            <img src={EmojiGenial} alt="Emoji Genial" />
            Génial !
          </span>
        );
      default:
        return '';
    }
  };

  return (
    <div className="row mt-2 rdv_passes">
      <div className="col-12 rect_info rect_info_search_bill">
        <div className="col-12">
          {loading ? (
            <div className="col-12 skeleton">
              <SkeletonTheme width="100%">
                <section className="d-flex flex-column align-items-center justify-center w-100">
                  <Skeleton height={60} className="mb-2" />
                  <Skeleton height={34} count={5} className="mb-1 line-table" />
                </section>
              </SkeletonTheme>
            </div>
          ) : (
            <div className="col-12 p-md-0">
              <h5 className="fw-bold text-primary rect_info_title">
                Rendez-vous passés
                <Question
                  className="stroke-primary questionIcon not-disabled"
                  data-tip
                  data-for="questionTypeContact"
                />
                <ReactTooltip
                  id="questionTypeContact"
                  place="right"
                  type="info"
                  effect="solid"
                  className="reactTooltipRDV"
                >
                  <span>Vos 20 derniers rendez-vous sont affichés ici.</span>
                </ReactTooltip>
              </h5>
              <div className="content">
                {data && (
                  <Table
                    data={data}
                    columns={columns}
                    setDatatable={setDatatableState}
                    name="rdv_passes_table thead-disabled text-primary"
                    pageLength={5}
                    // responsive={false}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalOpinion
        showModal={showModalOpinion}
        setShowModal={setShowModalOpinion}
        setShowModalCongrat={setShowModalCongrat}
        dataRDVOpinion={dataRDVOpinion}
        setData={setData}
        questions={dataReviewCriterias}
      />
      <ModalCongrat
        showModal={showModalCongrat}
        setShowModal={setShowModalCongrat}
      />
    </div>
  );
}
