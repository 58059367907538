import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { DomainAvailabilityChecker } from '@components/DomainAvailabilityChecker';

import './Preparation.scss';

export default function Preparation({
  infoProject,
  equipmentData,
  setShowModalContact,
  setShowModalSendRequest,
  setIsNomDomaine,
  setSelectedDomain,
  isNddRequestSent,
}) {
  const [isDomainAvailable, setIsDomainAvailable] = useState(null);
  const mailSent = false;
  const [platform, setPlatform] = useState('');

  useEffect(() => {
    if (infoProject !== null) {
      /v12/i.test(infoProject.plateform.name) && setPlatform('V12');
      /p50/i.test(infoProject.plateform.name) && setPlatform('P50');
    }
  }, [infoProject]);

  const stepPreparationRender = (
    <div className="project-content col-12 bg-white preparation">
      <h5 className="rect_info_title" data-cy="project-preparation-title">
        Préparation
      </h5>
      <p>
        Pour commencer votre déploiement, merci de bien vouloir préparer les
        éléments numérotés ci-dessous et de nous les transmettre en validant
        l'étape en bas de page.
      </p>

      <ul className="timeline" data-cy="timeline">
        <li data-cy="beneficiaries">
          <span className="number">1</span>
          <div className="title">Ma base de bénéficiaires</div>
          <p>
            Téléchargez le modèle du fichier Excel et envoyez-le à votre DRH/RH
            pour récupérer les noms, prénoms et emails professionnels des
            salariés. Les emails sont très importants, ils nous permettront
            d'envoyer aux salariés leurs identifiants et mots de passe pour
            accéder à votre site en toute sécurité.
          </p>
          <Link
            to={`${
              equipmentData !== null &&
              `documents/Imports_Users%20${platform}.xlsx`
            }`}
            target="_blank"
            rel="noopener noreferrer"
            download
            className={`btn btn-primary rounded-pill action ${
              equipmentData === null && 'disabled'
            }`}
            data-cy="download-model"
          >
            Télécharger le modèle
          </Link>
        </li>
        <li data-cy="ndd-choice">
          <span className="number">2</span>
          <div className="title">Choix du nom de domaine</div>
          <p>
            Choisissiez le nom de domaine de votre site internet et vérifiez
            s'il est disponible en temps réel. Nous vous conseillons de choisir
            un nom simple et facilement mémorisable par les salariés.
          </p>

          <DomainAvailabilityChecker
            setSelectedDomain={setSelectedDomain}
            isAvailable={isDomainAvailable}
            setIsAvailable={setIsDomainAvailable}
          />

          {isDomainAvailable === true && !mailSent && (
            <>
              <p className="mt-2">
                Bonne nouvelle ! Le nom de domaine que vous avez choisi est
                disponible. <br />
                Vous pouvez dès à présent télécharger le formulaire de création,
                le remplir avec ce dernier et le signer.
              </p>
              <>
                <Link
                  to="documents/Depot-nom-de-domaine.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  className="btn btn-white-border rounded-pill action mb-2 me-2"
                  data-cy="download-ndd-form"
                >
                  Télécharger le formulaire
                </Link>
              </>
            </>
          )}
          {mailSent && (
            <h5 className="mt-4">Votre message a bien été envoyé.</h5>
          )}
        </li>
        <li data-cy="design-and-homepage">
          <span className="number">3</span>
          <div className="title">Design et page d'accueil du site</div>
          <p>
            Consulter les thèmes disponibles pour votre site web. Vous pourrez
            indiquer votre choix au moment de valider vos informations.
          </p>
          <Link
            to={`${
              equipmentData !== null &&
              `documents/projet/CATALOGUE%20${platform}.pdf`
            }`}
            target="_blank"
            rel="noopener noreferrer"
            data-cy="download-catalogue"
            download
            className={`btn btn-primary rounded-pill action ${
              equipmentData === null && 'disabled'
            }`}
          >
            Voir les différents designs
          </Link>
        </li>
        <li data-cy="informations">
          <span className="number">4</span>
          <div className="title">Envoyez vos informations</div>
          <p>
            Une fois les précédents points traités, cliquez sur le bouton
            ci-dessous pour nous transmettre vos informations et fichiers.
          </p>
          <button
            type="button"
            onClick={() => {
              setShowModalSendRequest(true);
              setIsNomDomaine(true);
            }}
            className="btn btn-primary rounded-pill action mb-2"
            data-cy="send-informations"
            disabled={!isDomainAvailable}
          >
            Envoyer vos informations
          </button>
        </li>
      </ul>
    </div>
  );

  const messageRender = (
    <div className="project-content col-12 bg-white preparation">
      <h5 className="rect_info_title">Préparation</h5>
      <p>
        Votre demande a été transmise à votre chargé(e) de projet qui vous
        contactera rapidement afin de poursuivre votre déploiement. Si besoin
        vous pouvez le contacter par email en cliquant sur ce bouton.
      </p>
      <div className="text-center">
        <button
          type="button"
          onClick={() => setShowModalContact(true)}
          className="btn rounded-pill btn-white-border contact-mail"
        >
          Contacter par e-mail
        </button>
      </div>
    </div>
  );

  return isNddRequestSent ? messageRender : stepPreparationRender;
}
