import React, { createContext, useState, useEffect } from 'react';

const CreateAppointmentContext = createContext();

export { CreateAppointmentContext };

const CreateAppointmentProvider = (props) => {
  const [step, setStep] = useState(null);
  const [motif, setMotif] = useState(null);
  const [format, setFormat] = useState(null);
  const [date, setDate] = useState(null);
  const [telephone, setTelephone] = useState(null);
  const [loading, setLoading] = useState(true);
  const tabOrdreDistanciel = [1, 2, 3, 4, 5];
  const tabOrdrePresentiel = [1, 2, 3, 3.5, 4, 5];

  const getPreviousStep = () => {
    if (format) {
      if (format === 0) {
        // distanciel
        const indexStepDistanciel = tabOrdreDistanciel.findIndex(
          (stepItem) => stepItem === step
        );
        return tabOrdreDistanciel[indexStepDistanciel - 1];
      } else {
        const indexStepPresentiel = tabOrdrePresentiel.findIndex(
          (stepItem) => stepItem === step
        );
        return tabOrdrePresentiel[indexStepPresentiel - 1];
      }
    } else {
      return step - 1;
    }
  };

  const getNextStep = () => {
    if (format) {
      if (format === 0) {
        // distanciel
        const indexStepDistanciel = tabOrdreDistanciel.findIndex(
          (stepItem) => stepItem === step
        );
        return tabOrdreDistanciel[indexStepDistanciel + 1];
      } else {
        const indexStepPresentiel = tabOrdrePresentiel.findIndex(
          (stepItem) => stepItem === step
        );
        return tabOrdrePresentiel[indexStepPresentiel + 1];
      }
    } else {
      return step + 1;
    }
  };

  const goToStep = (step) => {
    if (typeof step === 'number') setStep(step);
    else {
      switch (step.toLowerCase()) {
        case 'motif':
          setStep(1);
          break;
        case 'rendez-vous':
          setStep(2);
          break;
        case 'telephone':
          setStep(2.5);
          break;
        case 'recapitulatif':
          setStep(3);
          break;
        case 'rdv_pris':
          setStep(4);
          break;
        default:
          setStep(1);
      }
    }
  };

  const goToPreviousStep = () => {
    goToStep(getPreviousStep());
  };

  const goToNextStep = () => {
    goToStep(getNextStep());
  };

  useEffect(() => {
    if (localStorage.getItem('create-appointment-data') !== null) {
      let dataStorage = JSON.parse(
        localStorage.getItem('create-appointment-data')
      );

      setStep(dataStorage.step);
      setMotif(dataStorage.motif);
      setFormat(dataStorage.format);
      setDate(dataStorage.date);
    } else {
      setStep(1);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (loading === false) {
      let dataStorage = {
        step: step,
        motif: motif,
        format: format,
        date: date,
      };

      localStorage.setItem(
        'create-appointment-data',
        JSON.stringify(dataStorage)
      );
    }
  }, [step, motif, format, date, loading]);

  return (
    <CreateAppointmentContext.Provider
      value={{
        step,
        goToStep,
        motif,
        setMotif,
        format,
        setFormat,
        date,
        setDate,
        telephone,
        setTelephone,
        getPreviousStep,
        goToPreviousStep,
        getNextStep,
        goToNextStep,
      }}
    >
      {props.children}
    </CreateAppointmentContext.Provider>
  );
};

export default CreateAppointmentProvider;
