import React, { useState, useEffect } from 'react';

import Select from 'react-select';

import { SelectList } from '@molecules';

import LoadingButton from '@components/LoadingButton/LoadingButton';
import Modal from '@components/Modal/ModalDefault';

import { useSkynetEmailPost } from '@hooks/useApi';

export default function ModalContact({
  titleModal,
  typeMail,
  showModalContact,
  setShowModalSuccess,
  setShowModalContact,
}) {
  const [textareaValue, setTextareaValue] = useState('');
  const [motifSelect, setMotifSelect] = useState('');
  const [sousMotifSelect, setSousMotifSelect] = useState('');
  const [options, setOptions] = useState([]);
  const [optionsSousMotif, setOptionsSousMotif] = useState([]);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);

  const optionsCDC = require('@datas/motifsCDC.json');
  const optionsCDP = require('@datas/motifsCDP.json');

  useEffect(() => {
    typeMail === 'cdc' ? setOptions(optionsCDC) : setOptions(optionsCDP);
    if (typeMail === 'cdp') {
      setMotifSelect(optionsCDP[0] ?? '');
    }
  }, [typeMail]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (typeMail === 'cdp') {
      setButtonIsDisabled(motifSelect === '' || textareaValue?.trim() === '');
    } else {
      textareaValue.trim() === '' ||
      motifSelect === '' ||
      sousMotifSelect === ''
        ? setButtonIsDisabled(true)
        : setButtonIsDisabled(false);
    }
  }, [textareaValue, motifSelect, sousMotifSelect]);

  const onChangeSelect = (e) => {
    setMotifSelect(e);
    setOptionsSousMotif(e.sousMotifs || []);
    setSousMotifSelect('');
  };

  const onChangeSousMotif = (e) => {
    setSousMotifSelect(e);
  };

  const { mutate: sendMailMutation, isLoading: isSendMailLoading } =
    useSkynetEmailPost({
      onSuccess: () => {
        setShowModalContact(false);
        setShowModalSuccess(true);
        resetModal();
      },
    });

  const sendMail = () => {
    let data = { motif: motifSelect.value, content: textareaValue };
    if (typeMail === 'cdc') {
      data = {
        motif: motifSelect.value,
        sous_motif: sousMotifSelect.value,
        content: textareaValue,
      };
    }

    sendMailMutation({
      typeMail: typeMail,
      data: data,
    });
  };

  const resetModal = () => {
    setMotifSelect('');
    setSousMotifSelect('');
    setTextareaValue('');
  };

  return (
    <Modal
      show={showModalContact}
      handleClose={() => {
        setShowModalContact(false);
        resetModal();
      }}
    >
      <div className="">
        <h2 className="fw-bold text-primary">{titleModal}</h2>
        <h6 className="text-primary">Tous les champs sont obligatoires.</h6>
        <form action="" autoComplete="off" className="text-primary">
          <div className="form-group">
            <label htmlFor="inputFonction">
              <small>Sélectionner un motif</small>
            </label>
            <SelectList
              options={options}
              onChange={onChangeSelect}
              value={motifSelect || ''}
              placeholder="Service souhaité"
              className="text-primary "
            />
          </div>
          {typeMail === 'cdc' && (
            <div className="form-group">
              <label htmlFor="inputFonction">
                <small>Sélectionner un sous-motif</small>
              </label>
              <SelectList
                options={optionsSousMotif}
                onChange={onChangeSousMotif}
                value={sousMotifSelect || ''}
                placeholder="Pour quel motif ?"
                className="text-primary "
                noOptionsMessage={() =>
                  'Veuillez selectionner un service souhaité !'
                }
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="formControlTextarea1">
              <small>Contacez votre chargé de clientèle</small>
            </label>
            <textarea
              className="form-control h3 text-primary"
              id="formControlTextarea1"
              rows="9"
              value={textareaValue}
              onChange={(e) => setTextareaValue(e.target.value)}
              required
            ></textarea>
          </div>
          <div className="text-center">
            <LoadingButton
              type="button"
              classes="btn btn-primary rounded-pill fw-bold"
              text="Envoyer"
              loading={isSendMailLoading}
              onClick={sendMail}
              spinnerHeight="20"
              disabled={buttonIsDisabled}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}
