import React from 'react';

import Password from '@components/Password/Password';

import GoogleTagManagement from '@GoogleTagManagement';

import './step2MDP.scss';

export default function Step2MDP(props) {
  const handleSubmit = () => {
    props.valideMDP();
  };

  return (
    <div className="card-creation-mdp">
      <GoogleTagManagement titlePage="Saisie du mot de passe" />
      <h4 className="card-title fw-bold">Création du mot de passe</h4>
      <Password setPassword={props.setPassword} handleSubmit={handleSubmit} />
    </div>
  );
}
