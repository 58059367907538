import React, { useState, useContext, useEffect } from 'react';

import { Link } from 'react-router-dom';

import CardRdv from '@components/Card_rdv/mini_card_rdv';

import { CreateAppointmentContext } from '@context/CreateAppointmentContext';

import GoogleTagManagement from '@GoogleTagManagement.jsx';

import { useSkynetMainResponsable } from '@hooks/useApi';

import { convertTimeToMs } from '@helpers';

import { ReactComponent as HappyPeople } from '@img/happyPeople.svg';

import './rdv_pris.scss';

export default function Rdv_pris() {
  const { motif, format, date } = useContext(CreateAppointmentContext);

  const { data: dataMainResponsable } = useSkynetMainResponsable({
    cacheTime: convertTimeToMs(1, 'day'),
    staleTime: convertTimeToMs(1, 'day'),
  });

  const [data, setData] = useState(null);

  useEffect(() => {
    if (dataMainResponsable !== null) {
      let dataRDV = {
        typeAction: 'Chargé de clientèle',
        description: motif.subject,
        duree: motif.duration,
        dateDebut: date,
        utilisateursAssignes: [
          dataMainResponsable?.prenom + ' ' + dataMainResponsable?.nom,
        ],
        format: format.toString(),
      };

      setData(dataRDV);
    }

    setTimeout(() => {
      localStorage.removeItem('create-appointment-data');
    }, 1);
  }, [dataMainResponsable]);

  return (
    <div className="step rdv_pris">
      <GoogleTagManagement titlePage="Rendez-vous confirmé" />
      <div className="title text-primary fw-bold text-center">
        <HappyPeople className="happy-people" />
        Votre demande de rendez-vous est enregistrée !
        <span>
          Votre chargé(e) de clientèle est informé(e) et reviendra vers vous
          dans les meilleurs délais.
        </span>
        <span>Vous allez également recevoir votre récapitulatif par mail.</span>
      </div>
      <div className="mini-recap">
        <span className="text-primary">Récapitulatif de votre rendez-vous</span>
        {data !== null && (
          <CardRdv data={data} hasButton={false} hasRecap={true} />
        )}
      </div>
      <div className="text-center button">
        <Link
          to={{ pathname: '/appointment' }}
          className="btn btn-primary rounded-pill fw-600"
          data-cy="back-rdv-button"
        >
          Retour à Mes rendez-vous
        </Link>
      </div>
    </div>
  );
}
