import React from 'react';

import Modal from '@components/Modal/ModalDefault';

import { ReactComponent as Add } from '@img/icons/add.svg';
import { ReactComponent as Valid } from '@img/icons/valid.svg';

import './ModalSubvention.scss';

export default function ModalSubvention({
  show,
  handleClose,
  getDataAndShowModalContactCDC,
}) {
  return (
    <Modal className="modal-subvention" show={show} handleClose={handleClose}>
      <div className="text-primary">
        <h2 className="fw-bold text-primary text-center">
          Nouvelle subvention
        </h2>
        <div className="row">
          <div className="col-lg-6 col-xl-3 subvention-card">
            <div className="col-12 d-flex flex-column h-100 px-3">
              <h5 className="text-center fw-bold">Compte Culture et Loisirs</h5>
              <div className="content h-100 d-flex flex-column justify-content-between">
                <div>
                  <p className="d-flex">
                    <Valid className="stroke-primary" />
                    Subvention des offres en ligne culture et loisirs
                  </p>
                  <p className="d-flex">
                    <Add className="stroke-primary" />
                    Remboursement sur facture
                  </p>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    onClick={() =>
                      getDataAndShowModalContactCDC('Compte Culture et Loisirs')
                    }
                    className="btn btn-primary rounded-pill fw-bold"
                  >
                    Selectionner
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-3 subvention-card">
            <div className="col-12 d-flex flex-column h-100 px-3">
              <h5 className="text-center fw-bold">Compte Vacances</h5>
              <div className="content h-100 d-flex flex-column justify-content-between">
                <div>
                  <p className="d-flex">
                    <Valid className="stroke-primary" />
                    Subvention des offres en ligne vacances
                  </p>
                  <p className="d-flex">
                    <Add className="stroke-primary" />
                    Remboursement sur facture
                  </p>
                  <p className="d-flex">
                    <Add className="stroke-primary" />
                    Commande de chèque vacances
                  </p>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    onClick={() =>
                      getDataAndShowModalContactCDC('Compte Vacances')
                    }
                    className="btn btn-primary rounded-pill fw-bold"
                  >
                    Selectionner
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-3 subvention-card">
            <div className="col-12 d-flex flex-column h-100 px-3">
              <h5 className="text-center fw-bold">Compte Sport</h5>
              <div className="content h-100 d-flex flex-column justify-content-between">
                <div>
                  <p className="d-flex">
                    <Valid className="stroke-primary" />
                    Subvention des offres en ligne sport et bien-être
                  </p>
                  <p className="d-flex">
                    <Add className="stroke-primary" />
                    Remboursement sur facture
                  </p>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    onClick={() =>
                      getDataAndShowModalContactCDC('Compte Sport')
                    }
                    className="btn btn-primary rounded-pill fw-bold"
                  >
                    Selectionner
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-3 subvention-card">
            <div className="col-12 d-flex flex-column h-100 px-3">
              <h5 className="text-center fw-bold">Compte Liberté</h5>
              <div className="content h-100 d-flex flex-column justify-content-between">
                <div>
                  <p className="d-flex">
                    <Valid className="stroke-primary" />
                    Subvention des offres en ligne culture, loisirs, sport,
                    bien-être et vacances
                  </p>
                  <p className="d-flex">
                    <Add className="stroke-primary" />
                    Remboursement sur facture
                  </p>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    onClick={() =>
                      getDataAndShowModalContactCDC('Compte Liberté')
                    }
                    className="btn btn-primary rounded-pill fw-bold"
                  >
                    Selectionner
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
