import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import $ from 'jquery';

import LoaderComponent from '@components/Loader/Loader';

import GoogleTagManagement from '@GoogleTagManagement';

import UserImage from '@img/icons/user.svg';

import './step1.scss';

export default function Step1(props) {
  const [loadingData, setLoadingData] = useState(true);
  const [form, setForm] = useState('');
  const refForm = useRef(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/skynet/contact/valide/email`)
      .then((res) => {
        let form = $(res.data.form);
        $('.input', form).prepend(`<img src=${UserImage} alt="user icon">`);
        setForm(form.get(0).outerHTML);
        setLoadingData(false);
      })
      .catch((error) => {});

    $(document).on('submit', 'form', function (e) {
      e.preventDefault();
      onSubmit(e.target);
    });

    $(document).on('input', '#valid_email_form_email', function (e) {
      const jquerySelectorThis = $(this);
      jquerySelectorThis.val(jquerySelectorThis.val().toLowerCase());
    });

    return () => {
      $(document).off('submit', 'form');
      $(document).off('input', '#valid_email_form_email');
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (form) => {
    axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/skynet/contact/valide/email`,
        $(form).serialize()
      )
      .then((res) => {
        props.setEmail($('#valid_email_form_email', form).val());
        props.nextStep();
      })
      .catch((error) => {
        let form = $(error.response.data.form);
        $('.input', form).prepend(`<img src=${UserImage}>`);
        setForm(form.get(0).outerHTML);
      });
  };

  return (
    <div className="step1">
      <GoogleTagManagement titlePage="Saisie de l'adresse e-mail" />
      <h4 className="card-title fw-bold">Votre adresse e-mail</h4>
      {loadingData ? (
        <div className="d-flex justify-content-center align-items-center loaderComponent">
          <LoaderComponent />
        </div>
      ) : (
        <>
          <p className="card-text">
            Veuillez renseigner l'adresse e-mail utilisée pour communiquer avec
            Edenred Solutions CSE (ex ProwebCE).
          </p>
          {form && (
            <div
              ref={refForm}
              dangerouslySetInnerHTML={{ __html: `${form}` }}
            />
          )}
        </>
      )}
    </div>
  );
}
