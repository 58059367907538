import React from 'react';

import { Link } from 'react-router-dom';

import './NavbarCreateAccount.scss';

export default function NavbarCreateAccount() {
  return (
    <nav className="navbar navbar-create-account navbar-btn-full-mobile bg-transparent navbar-expand-md border-bottom d-none d-md-block">
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarsExample04"
        aria-controls="navbarsExample04"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarsExample04">
        <ul className="">
          <Link to={{ pathname: `/` }} className="btn_home">
            <li className="nav-item active">
              <p className="btn text-primary" href="#">
                <i className="fa fa-arrow-left"></i>Accueil
              </p>
            </li>
          </Link>
        </ul>
        <div className="login-section">
          <div className="d-flex align-items-center">
            <p className=" mb-0 ">Déjà un compte ?</p>
            <Link to={{ pathname: `/login` }} className="btn_login">
              <p className="btn border-primary text-primary float-end rounded-pill">
                Connexion
              </p>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}
