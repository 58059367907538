import React, { useEffect, useRef, useState } from 'react';

import { TextField } from '@molecules';

import LoadingButton from '@components/LoadingButton/LoadingButton';
import Modal from '@components/Modal/ModalDefault';

import { useDomainNameReservationRequest } from '@hooks/useApi';

import { ReactComponent as FileIcon } from '@img/icons/piece-jointe.svg';

const ModalSendRequest = ({
  showModal,
  setShowModal,
  setIsDomainName,
  selectedDomain,
  projectId,
  setIsNddRequestSent,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const formRef = useRef();
  const [errors, setErrors] = useState({});
  const fields = {
    domainRequestFile: null,
    beneficiariesFile: null,
    design: null,
    comment: null,
  };
  const [formData, setFormData] = useState(fields);

  const fieldFileHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
  };

  const fieldTextHandler = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (
      !formData.domainRequestFile ||
      formData.domainRequestFile.type !== 'application/pdf' ||
      formData.domainRequestFile.size >= 20000000
    ) {
      errors = {
        ...errors,
        domainRequestFile:
          'Le fichier doit etre au format pdf et ne doit pas dépasser 20 Mo',
      };
      isValid = false;
    }

    const allowedFileTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel.sheet.macroEnabled.12',
      'text/csv',
      'text/plain',
    ];

    if (
      formData?.beneficiariesFile &&
      (!allowedFileTypes.includes(formData?.beneficiariesFile?.type) ||
        formData?.beneficiariesFile?.size >= 20000000)
    ) {
      errors = {
        ...errors,
        beneficiariesFile:
          'Le fichier doit etre aux formats xlsx, xls, csv et ne doit pas dépasser 20 Mo',
      };
      isValid = false;
    }

    if (!formData.design) {
      errors = {
        ...errors,
        design: 'Le champ est obligatoire',
      };
      isValid = false;
    }

    if (formData.design && formData.design.length > 100) {
      errors = {
        ...errors,
        design: 'Le texte est limité à 100 caractères',
      };
      isValid = false;
    }

    if (formData.comment && formData.comment.length > 500) {
      errors = {
        ...errors,
        comment: 'Le texte est limité à 500 caractères',
      };
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    setIsDisabled(!formData.domainRequestFile || !formData.design);
  }, [formData]);

  const {
    mutate: sendForm,
    isSuccess,
    isLoading,
    reset,
  } = useDomainNameReservationRequest({
    onSuccess: (data) => {
      formRef.current?.reset();
      setFormData(fields);
    },
    onError: (err) => {
      const { errors: apiErrors } = err.response.data;

      let errors = {};
      apiErrors?.forEach((error) => {
        errors = {
          ...errors,
          [error.property]: error.message,
        };
      });

      setErrors(errors);
    },
  });

  const closeHandler = () => {
    reset();
    setShowModal(false);
    setIsDomainName(false);

    if (isSuccess) {
      setIsNddRequestSent(true);
    }
  };

  const sendRequestHandler = (e) => {
    e.preventDefault();

    if (validate()) {
      sendForm({
        ...formData,
        domainName: selectedDomain,
        projectId: projectId,
      });
    }
  };

  const formRender = () => {
    return (
      <form onSubmit={sendRequestHandler} ref={formRef} data-cy="form-send">
        <div className="d-flex flex-column">
          <h6 className="text-primary mb-3">
            Merci de nous envoyer vos documents et informations afin de
            poursuivre votre déploiement avec votre chargé(e) de projet.
          </h6>
          <h6 className="text-primary">
            Document demande de dépôt de nom de domaine complété et signé.
          </h6>

          <div className="d-flex flex-column text-primary fs-7 mb-3">
            <span>Taille maximale : 20 Mo</span>
            <span>Le format autorisé est le pdf.</span>
          </div>

          <div className="custom-file-content">
            <div className="custom-file w-100">
              <input
                type="file"
                className="custom-file-input sr-only"
                name="domainRequestFile"
                id="domainRequestFile"
                onChange={fieldFileHandler}
                required
                data-cy="domain-request-file"
              />
              <label
                className="custom-file-label mb-0"
                htmlFor="domainRequestFile"
              >
                <span className="text-truncate">
                  {formData.domainRequestFile
                    ? formData.domainRequestFile?.name
                    : 'Aucun fichier'}
                </span>
                <FileIcon />
              </label>
            </div>
            {errors.domainRequestFile && (
              <div
                className="invalid-feedback d-block fs-7 mt-n3"
                data-target="domainRequestFile"
              >
                {errors.domainRequestFile}
              </div>
            )}
          </div>
        </div>

        <div className="d-flex flex-column">
          <h6 className="text-primary">
            Liste de vos bénéficiaires (facultatif).
          </h6>

          <div className="d-flex flex-column text-primary fs-7 mb-3">
            <span>Taille maximale : 20 Mo</span>
            <span>Les formats autorisés sont les xlsx, xls, csv.</span>
          </div>

          <div className="custom-file-content">
            <div className="custom-file w-100">
              <input
                type="file"
                className="custom-file-input sr-only"
                name="beneficiariesFile"
                id="beneficiariesFile"
                onChange={fieldFileHandler}
                data-cy="beneficiaries-file"
              />
              <label
                className="custom-file-label mb-0"
                htmlFor="beneficiariesFile"
              >
                <span className="text-truncate">
                  {formData.beneficiariesFile
                    ? formData.beneficiariesFile?.name
                    : 'Aucun fichier'}
                </span>
                <FileIcon />
              </label>
            </div>
            {errors.beneficiariesFile && (
              <div
                className="invalid-feedback d-block fs-7 mt-n3"
                data-target="beneficiariesFile"
              >
                {errors.beneficiariesFile}
              </div>
            )}
          </div>
        </div>

        <div className="form-group mb-4 pb-3">
          <label
            className="text-primary fs-6 font-weight-500 mb-2"
            htmlFor="design"
          >
            Choix de votre charte graphique.
          </label>

          <TextField
            name="design"
            id="design"
            placeholder="Nom de la charte souhaitée"
            onChange={fieldTextHandler}
            required
            data-cy="name-selected-design"
          />

          {errors.design && (
            <div
              className="invalid-feedback d-block fs-7 mt-n3"
              data-target="design"
            >
              {errors.design}
            </div>
          )}
        </div>

        <div className="form-group">
          <textarea
            className="form-control h3 text-primary false"
            rows={5}
            placeholder="Informations complémentaire à l'attention de votre chargé de projet (facultatif)"
            name="comment"
            onChange={fieldTextHandler}
            data-cy="textarea-comment"
          ></textarea>
          {errors.comment && (
            <div
              className="invalid-feedback d-block fs-7 mt-n3"
              data-target="comment"
            >
              {errors.comment}
            </div>
          )}
        </div>

        <div className="d-flex justify-content-center">
          <LoadingButton
            type="submit"
            classes="btn btn-primary rounded-pill fw-bold"
            text="Envoyer"
            loading={isLoading}
            spinnerHeight="20"
            disabled={isDisabled}
            dataCy="send-request"
          />
        </div>
        <input type="hidden" value={selectedDomain} name="domainName" />
      </form>
    );
  };

  const successMessage = (
    <div className="text-center" data-cy="success-message">
      <h6 className="text-primary text-start">
        Votre demande a été transmise à votre chargé(e) de projet qui vous
        contactera rapidement afin de poursuivre votre déploiement.
        <br /> <br />
        Si besoin vous pouvez le contacter par email en cliquant sur ce bouton.
      </h6>
      <br />
      <button
        type="button"
        className="btn btn-primary rounded-pill fw-bold"
        onClick={closeHandler}
        data-cy="close-modal"
      >
        Fermer
      </button>
    </div>
  );

  return (
    <>
      <Modal
        show={showModal}
        className="modal-project"
        handleClose={closeHandler}
      >
        <div data-cy="modal-send-request">
          <h2
            className="fw-bold text-primary"
            data-cy="title-modal-send-request"
          >
            {isSuccess
              ? 'Votre message a bien été envoyé !'
              : 'Envoyer vos informations'}
          </h2>
          {isSuccess ? successMessage : formRender()}
        </div>
      </Modal>
    </>
  );
};

export default ModalSendRequest;
