import React from 'react';

import { Link } from 'react-router-dom';

import Feature from '@components/Feature/Feature';

import { openOnetrustInfo } from '@helpers';

import EdenredWhite from '@img/Logo_Edenred_white.svg';

import './FooterDefault.scss';

function FooterDefault() {
  return (
    <div className="footerDefault">
      <img
        className="logo_edenred_white"
        src={EdenredWhite}
        alt="logo Edenred Solutions CSE (ex ProwebCE)"
      />
      <div className="links">
        <a
          href="https://solutionscse.edenred.fr/"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          Edenred Solutions CSE
        </a>
        <a className="link cookie_onetrust" onClick={openOnetrustInfo}>
          Cookies
        </a>{' '}
        {
          // eslint-disable-line jsx-a11y/anchor-is-valid
        }
        <Link
          to="documents/Def_CGU_Espace_Client_ERF_2024.pdf"
          target="_blank"
          rel="noopener noreferrer"
          download
          className="link"
        >
          CGU
        </Link>
        <Link
          to="documents/Def_Mentions_Legales_Espace_Client_ERF_2024.pdf"
          target="_blank"
          rel="noopener noreferrer"
          download
          className="link"
        >
          Informations légales
        </Link>
        <Link to={{ pathname: '/help/contact' }} className="link">
          Contact
        </Link>
        <Feature name="RESILIATION">
          <Link
            to={{
              pathname: `/resiliation`,
              state: { pathname: window?.location?.pathname ?? '' },
            }}
            className="link"
          >
            Résiliation
          </Link>
        </Feature>
      </div>
      <div></div>
    </div>
  );
}

export default React.memo(FooterDefault);
