import React from 'react';

import 'jquery-serializejson';

import InformationGeneralesDefault from './InformationsGeneralesContent/InformationGeneralesDefault';
import InformationGeneralesInputs from './InformationsGeneralesContent/InformationGeneralesInputs';
import './informationsGenerales.scss';

export default function InformationsGenerales({
  loading,
  setLoading,
  infoContact,
  listFunction,
  infoClient,
  functionContact,
  isInput,
  setIsInput,
}) {
  return (
    <div className="informations-generales">
      {loading ? (
        'Chargement'
      ) : !isInput ? (
        <InformationGeneralesDefault
          infoContact={infoContact}
          infoClient={infoClient}
          functionContact={functionContact}
          setIsInput={setIsInput}
        />
      ) : (
        <InformationGeneralesInputs
          infoContact={infoContact}
          infoClient={infoClient}
          functionContact={functionContact}
          setIsInput={setIsInput}
          listFunction={listFunction}
          setLoading={setLoading}
        />
      )}
    </div>
  );
}
