import React, { useState } from 'react';

import axios from 'axios';
import dayjs from 'dayjs';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import LoadingButton from '@components/LoadingButton/LoadingButton';
import Modal from '@components/Modal/ModalDefault';

import EmojiBien from '@img/icons/emoji-bien.svg';
import EmojiGenial from '@img/icons/emoji-genial.svg';
//Emoji
import EmojiHorrible from '@img/icons/emoji-horrible.svg';
import EmojiPasTop from '@img/icons/emoji-pas-top.svg';
import EmojiTresBien from '@img/icons/emoji-tres-bien.svg';

import './modalOpinionAppointment.scss';

export default function ModalOpinionAppointment({
  showModal,
  setShowModal,
  setShowModalCongrat,
  dataRDVOpinion,
  setData,
  questions,
}) {
  const [idEmojiGeneralChecked, setIdEmojiGeneralChecked] = useState(null);
  const [idEmojisChecked, setIdEmojisChecked] = useState([]);
  const [loading, setLoading] = useState(false);

  const emojisGeneral = [
    {
      image: EmojiHorrible,
      texte: 'Horrible',
    },
    {
      image: EmojiPasTop,
      texte: 'Pas top...',
    },
    {
      image: EmojiBien,
      texte: 'Bien',
    },
    {
      image: EmojiTresBien,
      texte: 'Très bien',
    },
    {
      image: EmojiGenial,
      texte: 'Génial !',
    },
  ];

  const updateEmojiCheckedState = (
    isClickedGeneral = false,
    idQuestion,
    idEmoji
  ) => {
    if (idQuestion !== undefined || idEmoji !== undefined) {
      setIdEmojisChecked((item) => {
        const findIndex = item.findIndex((i) => {
          return i.idQuestion === idQuestion;
        });
        if (findIndex === -1) {
          item.push({
            idQuestion,
            idEmoji,
          });
        } else {
          item[findIndex].idEmoji = idEmoji;
        }
        return [...item];
      });
    }
  };

  const onClickButton = () => {
    let body = {
      note: idEmojiGeneralChecked + 1,
      actionId: dataRDVOpinion.id,
      criteriaNotes: [],
    };

    idEmojisChecked.forEach((item, index) => {
      body.criteriaNotes.push({
        reviewCriteria: '/api/review_criterias/' + item.idQuestion,
        note: item.idEmoji + 1,
      });
    });

    postReview(body);
  };

  const postReview = (body) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACK_URL}/api/skynet/review`, body, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem('REACT_TOKEN_AUTH_KEY')
          )}`,
        },
      })
      .then((res) => {
        updateRDVPasses();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const updateRDVPasses = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_BACK_URL
        }/api/skynet/actions/list?typeAction=1&sousTypeActionCommun=RDV&dateTo=${encodeURIComponent(
          dayjs().format()
        )}&maxResults=20&order=asc`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem('REACT_TOKEN_AUTH_KEY')
            )}`,
          },
        }
      )
      .then((res) => {
        setData({
          actions: res.data.actions,
        });
        setLoading(false);
        setShowModal(false);
        setShowModalCongrat(true);

        setIdEmojiGeneralChecked(null);
        setIdEmojisChecked([]);
      })
      .catch((error) => {});
  };

  return (
    <Modal
      show={showModal}
      handleClose={() => {
        setShowModal(false);
        setIdEmojisChecked([]);
        setIdEmojiGeneralChecked(null);
      }}
      className="modal_opinion_rdv modal_rdv"
    >
      <div>
        <h2 className="fw-bold text-primary text-center">Donner mon avis</h2>
        <p className="text-primary text-center">
          Comment s'est passé votre rendez-vous avec{' '}
          <strong>
            {dataRDVOpinion && dataRDVOpinion.utilisateursAssignes[0]}
          </strong>{' '}
          ?
        </p>

        <div className="emoji-general-box" data-cy="emoji-general-box">
          {emojisGeneral.map((emojiGeneral, index) => {
            return (
              <div
                key={index}
                className={`emoji-general ${
                  index === idEmojiGeneralChecked ? 'checked' : ''
                }`}
                data-cy="emoji-general"
                onClick={() => {
                  setIdEmojiGeneralChecked(index);
                  questions.forEach((question, indexQuestion) => {
                    let intIdQuestion = parseInt(
                      question.id.replace('/api/review_criterias/', '')
                    );
                    updateEmojiCheckedState(true, intIdQuestion, index);
                  });
                }}
              >
                <img
                  src={emojiGeneral.image}
                  alt={`emoji ${emojiGeneral.texte}`}
                  data-cy="img-emoji"
                />
                {emojiGeneral.texte}
              </div>
            );
          })}
        </div>

        <p className="text-primary">Et qu'avez-vous pensé de ...</p>

        {!questions ? (
          <div className="skeleton">
            <SkeletonTheme width="100%" height="100%">
              <section className="d-flex flex-column align-items-center justify-center w-100">
                <Skeleton height={42} width="100%" className="mb-3" />
                <Skeleton height={42} width="100%" className="mb-3" />
                <Skeleton height={42} width="100%" className="mb-3" />
              </section>
            </SkeletonTheme>
          </div>
        ) : (
          <div className="questions">
            {questions.map((question, indexQuestion) => {
              let intIdQuestion = parseInt(
                question.id.replace('/api/review_criterias/', '')
              );
              return (
                <div className="question" key={indexQuestion}>
                  <span className="text-primary">{question.label}</span>
                  <div className="emojis">
                    {emojisGeneral.map((emojiGeneral, indexEmoji) => {
                      return (
                        <div
                          key={indexEmoji}
                          className={`emoji ${
                            idEmojisChecked.length &&
                            idEmojisChecked[
                              idEmojisChecked.findIndex(
                                (i) => intIdQuestion === i.idQuestion
                              )
                            ] &&
                            idEmojisChecked[
                              idEmojisChecked.findIndex(
                                (i) => intIdQuestion === i.idQuestion
                              )
                            ].idEmoji === indexEmoji
                              ? 'checked'
                              : ''
                          }`}
                          data-cy="emoji"
                          onClick={() =>
                            updateEmojiCheckedState(
                              false,
                              intIdQuestion,
                              indexEmoji
                            )
                          }
                        >
                          <img
                            src={emojiGeneral.image}
                            alt={`emoji ${emojiGeneral.texte}`}
                            data-cy="img-emoji"
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="text-center">
          <LoadingButton
            type="submit"
            classes="btn btn-primary rounded-pill fw-bold"
            dataCy="submit-opinion"
            text="Valider"
            loading={loading}
            spinnerHeight="20"
            disabled={
              idEmojiGeneralChecked === null ||
              !idEmojisChecked.length === questions.length
            }
            onClick={onClickButton}
          />
        </div>
      </div>
    </Modal>
  );
}
