import React from 'react';

import Modal from '@components/Modal/ModalDefault';

import { ReactComponent as CoupleCongrat } from '@img/coupleCongrat2.svg';

import './modalCongratOpinionAppointment.scss';

export default function ModalCongratOpinionAppointment({
  showModal,
  setShowModal,
}) {
  return (
    <Modal
      show={showModal}
      handleClose={() => {
        setShowModal(false);
      }}
      className="modal_congrat_opinion_rdv modal_rdv"
      noHeader={true}
    >
      <div data-cy="modal_congrat_opinion_rdv">
        <div className="text-center">
          <CoupleCongrat />
        </div>

        <h2 className="fw-bold text-primary text-center">Merci beaucoup !</h2>

        <p className="text-primary text-center">
          Cela nous aidera à nous améliorer pour toujours vous proposer le
          meilleur d'Edenred Solutions CSE (ex ProwebCE).
        </p>

        <div className="text-center">
          <button
            className="btn rounded-pill btn-primary fw-semi-bold fw-600"
            data-cy="btn-close-modal_congrat_opinion_rdv"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Fermer
          </button>
        </div>
      </div>
    </Modal>
  );
}
