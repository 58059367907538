import React, { useState, useEffect } from 'react';

import ReactTooltip from 'react-tooltip';

import LoadingButton from '@components/LoadingButton/LoadingButton';

import { ReactComponent as Check } from '@img/icons/check.svg';
import { ReactComponent as Close } from '@img/icons/close.svg';
import { ReactComponent as MDP } from '@img/icons/mot_de_passe.svg';
import { ReactComponent as Oeil } from '@img/icons/oeil.svg';
import { ReactComponent as OeilCache } from '@img/icons/oeil_cache.svg';
import { ReactComponent as Question } from '@img/icons/question.svg';

import './Password.scss';

export default function Password(props) {
  const [showCurrent, setShowCurrent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifPassword, setShowVerifPassword] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [allCriteriaAreValid, setAllCriteriaAreValid] = useState(false);
  const [minimumCharacters, setMinimumCharacters] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialCharacters, setHasSpecialCharacters] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [firstPassword, setFirstPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');

  const criterias = [
    {
      test: minimumCharacters,
      text: '8 caractères minimum',
    },
    {
      test: hasUppercase,
      text: 'Au moins une majuscule',
    },
    {
      test: hasLowercase,
      text: 'Au moins une minuscule',
    },
    {
      test: hasNumber,
      text: 'Au moins un chiffre',
    },
    {
      test: hasSpecialCharacters,
      text: 'Au moins un caractère spécial',
      tooltipText:
        'Les caractères spéciaux sont les suivants : ! # @ $ - _ + < >',
    },
  ];

  useEffect(() => {
    if (
      minimumCharacters &&
      hasUppercase &&
      hasLowercase &&
      hasNumber &&
      hasSpecialCharacters
    ) {
      setAllCriteriaAreValid(true);
    } else {
      setAllCriteriaAreValid(false);
    }
  }, [
    firstPassword,
    secondPassword,
    minimumCharacters,
    hasUppercase,
    hasLowercase,
    hasNumber,
    hasSpecialCharacters,
  ]);

  const handleShowCurrent = () => {
    setShowCurrent(!showCurrent);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowVerifPassword = () => {
    setShowVerifPassword(!showVerifPassword);
  };

  const onChangeCurrent = (e) => {
    let value = e.target.value;

    setCurrentPassword(value);
  };

  const onChangePassword = (e) => {
    let value = e.target.value;

    setFirstPassword(value);

    //test de la Taille
    value.length >= 8
      ? setMinimumCharacters(true)
      : setMinimumCharacters(false);

    //test majuscule
    value.toLowerCase() !== value
      ? setHasUppercase(true)
      : setHasUppercase(false);

    //test minuscule
    value.toUpperCase() !== value
      ? setHasLowercase(true)
      : setHasLowercase(false);

    //test nombre
    let hasNumber = /\d/.test(value);
    hasNumber ? setHasNumber(true) : setHasNumber(false);

    const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/; // eslint-disable-line

    //test caractere special
    let hasSpecialCharacters = regex.test(value);

    hasSpecialCharacters
      ? setHasSpecialCharacters(true)
      : setHasSpecialCharacters(false);
  };

  const onChangeSecondPassword = (e) => {
    let value = e.target.value;

    setSecondPassword(value);
  };

  const onSubmitPassword = (e) => {
    e.preventDefault();
    setHasError(false);
    firstPassword !== secondPassword && setHasError(true);
    if (currentPassword) {
      if (
        allCriteriaAreValid &&
        firstPassword === secondPassword &&
        currentPassword !== ''
      ) {
        props.setPassword(firstPassword);
        props.handleSubmit(currentPassword, firstPassword);
      }
    } else {
      if (allCriteriaAreValid && firstPassword === secondPassword) {
        props.setPassword(firstPassword);
        props.handleSubmit(firstPassword);
      }
    }
  };

  return (
    <form
      className="Password"
      action=""
      autoComplete="off"
      onSubmit={(e) => onSubmitPassword(e)}
    >
      {props.needCurrent && (
        <div className="form-group">
          <label htmlFor="inputActualPassword">
            Saisissez votre mot de passe actuel
          </label>
          <div
            className={`input-group ${currentPassword !== '' ? '' : 'empty'}`}
          >
            <MDP />
            <input
              type={showCurrent ? `text` : `password`}
              className="form-control"
              id="inputActualPassword"
              name="password"
              autoComplete="off"
              onChange={(e) => onChangeCurrent(e)}
            />
            <div
              className="input-group-append cursor-pointer"
              onClick={handleShowCurrent}
            >
              <span className="input-group-text">
                {showCurrent ? <Oeil /> : <OeilCache className="eye-hidden" />}
              </span>
            </div>
          </div>
        </div>
      )}

      <div className="form-group">
        <label htmlFor="inputNewPassword">
          Saisissez votre nouveau mot de passe
        </label>
        <div className={`input-group ${firstPassword !== '' ? '' : 'empty'}`}>
          <MDP />
          <input
            type={showPassword ? `text` : `password`}
            className="form-control"
            id="inputNewPassword"
            name="password"
            autoComplete="off"
            onChange={(e) => onChangePassword(e)}
          />
          <div
            className="input-group-append cursor-pointer"
            onClick={handleShowPassword}
          >
            <span className="input-group-text">
              {showPassword ? <Oeil /> : <OeilCache className="eye-hidden" />}
            </span>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="inputVerifPassword">Vérification du mot de passe</label>
        <div className={`input-group ${secondPassword !== '' ? '' : 'empty'}`}>
          <MDP />
          <input
            type={showVerifPassword ? `text` : `password`}
            className={`form-control ${hasError && 'is-invalid'}`}
            id="inputVerifPassword"
            name="password"
            autoComplete="off"
            onChange={(e) => onChangeSecondPassword(e)}
          />
          <div
            className="input-group-append cursor-pointer"
            onClick={handleShowVerifPassword}
          >
            <span className="input-group-text">
              {showVerifPassword ? (
                <Oeil />
              ) : (
                <OeilCache className="eye-hidden" />
              )}
            </span>
          </div>
        </div>
        {hasError && (
          <small id="passwordHelp" className="text-error">
            Les deux mots de passe ne sont pas identiques
          </small>
        )}
      </div>
      <div className="criteriaBox">
        <div className="body">
          <h6 className="title fw-bold">Votre mot de passe doit contenir : </h6>
          <div className="criterias">
            {criterias.map((criteria, index) => {
              return (
                <div
                  key={index}
                  className={`form-check ${criteria.test ? 'validate' : ''}`}
                >
                  {criteria.test ? <Check /> : <Close />}
                  <label
                    className={`form-check-label ${
                      criteria.tooltipText && 'withToolTip'
                    }`}
                    htmlFor="defaultCheck1"
                  >
                    {criteria.text}
                    {criteria.tooltipText && (
                      <div>
                        <Question
                          className="stroke-primary questionIcon"
                          data-tip
                          data-for="question"
                        />
                        <ReactTooltip
                          id="question"
                          place="right"
                          type="info"
                          effect="solid"
                          className="reactTooltip"
                        >
                          <span>{criteria.tooltipText}</span>
                        </ReactTooltip>
                      </div>
                    )}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <LoadingButton
        type="submit"
        id="sendPassword"
        classes={`btn btn-block rounded-pill ${
          props.loadingButton && 'loading'
        } ${allCriteriaAreValid ? 'btn-primary' : 'btn-secondary disabled'}`}
        disabled={allCriteriaAreValid ? '' : 'disabled'}
        text={allCriteriaAreValid ? 'Valider' : 'Valider le mot de passe'}
        loading={props.loadingButton}
        spinnerHeight="20"
      />
    </form>
  );
}
