import React, { createContext, useEffect, useState } from 'react';

import axios from 'axios';

import { useAuth } from '../authProvider';

const NewJiraContext = createContext();

export { NewJiraContext };

const NewJiraContextProvider = (props) => {
  const [hasNewJira, setHasNewJira] = useState(false);
  const [logged] = useAuth();

  useEffect(() => {
    if (logged) {
      const requestOne = axios.get(
        `${process.env.REACT_APP_BACK_URL}/api/jira/project/client?readOnly=true`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem('REACT_TOKEN_AUTH_KEY')
            )}`,
          },
        }
      );
      axios
        .all([requestOne])
        .then((res) => {
          const nbNew = res[0].data.issues.filter(
            (item) => item.new === true
          ).length;

          setHasNewJira(nbNew > 0);
        })
        .catch((error) => {});
    }
  }, [logged]);

  return (
    <NewJiraContext.Provider
      value={{
        hasNewJira,
        setHasNewJira,
      }}
    >
      {props.children}
    </NewJiraContext.Provider>
  );
};

export default NewJiraContextProvider;
