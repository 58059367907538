import React, { useContext } from 'react';

import { Link } from 'react-router-dom';

import { CreateAppointmentContext } from '@context/CreateAppointmentContext';

import { usePrefetchMainResponsable } from '@hooks/useApi';

import { ReactComponent as ArrowLeft } from '@img/icons/arrow-left.svg';
import { ReactComponent as Delete } from '@img/icons/delete.svg';

import './createAppointment.scss';
// * Steps
import Motif from './steps/motif/motif';
import RDVPris from './steps/rdv_pris/rdv_pris';
import Recapitulatif from './steps/recapitulatif/recapitulatif';
import RendezVous from './steps/rendez-vous/rendez-vous';
import Telephone from './steps/telephone/telephone';

export default function CreateAppointment() {
  const { step, goToPreviousStep } = useContext(CreateAppointmentContext);

  const stepList = {
    1: Motif,
    2: RendezVous,
    2.5: Telephone,
    3: Recapitulatif,
    4: RDVPris,
  };

  const StepComponent = stepList[step];

  usePrefetchMainResponsable();

  return (
    <div className="w-100 create-appointment">
      <div className="header-create-appointment">
        <h3 className="title_page">Nouveau rendez-vous</h3>
      </div>
      <div className="row">
        <div className="col-12 rect_info">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                {step === 5 ? (
                  ''
                ) : (
                  <div className="header-content-create-appointment d-flex justify-content-between">
                    {step === 1 ? (
                      <div></div>
                    ) : (
                      <span
                        className="precedent text-primary"
                        data-cy="back-button"
                        onClick={() => {
                          goToPreviousStep();
                        }}
                      >
                        <ArrowLeft />
                        Précédent
                      </span>
                    )}
                    <Link
                      to={{ pathname: '/appointment' }}
                      className="annuler text-placeholder"
                      onClick={() =>
                        localStorage.removeItem('create-appointment-data')
                      }
                    >
                      <Delete />
                      Annuler
                    </Link>
                  </div>
                )}
                {step !== null && <StepComponent />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
