import React, { createContext, useState } from 'react';

const ToggleFeatureContext = createContext();

export { ToggleFeatureContext };

const ToggleFeatureProvider = (props) => {
  const [toggleFeature, setToggleFeature] = useState(null);

  return (
    <ToggleFeatureContext.Provider value={{ toggleFeature, setToggleFeature }}>
      {props.children}
    </ToggleFeatureContext.Provider>
  );
};

export default ToggleFeatureProvider;
