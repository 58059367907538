import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Checkbox } from '@atoms';

import GoogleTagManagement from '@GoogleTagManagement';

import './step3.scss';

// import axios from 'axios';

export default function Step3(props) {
  const [check, setCheck] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    // check && (
    //     axios.post(`${process.env.REACT_APP_BACK_URL}/api/edenred/connect/${props.getEmail}/verify-email`, {"verification_code": props.getEmail})
    //     .then(res => {
    //         setMDPisValid(true)

    //     }).catch((error) => {
    //         setClickButton(false)
    //     });
    // )
    // history.push(`/confirm-email`);
  };

  return (
    <div className="card-conditions-generales">
      <GoogleTagManagement titlePage="Validation de la création de compte" />
      <h4 className="card-title fw-bold">
        Dernière étape pour créer votre compte !
      </h4>
      <form action="" autoComplete="off" onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <Checkbox
            required={true}
            name="conditionCheck"
            value={check}
            onChange={() => setCheck(!check)}
            label={
              <span>
                J'accepte{' '}
                <u>
                  <Link
                    to="documents/Def_CGU_Espace_Client_ERF_2024.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    les conditions générales d'utilisation
                  </Link>
                </u>
              </span>
            }
          />
        </div>
        <button
          type="submit"
          id="sendlogin"
          className={`btn btn-primary btn-block rounded-pill ${
            check === false ? 'disabled' : ''
          }`}
          disabled={check === false ? 'disabled' : ''}
        >
          Je crée mon compte
        </button>
      </form>
    </div>
  );
}
