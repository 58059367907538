import React from 'react';

import { Link } from 'react-router-dom';

import Feature from '@components/Feature/Feature';

import { openOnetrustInfo } from '@helpers';

import EdenredWhite from '@img/Logo_Edenred_white.svg';

import './FooterConnexion.scss';

export default function FooterDefault() {
  return (
    <div className="footerConnexion">
      <div className="footer">
        <img
          className="logo_edenred_white"
          src={EdenredWhite}
          alt="logo Edenred Solutions CSE (ex ProwebCE)"
        />
        <div className="links">
          <a
            href="https://solutionscse.edenred.fr/nous-decouvrir"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            Découvrir Edenred Solutions CSE
          </a>
          <a
            href="https://solutionscse.edenred.fr/actualites-ce-cse"
            className="link"
          >
            Blog
          </a>
          <a
            href="https://solutionscse.edenred.fr/solutions-ce-cse/site-internet"
            className="link"
          >
            Offres
          </a>
          <a href="https://solutionscse.edenred.fr/contact" className="link">
            Contact
          </a>
        </div>
        <div></div>
      </div>
      <div className="bottomBar">
        <a
          href="https://solutionscse.edenred.fr/"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          Edenred
        </a>
        <Link
          to="documents/Def_Mentions_Legales_Espace_Client_ERF_2024.pdf"
          target="_blank"
          rel="noopener noreferrer"
          download
          className="link"
        >
          Mentions légales
        </Link>
        <a className="link cookie_onetrust" onClick={openOnetrustInfo}>
          Cookies
        </a>{' '}
        {
          // eslint-disable-line jsx-a11y/anchor-is-valid
        }
        <a
          href="https://solutionscse.edenred.fr/donnees-personnelles"
          target="_blank"
          className="link"
          rel="noreferrer"
        >
          Protection des données
        </a>
        <Feature name="RESILIATION">
          <Link to={{ pathname: `/resiliation` }} className="link">
            Résiliation
          </Link>
        </Feature>
      </div>
    </div>
  );
}
