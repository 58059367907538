import React, { useContext } from 'react';

import Skeleton from 'react-loading-skeleton';
import { Link, useParams } from 'react-router-dom';

import Question from '@components/Question/Question';

import { UserContext } from '@context/UserContext';

import GoogleTagManagement from '@GoogleTagManagement';

import { useFaqSubCategoryThemes, useFaqPagesPined } from '@hooks/useApi';

import './faq.scss';

export default function FaqSubCategory() {
  const { thematic } = useParams();
  const { subThematic } = useParams();
  const { user } = useContext(UserContext);

  const {
    data: faqSubThemeData,
    isLoading: faqSubThemeDataLoading,
    isError: faqSubThemeDataError,
  } = useFaqSubCategoryThemes(subThematic, user ? user.platform : 'null');

  const {
    data: questionPinedData,
    isLoading: questionPinedLoading,
    isError: questionPinedError,
  } = useFaqPagesPined(user ? user.platform : 'null');

  const isLoadingData = faqSubThemeDataLoading || questionPinedLoading;
  const isErrorData = faqSubThemeDataError || questionPinedError;

  return (
    <div className="w-100 faq">
      <GoogleTagManagement titlePage={faqSubThemeData?.title} />
      <div className="header-faq align-items-center">
        <h3 className="title_page">Besoin d'aide</h3>
        <Link
          to={{ pathname: '/help/contact' }}
          className="btn btn-primary rounded-pill contact"
        >
          Contactez-nous
        </Link>
      </div>

      <div className="row">
        <div className="col-12 rect_info rect_info_search_bill">
          <div className="col-12">
            {isErrorData ? (
              <h5 className="mb-0 text-error">
                <span className="fw-bold">
                  <i className="fas fa-exclamation-triangle text-error me-2"></i>
                  Erreur :
                </span>{' '}
                Un problème est survenu lors de la récupération des données.
              </h5>
            ) : (
              <>
                {questionPinedData && (
                  <div className="featured_item">
                    <p>{questionPinedData.name}</p>
                    <Link
                      to={{ pathname: `${questionPinedData.path}` }}
                      className="btn btn-primary rounded-pill read_feature"
                    >
                      Lire l'article
                    </Link>
                  </div>
                )}
                {isLoadingData ? (
                  <Skeleton height={20} width={250} className="mb-4" />
                ) : (
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={{ pathname: `/help` }}>Besoin d'aide</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to={{ pathname: `/help/${thematic}` }}>
                          {faqSubThemeData?.title}
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {faqSubThemeData?.sousThemeName}
                      </li>
                    </ol>
                  </nav>
                )}
                {isLoadingData && (
                  <Link
                    to={{ pathname: `/help/${thematic}` }}
                    className="return text-primary text-decoration-none"
                  >
                    <i className="fa fa-arrow-left me-2"></i>Retour
                  </Link>
                )}
                <div className="thematic">
                  <h5 className="fw-bold text-primary rect_info_title">
                    {isLoadingData ? (
                      <Skeleton height={20} width={100} className="" />
                    ) : (
                      faqSubThemeData?.sousThemeName
                    )}
                  </h5>

                  <div className="questions">
                    {isLoadingData ? (
                      <Skeleton height={80} count={3} className="mb-1" />
                    ) : (
                      faqSubThemeData?.thematics.map((questionsItem, index) => {
                        return (
                          <div id="accordion" key={index}>
                            <Question
                              index={index}
                              id={questionsItem.id}
                              title={questionsItem.title}
                              path={questionsItem.path}
                              disabled={questionsItem.disabled}
                              content={questionsItem.content}
                              image={questionsItem.image}
                              video={questionsItem.video}
                            />
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
                <div className="contact">
                  <h5 className="fw-bold">
                    Vous ne trouvez pas de réponse à vos questions ?
                  </h5>
                  <Link
                    to={{ pathname: '/help/contact' }}
                    className="btn btn-primary rounded-pill"
                  >
                    Contactez-nous
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
