import React, { useContext } from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { UserContext } from '@context/UserContext';

import {
  useFaqMostViewed,
  useFaqMostVoted,
  useFaqPined,
  useFaqTheme,
} from '@hooks/useApi';

import { ReactComponent as Question } from '@img/icons/question.svg';

import './faq.scss';

export default function Faq() {
  const { user } = useContext(UserContext);

  const {
    data: faqThemeData,
    isLoading: faqThemeLoading,
    isError: faqThemeError,
  } = useFaqTheme(user ? user.platform : 'null', {
    enabled: !!user,
  });

  const {
    data: mostVotedData,
    isLoading: mostVotedLoading,
    isError: mostVotedError,
  } = useFaqMostVoted(user ? user.platform : 'null', {
    enabled: !!user,
  });

  const {
    data: mostViewedData,
    isLoading: mostViewedLoading,
    isError: mostViewedError,
  } = useFaqMostViewed(user ? user.platform : 'null', {
    enabled: !!user,
  });

  const {
    data: pinedData,
    isLoading: pinedLoading,
    isError: pinedError,
  } = useFaqPined(user ? user.platform : 'null', {
    enabled: !!user,
  });

  const isLoadingData =
    faqThemeLoading || mostVotedLoading || mostViewedLoading || pinedLoading;

  const isErrorData =
    faqThemeError || mostVotedError || mostViewedError || pinedError;

  return (
    <div className="w-100 faq">
      <div className="header-faq align-items-center">
        <h3 className="title_page">Besoin d'aide</h3>
        <Link
          to={{ pathname: '/help/contact' }}
          className="btn btn-primary rounded-pill contact"
        >
          Contactez-nous
        </Link>
      </div>

      <div className="row">
        <div className="col-12 rect_info rect_info_search_bill">
          <div className="col-12">
            {isErrorData ? (
              <h5 className="mb-0 text-error">
                <span className="fw-bold">
                  <i className="fas fa-exclamation-triangle text-error me-2"></i>
                  Erreur :
                </span>{' '}
                Un problème est survenu lors de la récupération des données.
              </h5>
            ) : (
              <>
                {pinedData && (
                  <div className="featured_item">
                    <p>{pinedData.name}</p>
                    <Link
                      to={{ pathname: `${pinedData.path}` }}
                      className="btn btn-primary rounded-pill read_feature"
                    >
                      Lire l'article
                    </Link>
                  </div>
                )}
                <div className="questions row">
                  <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                    <h5 className="fw-bold text-primary rect_info_title">
                      Questions fréquentes
                    </h5>
                    <div className="content">
                      {isLoadingData ? (
                        <div className="skeleton">
                          <SkeletonTheme width="100%">
                            <section>
                              <Skeleton height={20} className="mb-1" />
                              <Skeleton
                                height={20}
                                width="40%"
                                className="mb-3"
                              />

                              <Skeleton height={20} className="mb-1" />
                              <Skeleton
                                height={20}
                                width="40%"
                                className="mb-3"
                              />

                              <Skeleton height={20} className="mb-1" />
                              <Skeleton height={20} className="mb-1" />
                              <Skeleton
                                height={20}
                                width="40%"
                                className="mb-3"
                              />
                            </section>
                          </SkeletonTheme>
                        </div>
                      ) : (
                        mostViewedData.map((questionItem, index) => {
                          return (
                            <Link
                              key={index}
                              to={{ pathname: `${questionItem.path}` }}
                            >
                              <Question />
                              {questionItem.name}
                            </Link>
                          );
                        })
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                    <h5 className="fw-bold text-primary rect_info_title">
                      Questions les plus utiles
                    </h5>
                    <div className="content">
                      {isLoadingData ? (
                        <div className="skeleton">
                          <SkeletonTheme width="100%">
                            <section>
                              <Skeleton height={20} className="mb-1" />
                              <Skeleton
                                height={20}
                                width="40%"
                                className="mb-3"
                              />

                              <Skeleton height={20} className="mb-1" />
                              <Skeleton
                                height={20}
                                width="40%"
                                className="mb-3"
                              />

                              <Skeleton height={20} className="mb-1" />
                              <Skeleton height={20} className="mb-1" />
                              <Skeleton
                                height={20}
                                width="40%"
                                className="mb-3"
                              />
                            </section>
                          </SkeletonTheme>
                        </div>
                      ) : (
                        mostVotedData.map((questionItem, index) => {
                          return (
                            <Link
                              key={index}
                              to={{ pathname: `${questionItem.path}` }}
                            >
                              <Question />
                              {questionItem.name}
                            </Link>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
                <div className="thematic">
                  <h5 className="fw-bold text-primary rect_info_title">
                    Choisissez une thématique
                  </h5>
                  {isLoadingData ? (
                    <div className="skeleton">
                      <SkeletonTheme width="100%">
                        <section className="d-flex justify-content-start w-100 flex-wrap thematic-skeleton">
                          {[...Array(7)].map((e, i) => {
                            return (
                              <Skeleton height={118} width="100%" key={i} />
                            );
                          })}
                        </section>
                      </SkeletonTheme>
                    </div>
                  ) : (
                    <div className="thematic-items">
                      {faqThemeData.map((thematic, index) => {
                        return (
                          <Link
                            key={index}
                            to={{ pathname: `${thematic.path}` }}
                            className="thematic-item"
                          >
                            {thematic.name}
                          </Link>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="contact">
                  <h5 className="fw-bold">
                    Vous ne trouvez pas de réponse à vos questions ?
                  </h5>
                  <Link
                    to={{ pathname: '/help/contact' }}
                    className="btn btn-primary rounded-pill"
                  >
                    Contactez-nous
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
