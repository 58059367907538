import React, { useEffect, useState } from 'react';

import { SelectList } from '@molecules';

import LoadingButton from '@components/LoadingButton/LoadingButton';
import Modal from '@components/Modal/ModalDefault';

import { useSkynetEmailPost } from '@hooks/useApi';

export default function ModalServices({
  show,
  handleClose,
  options,
  clickedService,
}) {
  const [serviceSelect, setServiceSelect] = useState(options[0]);
  const [textareaValue, setTextareaValue] = useState(
    `Bonjour\n\nNotre CSE est intéressé ${serviceSelect?.value.texte_mail}. Pouvez-vous me contacter afin de m'en parler plus en détail ?\n\nCordialement`
  );

  useEffect(() => {
    clickedService !== null && setServiceSelect(clickedService);
  }, [clickedService]);

  useEffect(() => {
    setTextareaValue(
      `Bonjour\n\nNotre CSE est intéressé ${serviceSelect?.value.texte_mail}. Pouvez-vous me contacter afin de m'en parler plus en détail ?\n\nCordialement`
    );
  }, [serviceSelect]);

  const onChangeSelect = (e) => {
    setServiceSelect(e);
  };

  const { mutate: sendMailMutation, isLoading: loadingSendMail } =
    useSkynetEmailPost({
      onSuccess: () => {
        handleClose();
      },
    });

  const sendMail = (e) => {
    e.preventDefault();
    let data = { motif: serviceSelect?.label, content: textareaValue };

    sendMailMutation({
      typeMail: 'cdp',
      data: data,
    });
  };

  return (
    <Modal show={show} handleClose={handleClose}>
      <div className="">
        <h2 className="fw-bold text-primary">
          Contacter votre chargé de clientèle
        </h2>
        <h6 className="text-primary">Tous les champs sont obligatoires.</h6>
        <form autoComplete="off" onSubmit={sendMail} className="text-primary">
          <div className="form-group">
            <label htmlFor="inputFonction">
              <small>Service souhaité</small>
            </label>
            <SelectList
              options={options}
              value={serviceSelect}
              placeholder="Service souhaité"
              className="text-primary "
              onChange={onChangeSelect}
              isOptionDisabled={(option) => option.value.isSelectedService}
            />
          </div>
          <div className="form-group">
            <label htmlFor="formControlTextarea1">
              <small>Contacez votre chargé de clientèle</small>
            </label>
            <textarea
              className="form-control h3 text-primary"
              id="formControlTextarea1"
              rows="9"
              value={textareaValue}
              required
              onChange={(e) => setTextareaValue(e.target.value)}
            ></textarea>
          </div>
          <div className="text-center">
            <LoadingButton
              type="submit"
              classes="btn btn-primary rounded-pill fw-bold"
              text="Envoyer"
              loading={loadingSendMail}
              spinnerHeight="20"
              disabled={textareaValue.trim() === '' ? true : false}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}
