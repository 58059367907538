import React, { useState, useEffect } from 'react';

import axios from 'axios';

import Step2MDP from './step2MDP';
import Step2Validation from './step2Validation';

export default function Step2(props) {
  const [MDPisValid, setMDPisValid] = useState(false);
  const [clickButton, setClickButton] = useState(false);
  useEffect(() => {
    if (props.getPassword !== null && clickButton !== false) {
      axios
        .post(`${process.env.REACT_APP_BACK_URL}/api/edenred/identity/`, {
          username: props.getEmail,
          password: props.getPassword,
          email: props.getEmail,
        })
        .then((res) => {
          setMDPisValid(true);
        })
        .catch((error) => {
          setClickButton(false);
          if (error.response.data.session_expired) {
            props.goToStep(1);
          }
        });
    }
  }, [clickButton, props.getEmail, props.getPassword]); // eslint-disable-line react-hooks/exhaustive-deps

  const validMDP = () => {
    setClickButton(true);
  };

  return !MDPisValid ? (
    <Step2MDP valideMDP={validMDP} setPassword={props.setPassword} />
  ) : (
    <Step2Validation nextStep={props.nextStep} getEmail={props.getEmail} />
  );
}
