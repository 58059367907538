import React from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { ReactComponent as Conseil } from '@img/icons/Conseil.svg';
import { ReactComponent as AccompagnementComm } from '@img/icons/accomp_com.svg';
import { ReactComponent as AccompagnementGestion } from '@img/icons/accompagnement_gestion.svg';
import { ReactComponent as Formation } from '@img/icons/formation.svg';

import CarouselItem from './CarouselItem';
import './carousel.scss';

export default function CarouselComponent({ data, showModalService, loading }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    centerMode: false,
    enterPadding: 0,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2.2,
          // centerPadding: '40px',
          arrows: false,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
          // centerPadding: '40px',
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.5,
          // centerPadding: '40px',
          arrows: false,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.2,
          // centerPadding: '40px',
          arrows: false,
        },
      },
    ],
  };

  const items = [
    {
      image: <AccompagnementGestion />,
      title: 'Accompagnement en gestion',
      description:
        'Faites-vous aider par nos experts pour créer et paramétrer vos offres.',
      selected: data?.accompagnementGestion,
    },
    {
      image: <AccompagnementComm />,
      title: 'Accompagnement en communication',
      description:
        'Faites-vous aider par nos experts pour créer et paramétrer vos offres.',
      selected: data?.accompagnementComm,
    },
    {
      image: <AccompagnementGestion />,
      image2: <AccompagnementComm />,
      title: 'Accompagnement gestion + communication',
      description:
        'Nos experts vous aident pour gérer vos offres et votre communication.',
      selected:
        data?.accompagnementGestion && data?.accompagnementComm ? true : false,
    },
    {
      image: <Formation />,
      title: 'Formation',
      description:
        'Nos experts vous aident pour valoriser votre mandat et animer votre side internet.',
      selected: false,
    },
    {
      image: <Conseil />,
      title: 'Conseil',
      description:
        'Nos experts vous aident pour valoriser votre mandat et animer votre side internet.',
      selected: false,
    },
  ];

  return (
    <Slider {...settings} className="slider" data-cy="equipment-slider">
      {items.map((item, index) => {
        return (
          <CarouselItem
            key={index}
            image={item.image}
            image2={item.image2}
            title={item.title}
            description={item.description}
            selected={item.selected}
            showModalService={showModalService}
            loading={loading}
          />
        );
      })}
    </Slider>
  );
}
