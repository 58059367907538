import React, { useState } from 'react';

import axios from 'axios';

import CardCenter from '@components/Card/CardCenter';
import LoadingButton from '@components/LoadingButton/LoadingButton';

import GoogleTagManagement from '@GoogleTagManagement';

import ForbiddenImage from '@img/forbidden.svg';
import { ReactComponent as User } from '@img/icons/user.svg';

import './EnterContactEmail.scss';

export default function EnterContactEmail({ email, onChangeEmail, setStep }) {
  const [loading, setLoading] = useState(false);

  const sendMail = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/edenred/identity/${email}/account-verification`
      )
      .then((res) => {
        setStep(res.data);
      })
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <CardCenter
      row_class="min-height-full-with-navbar"
      id="enter-contact-email"
    >
      <GoogleTagManagement titlePage="Difficultés à me connecter : Saisie du mail de contact" />
      <div className="card-body text-center">
        <h4 className="card-title fw-bold text-center">
          Difficultés à me <br />
          connecter ?
        </h4>
        <img
          src={ForbiddenImage}
          className="img-fluid"
          alt="mot de passe oublie"
        ></img>
        <p className="card-text text-start">
          Pour vérifier votre compte, renseignez l'adresse e-mail de connexion à
          votre Espace-Client :
        </p>
        <form onSubmit={(e) => sendMail(e)}>
          <div className="form-group input-email">
            <label htmlFor="inputEmail">Adresse e-mail</label>
            <div className={`input`}>
              <User />
              <input
                type="email"
                value={email}
                className="form-control"
                id="inputEmail"
                aria-describedby="emailHelp"
                placeholder="exemple@exemple.com"
                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]+$"
                required
                onChange={(e) => onChangeEmail(e)}
              />
            </div>
          </div>
          <LoadingButton
            text="Continuer"
            loading={loading}
            type="submit"
            id="submitMail"
            classes="btn btn-primary btn-block rounded-pill"
            spinnerHeight={18}
          />
        </form>
      </div>
    </CardCenter>
  );
}
