import React from 'react';

import PropTypes from 'prop-types';

import './TextField.scss';

export const TextField = React.forwardRef(
  ({ placeholder, onChange, className, ...props }, ref) => {
    return (
      <input
        ref={ref}
        className={`form-control EC-TextField ${className}`}
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        {...props}
      />
    );
  }
);

TextField.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

TextField.defaultProps = {
  placeholder: '',
  className: '',
  onChange: () => {},
};
