import React, { useContext } from 'react';

import { Route, Switch } from 'react-router-dom';

import { useAuth } from './authProvider';
import './authProvider';
import AppRoute, { AppRouteTab } from './components/AppRoute/AppRoute';
import ProtectedContentRoute, {
  ProtectedContentRouteTab,
} from './components/ProtectedContentRoute/ProtectedContentRoute';
import ProtectedLoginRoute, {
  ProtectedLoginRouteTab,
} from './components/ProtectedLoginRoute/ProtectedLoginRoute';
import { ToggleFeatureContext } from './context/ToggleFeatureContext';
import NotFound from './pages/notFound/notFound';

export default function Routes() {
  const [logged] = useAuth();
  const { toggleFeature } = useContext(ToggleFeatureContext);

  const dispatchTypeRoute = (route, index) => {
    if (route.condition !== undefined && !route.condition) return false;

    if (
      toggleFeature &&
      route?.toggleFeature !== undefined &&
      !toggleFeature[route?.toggleFeature]
    ) {
      return false;
    } else {
      switch (route.routeType) {
        case 'ProtectedLoginRoute':
          return <ProtectedLoginRoute {...route} key={index} />;
        case 'ProtectedContentRoute':
          return <ProtectedContentRoute {...route} key={index} />;
        case 'AppRoute':
          return <AppRoute {...route} key={index} />;
        default:
          return false;
      }
    }
  };

  return (
    <Switch>
      {[
        ...ProtectedLoginRouteTab(),
        ...ProtectedContentRouteTab(),
        ...AppRouteTab(logged),
      ].map((route, index) => {
        return dispatchTypeRoute(route, index);
      })}
      <Route name="not-found" component={NotFound} />
    </Switch>
  );
}
