import React, { useState } from 'react';

import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';

import CardCenter from '@components/Card/CardCenter';
import Password from '@components/Password/Password';

import { getQueryParameters } from '@helpers';

import ForbiddenFinishImage from '@img/forbidden_finish.svg';

export default function ResetPassword() {
  const [password, setPassword] = useState('');
  const [valid, setValid] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  let location = useLocation();

  const handleSubmit = (password) => {
    setLoadingButton(true);

    let data = getQueryParameters(location.search);
    axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/edenred/identity/reset-password`,
        {
          username: data.username,
          password: password,
          verification_code: data.token,
        }
      )
      .then((res) => {
        setValid(true);
        setLoadingButton(false);
      })
      .catch((error) => {
        setLoadingButton(false);
      });
  };

  return (
    <CardCenter row_class="min-height-full-with-navbar">
      {!valid ? (
        <div className="card-body reinitialisation">
          <h4 className="card-title fw-bold">
            Réinitialisation du mot de passe
          </h4>
          <Password
            setPassword={setPassword}
            password={password}
            loadingButton={loadingButton}
            handleSubmit={handleSubmit}
          />
        </div>
      ) : (
        <div className="card-body text-center finish">
          <h4 className="card-title fw-bold">
            Vous avez changé votre mot de passe !
          </h4>
          <img
            src={ForbiddenFinishImage}
            className="img-fluid"
            alt="mot de passe changé"
          ></img>
          <p className="card-text">
            Vous venez de réinitialiser votre mot de passe. Vous pouvez dès
            maintenant vous connecter avec votre nouveau mot de passe.
          </p>
          <Link
            to={{ pathname: `/login` }}
            className="btn btn-primary btn-block rounded-pill getIt"
          >
            Me connecter
          </Link>
        </div>
      )}
    </CardCenter>
  );
}
