import React, { useState, useEffect } from 'react';

import $ from 'jquery';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import {
  useSkynetContact,
  useSkynetContactFunction,
  useSkynetEquipmentClient,
  useEdenredIdentityPost,
} from '@hooks/useApi';

import Password from './MDP';
import Communication from './communication';
import InformationsGenerales from './informationsGenerales';
import './profil.scss';

export default function Profil() {
  const [password, setPassword] = useState('');
  const [isInput, setIsInput] = useState(false);
  const [loading, setLoading] = useState(true);
  const [numberSlide, setNumberSlide] = useState(0);
  const [passwordIsChanged, setPasswordIsChanged] = useState(false);

  const { mutate: changePasswordMutation, isLoading: isChangePasswordLoading } =
    useEdenredIdentityPost({
      onSuccess: () => {
        setPasswordIsChanged(true);
      },
    });

  const handleSubmit = (current, password) => {
    changePasswordMutation({
      current_password: current,
      new_password: password,
    });
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: numberSlide,
    slidesToScroll: 1,
    centerMode: false,
    enterPadding: 6,
    arrows: false,
  };

  const {
    data: dataSkynetContact,
    isLoading: isLoadingSkynetContact,
    isError: isErrorSkynetContact,
  } = useSkynetContact();

  const {
    data: dataSkynetContactFunction,
    isLoading: isLoadingSkynetContactFunction,
    isError: isErrorSkynetContactFunction,
  } = useSkynetContactFunction();

  const {
    data: dataSkynetEquipmentClient,
    isLoading: isLoadingSkynetEquipmentClient,
    isError: isErrorSkynetEquipmentClient,
  } = useSkynetEquipmentClient({
    enabled: !isInput,
  });

  const isLoadingData =
    isLoadingSkynetContact ||
    isLoadingSkynetContactFunction ||
    isLoadingSkynetEquipmentClient;

  const isErrorData =
    isErrorSkynetContact ||
    isErrorSkynetContactFunction ||
    isErrorSkynetEquipmentClient;

  useEffect(() => {
    handleResize();

    function handleResize() {
      let containerWidth = $('.profil-container').width();
      $('.js-nav-link').outerWidth(containerWidth / 3 - 6);
      let itemLength = $('.js-nav-link').length;
      let itemWidth = $('.js-nav-link').outerWidth(true);
      let numberSlideCalc = containerWidth / itemWidth;
      setNumberSlide(
        numberSlideCalc > itemLength ? itemLength : numberSlideCalc
      );
    }

    window.addEventListener('resize', handleResize);

    $('.nav-item .js-nav-link').on('click', function (e) {
      e.preventDefault();
      $('.js-nav-link').removeClass('active');
      $(this).addClass('active');
    });

    return () => {
      window.removeEventListener('resize', handleResize);
      $('.nav-item .js-nav-link').off('click');
    };
  }, []);

  return (
    <div className="w-100 profil-container">
      <h4 className="fw-bold mb-4 title_page">Mon profil</h4>
      <Slider
        {...settings}
        className={`slider slider-profil slider-ec nav nav-tabs ${
          isLoadingData && 'pointer-events-none'
        }`}
      >
        <li className="nav-item">
          <a
            className="js-nav-link nav-link active"
            id="infos-tab"
            data-bs-toggle="tab"
            href="#infos"
            role="tab"
            aria-controls="infos"
            aria-selected="true"
          >
            Informations générales
          </a>
        </li>
        <li className="nav-item">
          <a
            className="js-nav-link nav-link"
            id="communication-tab"
            data-bs-toggle="tab"
            href="#communication"
            role="tab"
            aria-controls="communication"
            aria-selected="false"
          >
            Communication
          </a>
        </li>
        <li className="nav-item">
          <a
            className="js-nav-link nav-link"
            id="password-tab"
            data-bs-toggle="tab"
            href="#password"
            role="tab"
            aria-controls="password"
            aria-selected="false"
          >
            Mot de passe
          </a>
        </li>
      </Slider>
      <div className="tab-content rect_info" id="myTabContent">
        {isLoadingData ? (
          <div className="col-12 skeleton">
            <SkeletonTheme width="100%">
              <section className="d-flex flex-column align-items-center justify-center w-100">
                {/* informations générales */}
                <Skeleton height={20} width={210} className="mb-4" />

                <Skeleton height={10} width={30} className="mb-1" />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />

                <Skeleton height={10} width={30} className="mb-1" />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />

                <Skeleton height={10} width={30} className="mb-1" />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-5 skeleton-input"
                />

                {/* informations de contact */}

                <Skeleton height={20} width={210} className="mb-4" />

                <Skeleton height={10} width={30} className="mb-1" />
                <Skeleton height={30} width={210} className="mb-2" />

                <Skeleton height={10} width={30} className="mb-1" />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />

                <Skeleton height={10} width={30} className="mb-1" />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />

                <Skeleton height={10} width={30} className="mb-1" />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-5 skeleton-input"
                />

                <Skeleton height={20} width={210} className="mb-4" />

                <Skeleton height={10} width={30} className="mb-1" />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />

                <Skeleton height={10} width={30} className="mb-1" />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />

                <Skeleton height={10} width={30} className="mb-1" />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-5 skeleton-input"
                />

                <Skeleton height={10} width={30} className="mb-1" />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />

                <Skeleton height={10} width={30} className="mb-1" />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />

                <Skeleton height={10} width={30} className="mb-1" />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-5 skeleton-input"
                />

                <Skeleton height={20} width={210} className="mb-4" />

                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />
              </section>
            </SkeletonTheme>
          </div>
        ) : isErrorData ? (
          <div>
            <h5 className="mb-0 text-error">
              <span className="fw-bold">
                <i className="fas fa-exclamation-triangle text-error me-2"></i>
                Erreur :
              </span>{' '}
              Un problème est survenu lors de la récupération des données.
            </h5>
          </div>
        ) : (
          <>
            <div
              className="tab-pane fade show active "
              id="infos"
              role="tabpanel"
              aria-labelledby="infos-tab"
            >
              {!isLoadingData && (
                <InformationsGenerales
                  loading={isLoadingData}
                  setLoading={setLoading}
                  infoContact={dataSkynetContact.contact}
                  infoClient={dataSkynetContact.client}
                  functionContact={dataSkynetContact.function}
                  listFunction={dataSkynetContactFunction['hydra:member']}
                  isInput={isInput}
                  setIsInput={setIsInput}
                />
              )}
            </div>
            <div
              className="tab-pane fade"
              id="communication"
              role="tabpanel"
              aria-labelledby="communication-tab"
            >
              <Communication
                infoContact={dataSkynetContact.contact}
                isMeyclub={dataSkynetEquipmentClient?.meyclub}
                isLicence={dataSkynetEquipmentClient?.licence}
              />
            </div>
            <div
              className="tab-pane fade"
              id="password"
              role="tabpanel"
              aria-labelledby="password-tab"
            >
              <Password
                password={password}
                setPassword={setPassword}
                handleSubmit={handleSubmit}
                loadingButton={isChangePasswordLoading}
                passwordIsChanged={passwordIsChanged}
                setPasswordIsChanged={setPasswordIsChanged}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
