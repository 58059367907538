import React from 'react';

import axios from 'axios';

import GoogleTagManagement from '@GoogleTagManagement';

import './step2Validation.scss';

export default function Step2Validation(props) {
  const resendMail = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/edenred/identity/${props.getEmail}/${props.getEmail}/resend-email-verification`
      )
      .then((res) => {})
      .catch((error) => {});
  };

  return (
    <div className="card-validation-mail">
      <GoogleTagManagement titlePage="Confirmation de l'adresse e-mail" />
      <h4 className="card-title fw-bold">Validation de l'adresse e-mail</h4>
      <p className="card-text">
        Pour finaliser la dernière étape de création de votre compte, vous allez
        recevoir un e-mail à l’adresse :
      </p>
      <p className="fw-bold card-text email">{props.getEmail}</p>
      <p className="card-text">
        Cliquez sur le lien de l'e-mail pour valider votre adresse. Si vous
        n'avez rien reçu, pensez à regarder dans votre spam ou{' '}
        <span className="cursor-pointer" onClick={resendMail}>
          <u>cliquez-ici</u>
        </span>
      </p>
    </div>
  );
}
