import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';

import App from './App';
import LoadingScreenContextProvider from './context/LoadingScreenContext';
import NewDocumentContextProvider from './context/NewDocumentContext';
import NewJiraProvider from './context/NewJiraContext';
import ProjectContextProvider from './context/ProjectContext';
import ToggleFeatureProvider from './context/ToggleFeatureContext';
import UserContextProvider from './context/UserContext';
import './index.css';
import * as serviceWorker from './serviceWorker';

const tagManagerArgs = {
  gtmId: 'GTM-5JBN6LP',
};

TagManager.initialize(tagManagerArgs);

let defaultQueryOptions = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
  },
};

// Vérifie si vous êtes dans l'environnement Cypress
if (window.Cypress) {
  defaultQueryOptions = {
    ...defaultQueryOptions,
    queries: {
      ...defaultQueryOptions.queries,
      cacheTime: 0, // Définit la durée de mise en cache à 0 pour désactiver le cache dans Cypress
      staleTime: 0,
    },
  };
}

const queryClient = new QueryClient({
  defaultOptions: defaultQueryOptions,
});

const container = document.getElementById('root');
const root = createRoot(container);

const ReactQueryDevtoolsProduction = React.lazy(() =>
  process.env.NODE_ENV === 'production'
    ? import('@tanstack/react-query-devtools/production').then((d) => ({
        default: d.ReactQueryDevtools,
      }))
    : import('@tanstack/react-query-devtools').then((d) => ({
        default: d.ReactQueryDevtools,
      }))
);

root.render(
  <QueryClientProvider client={queryClient}>
    <LoadingScreenContextProvider>
      <ProjectContextProvider>
        <NewJiraProvider>
          <NewDocumentContextProvider>
            <UserContextProvider>
              <ToggleFeatureProvider>
                <App />
              </ToggleFeatureProvider>
            </UserContextProvider>
          </NewDocumentContextProvider>
        </NewJiraProvider>
      </ProjectContextProvider>
    </LoadingScreenContextProvider>
    {!window.Cypress && process.env.REACT_APP_SHOW_DEV_TOOLS === 'true' && (
      <ReactQueryDevtoolsProduction />
    )}
  </QueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
