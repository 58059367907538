import React, { useContext, useState, useEffect } from 'react';

import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { UserContext } from '@context/UserContext';

import Edenred from '@img/Logo_Edenred.svg';
import EdenredWhite from '@img/Logo_Edenred_white.svg';
import { ReactComponent as Deconnexion } from '@img/icons/DECO.svg';
import { ReactComponent as UserSVG } from '@img/icons/user.svg';

import './Navbar.scss';

export default function NavbarDefault() {
  const infoClient = JSON.parse(localStorage.getItem('infoClient'));
  const { user } = useContext(UserContext);
  const [environment, setEnvironment] = useState(null);

  useEffect(() => {
    switch (window.location.hostname) {
      case 'espace-client.front':
        setEnvironment('local');
        break;
      case 'espace-client-rec.prowebce.net':
        setEnvironment('recette');
        break;
      case 'espace-client-pprd.prowebce.net':
        setEnvironment('pre-prod');
        break;
      default:
        setEnvironment(null);
        break;
    }
  }, []);

  return (
    <nav className="navbar navbar-btn-full-mobile navbar-expand">
      <Link to={{ pathname: `/` }} className="logos">
        <p className="navbar-brand mb-0 fw-bold" href="#">
          Espace Client
        </p>
        <img
          className="d-none d-md-block logo_edenred"
          src={Edenred}
          alt="logo edenred"
        />
        <img
          className="logo_edenred_white d-md-none"
          src={EdenredWhite}
          alt="logo edenred"
        />
        {environment && (
          <div className={`environment d-none d-md-block ${environment}`}>
            {environment}
          </div>
        )}
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarsExample04"
        aria-controls="navbarsExample04"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      {infoClient && (
        <>
          <div className="collapse navbar-collapse infos">
            <ul className="navbar-nav me-auto"></ul>
            <div className="my-2 my-md-0 child-collapse">
              <div className="d-flex align-items-center">
                <div className="flex-column justify-content-center me-3 info_client d-none d-md-flex">
                  <p className="fw-bold mb-0">
                    {infoClient.societe || (
                      <Skeleton color="#e6e6e6" width={120} />
                    )}
                  </p>
                  <small>
                    {infoClient.id ? (
                      `n° client : ${infoClient.id}`
                    ) : (
                      <Skeleton color="#e6e6e6" width={120} />
                    )}
                  </small>
                </div>
                <div className="d-flex flex-column justify-content-center text-center me-3 user">
                  <Link to={{ pathname: `/profil` }}>
                    <UserSVG className="stroke-white" />
                  </Link>
                  <small>
                    {/* {infoContact.prenom || (
                      <Skeleton
                        color="#e256e6"
                        highlightColor="#444"
                        width={50}
                      />
                    )} */}
                    {user ? (
                      user.prenom
                    ) : (
                      <Skeleton
                        color="#e256e6"
                        highlightColor="#444"
                        width={50}
                      />
                    )}
                  </small>
                </div>
                <div className="d-flex flex-column justify-content-center text-center cursor-pointer deconnexion">
                  <Link to={{ pathname: `/logout` }}>
                    <Deconnexion className="stroke-white" />
                    <small className="text-primary">Déconnexion</small>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="info_client info_client_mobile d-md-none">
            <p className="fw-bold mb-0">
              {infoClient.societe || <Skeleton color="#e6e6e6" width={120} />}
            </p>
            <small>
              {infoClient.id ? (
                `n° client : ${infoClient.id}`
              ) : (
                <Skeleton color="#e6e6e6" width={120} />
              )}
            </small>
          </div>
        </>
      )}
    </nav>
  );
}
