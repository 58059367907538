import React, { useEffect } from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import LoadingButton from '@components/LoadingButton/LoadingButton';

import {
  useSubmitResiliationPost,
  useResendConfirmationResiliationPost,
} from '@hooks/useApi';

import { getQueryParameters } from '@helpers';

const LoadingSkeleton = () => (
  <div className="w-100">
    <div className="row me-md-0">
      <div className="col-12">
        <div className="col-12 skeleton">
          <SkeletonTheme width="100%" height="100%">
            <section className="d-flex flex-column align-items-center justify-center w-100 ms-4 ms-md-0">
              <Skeleton height={22} width="50%" className="mb-3" />
              <Skeleton height={20} width="30%" className="mb-3" />
              <Skeleton height={20} width="80%" className="mb-3" />
              <div className="text-center w-100">
                <Skeleton
                  height={44}
                  width="20%"
                  className="mb-4 rounded-pill mt-3"
                />
              </div>
            </section>
          </SkeletonTheme>
        </div>
      </div>
    </div>
  </div>
);

export default function Send() {
  const location = useLocation();

  const verified = getQueryParameters(location.search)?.verified;
  const token = getQueryParameters(location.search)?.token;

  const {
    mutate: mutateSubmitResiliationPost,
    isLoading: isSubmitResiliationLoading,
    isSuccess: isSubmitResiliationSuccess,
    isError: isSubmitResiliationError,
    error: submitResiliationError,
  } = useSubmitResiliationPost({
    onSettled: (data, error, variables, context) => {
      if (error?.response?.data?.error === 'Termination already done') {
        //change error message
        throw new Error(
          'Une demande de résiliation est déjà en cours de traitement. Nous ne pouvons pas enregistrer votre nouvelle demande pour le moment.'
        );
      }
    },
  });

  const {
    mutate: mutateResendConfirmationResiliationPost,
    isLoading: isResendConfirmationResiliationLoading,
    isError: isResendConfirmationResiliationError,
    isSuccess: isResendConfirmationResiliationSuccess,
    error: resendConfirmationResiliationError,
  } = useResendConfirmationResiliationPost();

  useEffect(() => {
    if (verified === 'true' && token)
      mutateSubmitResiliationPost({
        token,
      });
  }, [mutateSubmitResiliationPost, token, verified]);

  const resendEmail = () => {
    if (token)
      mutateResendConfirmationResiliationPost({
        token,
      });
  };

  if (
    isSubmitResiliationLoading &&
    (!isSubmitResiliationError || isSubmitResiliationSuccess)
  )
    return <LoadingSkeleton />;

  return (
    <div className="px-3">
      {isSubmitResiliationSuccess && (
        <>
          <h5 className="fw-bold text-primary mb-4">
            Votre demande de résiliation est enregistrée !
          </h5>
          <p className="text-placeholder mb-1 fw-bold">
            La demande est transmise au service résiliation
          </p>
          <p className="text-placeholder mb-5">
            Vous allez recevoir le récapitulatif de votre demande sur le mail
            préalablement saisi.
          </p>
        </>
      )}
      {isSubmitResiliationError && (
        <>
          <h5 className="fw-bold text-error mb-4">
            Votre demande de résiliation n'a pas pu être enregistrée !
          </h5>
          <p className="text-placeholder mb-1 fw-bold">
            {submitResiliationError?.response?.data?.error ===
            'Termination already done'
              ? 'Une demande de résiliation est déjà en cours de traitement. Nous ne pouvons pas enregistrer votre nouvelle demande pour le moment.'
              : "La demande n'a pas pu être transmise au service résiliation"}
          </p>
          <p className="text-placeholder mb-5">
            Veuillez réessayer ultérieurement.
          </p>
        </>
      )}
      {isResendConfirmationResiliationError && (
        <div className="text-center fs-7 mt-4 mb-3 text-primary" role="alert">
          <span className="fw-bold d-block">
            Le mail n'a pas pu être envoyé !
          </span>
          <span>
            Vous avez déjà demandé un renvoi de mail, veuillez réessayer
            ultérieurement.
          </span>
        </div>
      )}
      {isResendConfirmationResiliationSuccess ? (
        <div className="text-center fs-7 mt-4 mb-3 text-primary" role="alert">
          <span className="fw-bold d-block">Le mail est envoyé !</span>
          <span>
            Merci de vérifier votre boite mail, si vous n'avez pas reçu de mail,
            pensez à vérifier vos spams.
          </span>
        </div>
      ) : (
        submitResiliationError?.response?.status === 403 &&
        token && (
          <div className="text-center">
            <LoadingButton
              type="button"
              classes="btn btn-white-border rounded-pill fw-bold px-4 py-2 mt-3 d-block mx-auto mb-3"
              id="resend-mail"
              text="Renvoyer le mail"
              disabled={isResendConfirmationResiliationError}
              loading={isResendConfirmationResiliationLoading}
              spinnerHeight="20"
              onClick={() => {
                resendEmail();
              }}
            />
          </div>
        )
      )}
      <div className="text-center">
        <Link to="/" className="btn btn-primary rounded-pill fw-bold px-4 py-2">
          Revenir à l'accueil
        </Link>
      </div>
    </div>
  );
}
