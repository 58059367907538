import React, { useEffect, useRef } from 'react';

import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';
import 'datatables.net-select';
import 'datatables.net-select-dt/css/select.dataTables.min.css';
import $ from 'jquery';

import './BillTable.scss';
import { French } from './traductions/FrenchTrad';

const Table = ({
  name,
  columns,
  data,
  searchBarInput,
  setDatatable,
  setDatatableThis,
  selectedType,
  order,
  dom,
  language,
  setLoading,
  ajax,
  nbView,
  responsive,
  pageLength,
  dataCy,
  refreshTable,
  setRefreshTable,
}) => {
  const table = useRef(null);

  useEffect(() => {
    if (typeof $.fn.dataTableExt.oSort['currency-asc'] !== 'function') {
      $.extend($.fn.dataTableExt.oSort, {
        'currency-pre': function (a) {
          a =
            a === '-'
              ? 0
              : a
                  .split(',')
                  .join('')
                  .replace(/[^\d\-\.]/g, '');
          return parseFloat(a);
        },
        'currency-asc': function (a, b) {
          return a - b;
        },
        'currency-desc': function (a, b) {
          return b - a;
        },
      });
    }

    const constDatatable = $(table.current).DataTable({
      aoColumns: [{ sType: 'currency' }],
      dom: dom !== undefined ? dom : 'rt<"bottom"p><"clear">',
      data: data,
      ordering: true,
      columns: columns,
      responsive:
        responsive !== undefined
          ? responsive
          : {
              details: {
                renderer: $.fn.dataTable.Responsive.renderer.listHiddenNodes(),
                // type: "column",
              },
            },
      pageLength: pageLength !== undefined ? pageLength : 10,
      language: language !== undefined ? language : French,
      order:
        order !== undefined
          ? order
          : [[columns.findIndex((column) => column.title === 'Date'), 'desc']],
      autoWidth: false,
      select: {
        style: 'multi',
        selector: 'td.select-checkbox',
        info: false,
      },
    });

    setDatatable && setDatatable(table);
    setDatatableThis && setDatatableThis(constDatatable);

    return () => {
      constDatatable.destroy();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let tabEl = document.querySelectorAll('a.js-nav-link');
    tabEl.forEach((item, index) => {
      let tabPane = document.querySelector(
        '#' + item.getAttribute('aria-controls')
      );
      let $dataTable = tabPane.querySelector('.dataTable');

      item.removeEventListener('shown.bs.tab', null);

      if ($dataTable !== null && $dataTable !== undefined) {
        item.addEventListener('shown.bs.tab', function (event) {
          if (item.classList.contains('active')) {
            rebuildDataTable();
            $($dataTable)?.dataTable()?.fnAdjustColumnSizing();
          }
        });
      }
    });

    return () => {
      tabEl.forEach((item, index) => {
        window.document.removeEventListener('shown.bs.tab', item);
      });
    };
  }, []);

  useEffect(() => {
    $(table.current).dataTable().fnFilter(searchBarInput);
  }, [searchBarInput]);

  useEffect(() => {
    let regex = '';

    if (selectedType) {
      let dataRegex = selectedType.map((value) => {
        return value.value
          ? '' + $.fn.dataTable.util.escapeRegex(value.value) + ''
          : null;
      });

      regex = dataRegex.join('|');
    }

    $(table.current)
      .dataTable()
      .api()
      .column([columns.findIndex((column) => column.title === 'Type')])
      .search(regex ? '' + regex + '' : '', true, false)
      .draw();
  }, [selectedType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      // //update datatable data
      rebuildDataTable();
    }
  }, [data]);

  useEffect(() => {
    if (name) {
      $(`.table.${name}`)?.DataTable()?.columns?.adjust();
      const currentDataTable = $(`.table.${name}`)?.dataTable();

      window.onresize = function () {
        let api = currentDataTable.api(true).columns.adjust();
        let settings = api.settings()[0];

        if (settings) currentDataTable?.fnAdjustColumnSizing();
      };

      setRefreshTable && setRefreshTable(false);
    }

    return () => {
      window.onresize = null;
    };
  }, [refreshTable]);

  const rebuildDataTable = (name) => {
    const currentDataTable = $(
      name ? `.table.${name}` : table.current
    ).dataTable();
    const datatable = currentDataTable?.api();
    datatable?.clear();
    datatable?.rows?.add(data);
    datatable?.draw(false);
  };

  return (
    <div>
      <table
        className={`table w-100 mw-100 ${name}`}
        ref={table}
        data-nb-view={nbView ? nbView : ''}
        data-cy={dataCy}
      ></table>
    </div>
  );
};

export default React.memo(Table);
