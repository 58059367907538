import React from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { ReactComponent as Arrow } from '@img/icons/arrow-thin.svg';

import './sliderNotifications.scss';

export default function SliderNotifications({ children, ...props }) {
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <Arrow
        className={`${className}`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <Arrow
        className={`${className}`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider
      {...settings}
      className="slider slider-notifications"
      nextArrow={<SampleNextArrow />}
      prevArrow={<SamplePrevArrow />}
      {...props}
    >
      {children}
    </Slider>
  );
}
