import React from 'react';

import classNames from 'classnames';

import GoogleTagManagement from '@GoogleTagManagement';

export default function AppLayout(props) {
  return (
    <div
      className={`min-h-100 d-flex flex-column site-content ${
        !props.sideBar && ``
      }`}
    >
      <GoogleTagManagement titlePage={props.title} />
      {props.navBar && React.createElement(props.navBar)}
      <div
        className={classNames({
          'd-flex': true,
          'min-h-100': !props.navBar,
          'min-h-100-with-navBar': props.navBar,
          [`container-${props.name}`]: true,
          'h-100': props.name === 'not-found',
          'container-fluid h-100 container-fluid-desktop position-relative p-0':
            props.sideBar,
          container: !props.sideBar,
        })}
      >
        {props.sideBar && React.createElement(props.sideBar)}

        {props.sideBar ? (
          <div className={`container h-100 d-flex container-next-to-sidebar `}>
            {props.children}
          </div>
        ) : props.children ? (
          props.children
        ) : (
          ''
        )}
      </div>
      {props.footer && React.createElement(props.footer)}
    </div>
  );
}
