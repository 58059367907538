import React, { useEffect, useContext } from 'react';

import { logout } from '@authProvider';

import Loader from '@components/Loader/Loader';

import { UserContext } from '@context/UserContext';

import { apiCallWithToken } from '@helpers';

import './logout.scss';

export default function Logout() {
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    apiCallWithToken(`/api/edenred/connect/logout`, 'GET').then((res) => {
      const { id_token_hint } = res.data;
      const link =
        process.env.REACT_APP_LOGOUT_URL +
        '?post_logout_redirect_uri=' +
        process.env.REACT_APP_FRONT_URL +
        '&id_token_hint=' +
        id_token_hint;
      setUser(null);
      logout();
      window.location.href = link;
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center text-center">
      <h1 className="d-block fw-bold mb-5 text-primary">
        Déconnexion du site...
      </h1>
      <Loader />
    </div>
  );
}
