import React from 'react';

import { Modal } from 'react-bootstrap';

import { ReactComponent as Close } from '@img/icons/close.svg';

import './ModalDefault.scss';

export default function ModalDefault({
  show,
  handleClose,
  handleReturn,
  size = 'md',
  title,
  footer,
  children,
  className,
  noHeader = false,
  noCloseButton = false,
  ...rest
}) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName={`modal-equipment ${className}`}
      aria-labelledby={`contained-modal-title-vcenter`}
      backdrop="static"
      centered
      size={size}
      {...rest}
    >
      {!noHeader && (
        <Modal.Header>
          {handleReturn && (
            <button
              type="button"
              className="return text-primary"
              onClick={handleReturn}
            >
              <i className="fa fa-arrow-left me-2"></i>Retour
            </button>
          )}
          {title && (
            <Modal.Title dialogClassName="text-primary">{title}</Modal.Title>
          )}
          {handleClose && !noCloseButton && (
            <button type="button" className="btn-close" onClick={handleClose}>
              <Close className="closeSvg stroke-primary" aria-hidden="true" />
              <span className="visually-hidden">Close</span>
            </button>
          )}
        </Modal.Header>
      )}
      {children && <Modal.Body>{children}</Modal.Body>}
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
}
