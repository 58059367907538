import React from 'react';

import { Link } from 'react-router-dom';

import CardCenter from '@components/Card/CardCenter';

import GoogleTagManagement from '@GoogleTagManagement';

import ConnectionProblemImage from '@img/connection_problem.svg';

import './connectionProblem.scss';

export default function ConnectionProblem() {
  return (
    <CardCenter row_class="min-height-full-with-navbar">
      <GoogleTagManagement titlePage="Compté créé" />
      <div className="card-body text-center">
        <h4 className="card-title fw-bold">Problème de connexion ?</h4>
        <img
          src={ConnectionProblemImage}
          className="img-fluid"
          alt="probleme de connexion"
        ></img>
        <p className="card-text">
          Si vous n'arrivez toujours pas à vous connecter, contactez votre
          chargé de compte.
        </p>

        <Link to={{ pathname: `/` }}>
          <button
            type="button"
            className="btn btn-primary btn-block rounded-pill"
          >
            J'ai compris
          </button>
        </Link>
      </div>
    </CardCenter>
  );
}
