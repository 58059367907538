import React, { useState, useContext, useEffect } from 'react';

import axios from 'axios';
import Skeleton from 'react-loading-skeleton';

import { CreateAppointmentContext } from '@context/CreateAppointmentContext';

import GoogleTagManagement from '@GoogleTagManagement';

import './telephone.scss';

export default function Telephone({ infoResponsable }) {
  const { setTelephone, goToNextStep } = useContext(CreateAppointmentContext);
  const [infoContact, setInfoContact] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [errorTel, setErrorTel] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [editTelephonePortable, setEditTelephonePortable] = useState('');
  const [editTelephoneFixe, setEditTelephoneFixe] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/skynet/contact`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem('REACT_TOKEN_AUTH_KEY')
          )}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setInfoContact(res.data.contact);
        setEditTelephonePortable(res.data.contact.telephonePortable || '');
        setEditTelephoneFixe(res.data.contact.telephone || '');
      })
      .catch((error) => {});
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    setErrorText('');
    if (editTelephonePortable.length === 0 && editTelephoneFixe.length === 0) {
      setErrorTel(true);
    } else {
      setErrorTel(false);

      axios
        .put(
          `${process.env.REACT_APP_BACK_URL}/api/skynet/contact`,
          JSON.stringify({
            telephonePortable: editTelephonePortable,
            telephone: editTelephoneFixe,
          }),
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem('REACT_TOKEN_AUTH_KEY')
              )}`,
            },
          }
        )
        .then((res) => {
          setTelephone(editTelephonePortable || editTelephoneFixe);
          goToNextStep();
        })
        .catch((error) => {
          setErrorText(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    if (editTelephonePortable === '' && editTelephoneFixe === '') {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [editTelephonePortable, editTelephoneFixe]);

  return (
    <div className="step telephone">
      <GoogleTagManagement titlePage="Confirmation du numéro de téléphone" />
      <div className="title text-primary fw-bold text-center">
        Merci de confirmer votre numéro de téléphone
      </div>
      <div className="text-primary telephone-number text-center">
        {isLoading ? (
          <Skeleton width={300} className="skeleton" />
        ) : (
          (infoContact && infoContact.telephonePortable) ||
          (infoContact && infoContact.telephone)
        )}
      </div>
      <div className="text-center button d-flex flex-column align-items-center">
        <button
          type="button"
          className="btn btn-primary rounded-pill fw-600"
          onClick={() => {
            setTelephone(
              (infoContact && infoContact.telephonePortable) ||
                (infoContact && infoContact.telephone)
            );
            goToNextStep();
          }}
        >
          Valider
        </button>
        <span
          className="text-primary cursor-pointer "
          data-cy="update-phone"
          onClick={() => !isLoading && setShowInput(true)}
        >
          <u>Mettre à jour mon numéro de téléphone</u>
        </span>

        {showInput ? (
          <>
            <p className={`text-primary fw-bold ${errorTel && 'error'}`}>
              Saisissez au moins 1 numéro de téléphone
            </p>
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="inputs d-flex">
                <div
                  className={`form-group telephone-principal ${
                    errorTel && 'error'
                  }`}
                >
                  <label htmlFor="inputTelephonePrincipal">
                    Téléphone portable
                  </label>
                  <input
                    type="tel"
                    name="telephone"
                    className="col-12 form-control mt-2"
                    id="inputTelephonePrincipal"
                    value={editTelephonePortable}
                    onChange={(e) => {
                      setEditTelephonePortable(e.target.value);
                    }}
                  />
                </div>
                <div className={`form-group ${errorTel && 'error'}`}>
                  <label htmlFor="inputTelephoneSecondaire">
                    Téléphone fixe
                  </label>
                  <input
                    type="tel"
                    name="telephonePortable"
                    className="col-12 form-control mt-2"
                    id="inputTelephoneSecondaire"
                    value={editTelephoneFixe}
                    onChange={(e) => {
                      setEditTelephoneFixe(e.target.value);
                    }}
                  />
                </div>
              </div>
              <p className={`text-primary fw-bold ${errorText && 'error'}`}>
                {errorText && errorText}
              </p>
              <button
                type="submit"
                className="btn btn-primary rounded-pill fw-600"
                disabled={disabledButton ? 'disabled' : ''}
                data-cy="submit-update-phone"
              >
                Enregistrer et valider
              </button>
            </form>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
