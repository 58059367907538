import React from 'react';

import './Deploiement.scss';

export default function Deploiement() {
  return (
    <div className="project-content col-12 bg-white deploiement">
      <h5 className="rect_info_title">Déploiement</h5>

      <p>
        Bonne nouvelle ! L'ouverture de votre site à vos bénéficiaires est
        imminente. Dernière étape, votre atelier de prise en main avec votre
        interlocuteur Edenred. Il vous donnera toutes les informations
        nécessaires pour gérer votre site après le lancement. <br />
        <br />
        <strong>
          Vous avez convenu d'une date de lancement et souhaitez la modifier
          ?&nbsp;
        </strong>
        Prévenez dès maintenant votre interlocuteur Edenred pour définir une
        nouvelle date de lancement.
      </p>
    </div>
  );
}
