import React, { createContext, useEffect, useState } from 'react';

import axios from 'axios';

import { useAuth } from '../authProvider';

const NewDocumentContext = createContext();

export { NewDocumentContext };

const NewDocumentContextProvider = (props) => {
  const [hasNewDocument, setHasNewDocument] = useState(false);
  const [hasDocumentToSign, setHasDocumentToSign] = useState(false);
  const [nbNotView, setNbNotView] = useState(0);
  const [logged] = useAuth();

  useEffect(() => {
    if (logged) {
      const requestOne = axios.get(
        `${process.env.REACT_APP_BACK_URL}/api/skynet/envelopes/list`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem('REACT_TOKEN_AUTH_KEY')
            )}`,
          },
        }
      );

      const requestTwo = axios.get(
        `${process.env.REACT_APP_BACK_URL}/api/skynet/documents`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem('REACT_TOKEN_AUTH_KEY')
            )}`,
          },
        }
      );
      axios
        .all([requestOne, requestTwo])
        .then((res) => {
          res[0].data.findIndex(
            (item) =>
              item.status !== 'completed' && item.contactStatus !== 'completed'
          ) !== -1
            ? setHasDocumentToSign(true)
            : setHasDocumentToSign(false);

          const nbNotViewCalcul = res[1].data.filter(
            (item) => item.view === false
          ).length;

          if (nbNotViewCalcul !== 0) {
            setHasNewDocument(true);
            setNbNotView(nbNotViewCalcul);
          } else {
            setHasNewDocument(false);
            setNbNotView(0);
          }
        })
        .catch((error) => {});
    }
  }, [logged]);

  useEffect(() => {
    if (nbNotView === 0 && hasNewDocument === true) {
      changeHasNewDocument(false);
    } else {
      changeHasNewDocument(true);
    }
  }, [nbNotView]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeHasNewDocument = (toggle) => {
    let hasNewDocumentState = { ...hasNewDocument };
    hasNewDocumentState = toggle;
    setHasNewDocument(hasNewDocumentState);
  };

  const changeDocumentToSign = (toggle) => {
    let hasDocumentToSignState = { ...hasDocumentToSign };
    hasDocumentToSignState = toggle;
    setHasDocumentToSign(hasDocumentToSignState);
  };

  const decreaseNbNotView = (nb) => {
    let nbNotViewState;
    if (nb) {
      nbNotViewState = nb;
    } else {
      nbNotViewState = { ...nbNotView };
    }
    nbNotViewState = nbNotViewState - 1;
    setNbNotView(nbNotViewState);
  };

  return (
    <NewDocumentContext.Provider
      value={{
        hasNewDocument,
        hasDocumentToSign,
        nbNotView,
        changeDocumentToSign,
        changeHasNewDocument,
        decreaseNbNotView,
      }}
    >
      {props.children}
    </NewDocumentContext.Provider>
  );
};

export default NewDocumentContextProvider;
