import React from 'react';

import dayjs from 'dayjs';
import $ from 'jquery';

export default function InformationGeneralesDefault({
  infoContact,
  infoClient,
  functionContact,
  setIsInput,
}) {
  const isBilling =
    infoClient && infoClient.adresses.find((address) => address.isBilling);
  const isMeeting =
    infoClient && infoClient.adresses.find((address) => address.isMeeting);

  return (
    <div className="px-3">
      <h5 className="fw-bold text-primary">Informations générales</h5>
      <div className="form-group col-md-6">
        <label htmlFor="inputCivilite">Civilité</label>
        <p className="h6 border-bottom">
          {infoContact && infoContact.civilite}
        </p>
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="inputNom">Nom</label>
        <p className="h6 border-bottom">{infoContact && infoContact.nom}</p>
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="inputPrenom">Prénom</label>
        <p className="h6 border-bottom">{infoContact && infoContact.prenom}</p>
      </div>
      <h5 className="fw-bold  text-primary">Informations de contact</h5>
      <div className="form-inline col-12  pb-2">
        <div className="form-group col-md-6">
          <label htmlFor="inputFonction">Fonction</label>
          <p className="col-12  h6 border-bottom  ">
            {functionContact && functionContact.fonction}
          </p>
        </div>
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="inputTelephonePrincipal">Téléphone portable</label>
        <p className="col-12  h6 border-bottom  ">
          {infoContact && infoContact.telephonePortable}
        </p>
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="inputTelephoneSecondaire">Téléphone fixe</label>
        <p className="col-12  h6 border-bottom  ">
          {infoContact && infoContact.telephone}
        </p>
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="inputPreferenceContact">Préférence de contact</label>
        <p className="col-12  h6 border-bottom  ">
          {infoContact && infoContact.preferenceAm && 'Matin'}
          {infoContact &&
            infoContact.preferenceAm &&
            infoContact.preferencePm &&
            ' et '}
          {infoContact && infoContact.preferencePm && 'Après-midi'}
        </p>
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="InputEmail">Adresse e-mail</label>
        <p className="col-12  h6 border-bottom">
          {infoContact && infoContact.mail}
        </p>
      </div>
      <h5 className="fw-bold  text-primary">Adresse du CSE</h5>
      <div className="form-group col-md-6">
        <label htmlFor="InputAdressePostale">Adresse de facturation</label>
        <p className="col-12  h6 border-bottom">
          {isBilling && isBilling.adresseLine1}
        </p>
        <label htmlFor="InputAdressePostale">Complément d'adresse</label>
        <p className="col-12  h6 border-bottom  ">
          {isBilling && isBilling.adresseLine2}
        </p>
        <p className="col-12  h6 border-bottom  ">
          {isBilling && isBilling.adresseLine3}
        </p>
      </div>
      <div className="d-flex align-items-md-center flex-column flex-md-row col-12 p-0 pb-2">
        <div className="form-group col-12 col-md-2">
          <label htmlFor="inputCodePostal">Code postal</label>
          <p className="col-12  h6 border-bottom  ">
            {isBilling && isBilling.codePostal}
          </p>
        </div>
        <div className="form-group col-12 col-md-4">
          <label htmlFor="inputVille">Ville</label>
          <p className="col-12  h6 border-bottom  ">
            {isBilling && isBilling.ville}
          </p>
        </div>
      </div>
      <div className="form-group col-md-6 address-rdv">
        <label htmlFor="InputAdresseFacturation">
          Adresse postale pour les rendez-vous
        </label>
        <p className="col-12  h6 border-bottom">
          {isMeeting && isMeeting.adresseLine1}
        </p>
        <label htmlFor="InputAdresseFacturation">Complément d'adresse</label>
        <p className="col-12  h6 border-bottom  ">
          {isMeeting && isMeeting.adresseLine2}
        </p>
        <p className="col-12  h6 border-bottom  ">
          {isMeeting && isMeeting.adresseLine3}
        </p>
      </div>
      <div className="d-flex align-items-md-center flex-column flex-md-row col-12 p-0 pb-2">
        <div className="form-group col-12 col-md-2 mb-0">
          <label htmlFor="inputCodePostal">Code postal</label>
          <p className="col-12  h6 border-bottom  mb-0">
            {isMeeting && isMeeting.codePostal}
          </p>
        </div>
        <div className="form-group col-12 col-md-4 mb-0">
          <label htmlFor="inputVille">Ville</label>
          <p className="col-12  h6 border-bottom  mb-0">
            {isMeeting && isMeeting.ville}
          </p>
        </div>
      </div>
      <h5 className="fw-bold  text-primary">Informations complémentaires</h5>
      <div className="form-inline col-12  pb-2">
        <div className="form-group col-md-2">
          <label htmlFor="inputEffectif">Effectif de la société</label>
          <p className="col-12  h6 border-bottom  ">
            {infoClient && infoClient.societeNbSalaries}
          </p>
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="inputElections">Date des prochaines élections</label>
          <p className="col-12  h6 border-bottom  ">
            {infoClient && dayjs(infoClient.dateElection).format('DD/MM/YYYY')}
          </p>
        </div>
      </div>
      <div className="text-center">
        <button
          type="submit"
          id="sendlogin"
          className="btn btn-primary btn-block rounded-pill"
          onClick={() => {
            $('#root').animate(
              {
                scrollTop: $('#root').offset().top,
              },
              0
            );
            setIsInput(true);
          }}
        >
          Modifier les informations
        </button>
      </div>
    </div>
  );
}
