import React, { useState, useEffect } from 'react';

import $ from 'jquery';

import { SelectList } from '@molecules';

import LoadingButton from '@components/LoadingButton/LoadingButton';
import Modal from '@components/Modal/ModalDefault';

import { useSkynetEmailPost } from '@hooks/useApi';

import { ReactComponent as PieceJointe } from '@img/icons/piece-jointe.svg';

export default function ModalContactCdp({
  showModalContact,
  showModalSuccess,
  setShowModalContact,
  setShowModalSuccess,
  isNomDomaine,
  setIsNomDomaine,
  selectedDomain,
}) {
  const options = require('@datas/motifsCDP.json');
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [textareaValue, setTextareaValue] = useState('');
  const [motifSelect, setMotifSelect] = useState(null);
  const [fileName, setFileName] = useState('');
  const [filesList, setFilesList] = useState([]);
  const [errorSize, setErrorSize] = useState(false);
  const [errorFormat, setErrorFormat] = useState(false);
  const [errorNoAttachment, setErrorNoAttachment] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);

  const [loading, setLoading] = useState(false);

  const { mutate: sendMailMutation } = useSkynetEmailPost({
    onSuccess: () => {
      setShowModalContact(false);
      setShowModalSuccess(true);
      resetModal();
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (isNomDomaine) {
      setMotifSelect('Mon projet');
      setTextareaValue(
        `Bonjour\n\nVous trouverez en pièce jointe le formulaire de création de nom de domaine rempli et signé.\n\nCordialement`
      );
    }
  }, [isNomDomaine]);

  useEffect(() => {
    textareaValue.trim() === '' || motifSelect === null
      ? setButtonIsDisabled(true)
      : setButtonIsDisabled(false);
  }, [textareaValue, motifSelect]);

  const sendMail = (e) => {
    e.preventDefault();
    setLoading(true);
    let error = false;
    if (motifSelect === '' && textareaValue === '') {
      error = 'all';
    } else if (motifSelect === '') {
      error = 'select';
    } else if (textareaValue === '') {
      error = 'textarea';
    } else {
      let data = { motif: motifSelect, content: textareaValue };
      setErrorSubmit(false);

      sendMailMutation({
        typeMail: 'cdp',
        data: data,
      });
    }
    if (error !== false) setLoading(false);
    setErrorSubmit(error);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getFileExtension = (fname) => {
    return fname.slice((Math.max(0, fname.lastIndexOf('.')) || Infinity) + 1);
  };

  const sendMailDomain = async (e) => {
    e.preventDefault();
    setLoading(true);
    let error = false;

    if (filesList.length === 0) {
      setErrorNoAttachment(true);
      setLoading(false);
    } else {
      setErrorNoAttachment(false);
    }

    if (motifSelect === '' && textareaValue === '') {
      error = 'all';
    } else if (motifSelect === '') {
      error = 'select';
    } else if (textareaValue === '') {
      error = 'textarea';
    } else if (filesList.length !== 0) {
      let data = { motif: motifSelect, content: textareaValue };
      if (filesList.length === undefined) {
        data.file = await toBase64(filesList);
        data.fileName = filesList.name;
      }
      setErrorSubmit(false);

      if (isNomDomaine) {
        data.domain = selectedDomain;
      }

      sendMailMutation({
        typeMail: isNomDomaine ? 'domain' : 'cdp',
        data: data,
      });
    }
    if (error !== false) setLoading(false);
    setErrorSubmit(error);
  };

  const resetModal = () => {
    setTextareaValue('');
    setFileName('');
    setFilesList([]);
    setIsNomDomaine(false);
    setErrorSubmit(false);
    setMotifSelect('');
  };

  const onFilesChange = (e) => {
    setErrorSize(false);
    setErrorFormat(false);
    setErrorNoAttachment(false);
    let file = e.target.files[0];
    let acceptFormat = ['pdf'];
    if (file !== undefined) {
      if (
        file.size <= 20000000 &&
        acceptFormat.includes(getFileExtension(file.name))
      ) {
        setFileName(file.name);
        setFilesList(file);
      } else {
        e.target.value = '';

        file.size > 20000000 && setErrorSize(true);
        acceptFormat.includes(getFileExtension(file.name)) === false &&
          setErrorFormat(true);
      }
    } else {
      setFileName('');
      setFilesList([]);
    }
  };

  return (
    <div>
      <Modal
        show={showModalContact}
        className="modal-project"
        handleClose={() => {
          setShowModalContact(false);
          resetModal();
        }}
      >
        <div className="">
          <h2 className="fw-bold text-primary">
            Contacter votre chef de projet
          </h2>
          <h6 className="text-primary">Tous les champs sont obligatoires.</h6>
          <form
            autoComplete="off"
            onSubmit={isNomDomaine ? sendMailDomain : sendMail}
            className="text-primary"
          >
            <div className="form-group">
              <label htmlFor="inputFonction">
                <small>Motif</small>
              </label>
              <SelectList
                options={options}
                onChange={(e) => {
                  setMotifSelect(e.value);
                }}
                value={
                  isNomDomaine
                    ? {
                        value: 'Mon projet',
                        label: 'Mon projet',
                      }
                    : motifSelect && {
                        value: motifSelect,
                        label: motifSelect,
                      }
                }
                placeholder="Sélectionner un motif"
                className={`text-primary ${
                  errorSubmit !== 'textarea' && errorSubmit !== false && 'error'
                }`}
              />
              {errorSubmit !== 'textarea' && errorSubmit !== false && (
                <small className="text-error">
                  Veuillez sélectionner un motif
                </small>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="formControlTextarea1">
                <small>Saisissez votre message</small>
              </label>
              <textarea
                className={`form-control h3 text-primary ${
                  errorSubmit !== 'select' &&
                  errorSubmit !== false &&
                  'border-danger'
                }`}
                id="formControlTextarea1"
                rows="9"
                value={textareaValue}
                required="required"
                onChange={(e) => setTextareaValue(e.target.value)}
              ></textarea>
              {errorSubmit !== 'select' && errorSubmit !== false && (
                <small className="text-error">
                  Ce champ ne peux être vide.
                </small>
              )}
            </div>
            {isNomDomaine && (
              <div className="form-group">
                <label htmlFor="inputFile">
                  <small>Pièce jointe</small>
                </label>
                <div className="custom-file-content">
                  <div className="custom-file col-12">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFileLang"
                      lang="fr"
                      onChange={onFilesChange}
                      hidden={true}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileLang"
                      title={fileName}
                    >
                      <span className="text-truncate">
                        {fileName === '' ? 'Aucun fichier' : fileName}
                      </span>
                      <PieceJointe />
                    </label>
                  </div>
                  <button
                    type="button"
                    className="btn text-primary border border-primary rounded-pill fw-bold d-none d-md-block"
                    onClick={() => {
                      $('.custom-file-input').click();
                    }}
                  >
                    Ajouter un fichier
                  </button>
                  <small>Taille maximale : 20 Mo</small>
                  <small>Le format autorisé est le pdf.</small>
                  {errorSize && (
                    <small className="text-error">
                      Fichier trop volumineux
                    </small>
                  )}
                  {errorFormat && (
                    <small className="text-error">
                      Le format du fichier n'est pas pris en charge
                    </small>
                  )}
                  {errorNoAttachment && (
                    <small className="text-error">
                      Veuillez ajouter votre document en pièce jointe
                    </small>
                  )}
                </div>
              </div>
            )}
            <div className="text-center">
              <LoadingButton
                type="submit"
                classes="btn btn-primary rounded-pill fw-bold"
                text="Envoyer"
                loading={loading}
                spinnerHeight="20"
                disabled={buttonIsDisabled}
              />
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        show={showModalSuccess}
        handleClose={() => {
          setShowModalSuccess(false);
        }}
      >
        <div className="">
          <h2 className="fw-bold text-primary">
            Votre message a bien été envoyé !
          </h2>
          <h6 className="text-primary">
            Nous allons prendre en compte votre message et nous vous
            recontacterons dans les plus brefs délais.
          </h6>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary rounded-pill fw-bold"
              onClick={() => setShowModalSuccess(false)}
            >
              Fermer
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
