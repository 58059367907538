import React from 'react';

import Switch from 'react-switch';

export default function SwitchCheckbox({ check, setCheck, className }) {
  return (
    <Switch
      className={`${className}`}
      onChange={() => setCheck(!check)}
      checked={check}
      width={72}
      height={30}
      activeBoxShadow={'0 0 2px 3px #162056'}
      uncheckedIcon={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            color: 'white',
            fontSize: 14,
            lineHeight: 16,
            fontWeight: 'normal',
            paddingRight: 2,
          }}
        >
          OFF
        </div>
      }
      checkedIcon={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: 14,
            lineHeight: 16,
            color: 'white',
            fontWeight: 'normal',
            paddingRight: 2,
          }}
        >
          ON
        </div>
      }
      offColor={'#D6D6D6'}
      onColor={'#162056'}
    />
  );
}
