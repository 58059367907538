import React, { useState } from 'react';

import LoadingButton from '@components/LoadingButton/LoadingButton';

import { useFaqPagesPUT, useEmailSupportPost } from '@hooks/useApi';

import './Useful.scss';

export default function Useful({ index, id, vote }) {
  const [radioValue, setRadioValue] = useState(null);
  const [textareaValue, setTextareaValue] = useState('');
  const [messageSent, setMessageSent] = useState(false);

  const { mutate: mutatePutFAQPages } = useFaqPagesPUT();

  const {
    mutate: mutatePostEmailSupport,
    isLoading: isLoadingPostEmailSupport,
  } = useEmailSupportPost({
    onSuccess: (data) => {
      setMessageSent(true);
    },
  });

  const onChangeRadio = (e) => {
    if (radioValue === null) {
      setRadioValue(e.target.value);

      mutatePutFAQPages({
        id: id,
        data: {
          vote: e.target.value === 'oui' ? 1 : -1,
        },
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let data = {
      motif: 'Retour question',
      sous_motif: 'Id -> ' + id,
      content: textareaValue,
    };

    mutatePostEmailSupport({
      data: data,
    });
  };

  return (
    <div className="useful">
      <h5 className="mb-0">Cette réponse a t-elle été utile ?</h5>
      <div className="buttons-choices">
        <label className="">
          <input
            type="radio"
            checked={radioValue === 'oui'}
            name={`test${index}`}
            id="Oui"
            autoComplete="off"
            value="oui"
            required
            onChange={(e) => onChangeRadio(e)}
          />
          <span className="btn btn-white-border rounded-pill">Oui</span>
        </label>
        <label className="">
          <input
            type="radio"
            checked={radioValue === 'non'}
            name={`test${index}`}
            id="Non"
            autoComplete="off"
            value="non"
            required
            onChange={(e) => onChangeRadio(e)}
          />
          <span className="btn btn-white-border rounded-pill">Non</span>
        </label>
      </div>

      {radioValue === 'non' && messageSent ? (
        <h5 className="fw-bold text-primary">
          Votre message a bien été envoyé !
        </h5>
      ) : radioValue === 'non' && !messageSent ? (
        <form onSubmit={(e) => onSubmit(e)}>
          <h5>
            Dites-nous en quelques mots ce que vous attendiez comme exemple de
            réponse
          </h5>
          <textarea
            className="form-control h3 text-primary"
            id="formControlTextarea1"
            value={textareaValue}
            rows="4"
            required
            onChange={(e) => setTextareaValue(e.target.value)}
          ></textarea>
          <LoadingButton
            type="submit"
            classes="btn btn-primary rounded-pill fw-bold"
            text="Envoyer"
            loading={isLoadingPostEmailSupport}
            spinnerHeight="20"
          />
        </form>
      ) : (
        ''
      )}
    </div>
  );
}
