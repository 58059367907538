import React from 'react';

import LogoEdenred from '@img/Logo_Edenred.svg';
import { ReactComponent as ImgMaintenance } from '@img/technician-workers-engineers-team-technicians.svg';

import './Maintenance.scss';

export default function Maintenance() {
  return (
    <div className="maintenance-page text-primary">
      <img src={LogoEdenred} className="edenred-logo" alt="logo edenred" />
      <div className="content">
        <h1>Le site est actuellement en maintenance</h1>
        <span>
          Veuillez nous excuser, nous mettons tout en œuvre pour rétablir
          l’accès au plus vite.
        </span>
        <ImgMaintenance
          className="img-maintenance"
          alt="image de maintenance"
        />
      </div>
    </div>
  );
}
