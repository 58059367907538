import React, { useState, useEffect } from 'react';

import axios from 'axios';

import CardCenter from '@components/Card/CardCenter';
import LoadingButton from '@components/LoadingButton/LoadingButton';

import GoogleTagManagement from '@GoogleTagManagement';

import ForbiddenMailImage from '@img/forbidden_mail.svg';

import './AccountValidated.scss';

export default function AccountValidated({ email, setEmail, setStep }) {
  const [loading, setLoading] = useState(false);

  const sendMail = () => {
    setLoading(true);

    axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/edenred/identity/${email}/reset-password-mail`
      )
      .then((res) => {})
      .catch((error) => {})
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    sendMail();
  }, []);

  return (
    <CardCenter row_class="min-height-full-with-navbar" id="account-validated">
      <GoogleTagManagement titlePage="Difficultés à me connecter : Mot de passe oublié" />
      <div className="card-body text-center">
        <h2 className="card-title fw-bold text-center">
          Mot de passe oublié ?
        </h2>
        <img
          src={ForbiddenMailImage}
          className="img-fluid"
          alt="mot de passe oublie"
        ></img>
        <p className="card-text mt-3">
          Nous vous avons envoyé un e-mail de réinitialisation à l'adresse
          e-mail que vous avez saisi :
        </p>
        <p className="card-text fw-bold">{email}</p>
        <p className="card-text">
          Si vous n'avez rien reçu, pensez à regarder dans vos courriers
          indésirables
        </p>
        <div className="d-flex flex-md-row mt-3 mb-md-3 flex-column gap-3">
          <LoadingButton
            text="Modifier l'email"
            type="button"
            id="submitMail"
            classes="btn btn-block rounded-pill ms-lg-1 fw-bold text-primary"
            spinnerHeight={18}
            onClick={() => {
              setEmail('');
              setStep(0);
            }}
          />

          <LoadingButton
            text="Renvoyer l'e-mail "
            type="button"
            id="submitMail"
            classes="btn btn-block rounded-pill me-md-1  btn-white-border r mt-3 mt-md-0 fw-bold py-2"
            spinnerHeight={18}
            onClick={sendMail}
            loading={loading}
          />
        </div>
      </div>
    </CardCenter>
  );
}
