import React from 'react';

import { Link } from 'react-router-dom';

import Corrige from '@img/icons/corrige.svg';
import EnCours from '@img/icons/en-cours.svg';
import Inconnu from '@img/icons/inconnu.svg';
import Rejete from '@img/icons/rejete.svg';

import './requests.scss';

const Header = ({ data }) => {
  let image = Inconnu;

  if (data === 'En cours') image = EnCours;
  if (data === 'Rejeté') image = Rejete;
  if (data === 'Corrigé') image = Corrige;

  return data && image ? (
    <div className="header d-flex">
      <img src={image} alt="" />
    </div>
  ) : (
    ''
  );
};

export default function Reviews({ data, nbRequests }) {
  const subStringItemTitle = (data) => {
    let title = data;
    let titleSubstring = title.substring(0, 80);
    if (title.length > 80) titleSubstring += '...';

    return titleSubstring;
  };

  return (
    <div className="col-12 reviews-widget">
      <h5 className="fw-bold text-primary rect_info_title">Mes demandes</h5>
      <div className="content">
        {data.map((item, index) => {
          return (
            <div className="review" key={index}>
              <Header data={item.status} />
              <div className="body">
                {item.new ? <span className="new">Nouveau</span> : ''}
                <div className="title text-primary">
                  {subStringItemTitle(item?.name)}
                </div>
              </div>
            </div>
          );
        })}

        {nbRequests ? (
          <div className="nb-requests">
            + {nbRequests}{' '}
            {nbRequests > 1 ? 'autres demandes' : 'autre demande'}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="button">
        <Link
          to={{ pathname: `/requests` }}
          className="btn btn-primary rounded-pill btn-block"
        >
          Toutes mes demandes
        </Link>
      </div>
    </div>
  );
}
