import React from 'react';

import { useAuth } from '@authProvider';
import { Route } from 'react-router-dom';

import ConnectionProblem from '@pages/connectionProblem/connectionProblem';
import ConfirmEmail from '@pages/createAccount/confirm-email';
import CreateAccount from '@pages/createAccount/createAccount';
import DifficultyToConnect from '@pages/difficultyToConnect/difficultyToConnect';
import Login from '@pages/login/login';
import ResetPassword from '@pages/resetPassword/resetPassword';
import Welcome from '@pages/welcome/welcome';

import AppLayout from '../AppLayout/AppLayout';
import AppRedirect from '../AppRedirect/AppRedirect';
import FooterConnexion from '../Footer/FooterConnexion';
import NavbarCreateAccount from '../Navbar/NavbarCreateAccount';
import NavbarDifficultyToConnect from '../Navbar/NavbarDifficultyToConnect';

export default function ProtectedLoginRoute({ component, ...routeProps }) {
  const [logged] = useAuth();
  const session = sessionStorage.getItem('pathname-before-redirection');
  const hasPathnameBeforeRedirect = logged && session !== null;
  const pathname = hasPathnameBeforeRedirect && JSON.parse(session).pathname;
  const timeStamp = hasPathnameBeforeRedirect && JSON.parse(session).time;
  const timeStampIsBeforeOneHour =
    parseInt((Date.now() - timeStamp) / 1000 / 60) < 60;

  hasPathnameBeforeRedirect &&
    sessionStorage.removeItem('pathname-before-redirection');

  return (
    <Route
      {...routeProps}
      render={(props) => {
        return !logged ? (
          <AppLayout {...props} {...routeProps}>
            {React.createElement(component, props)}
          </AppLayout>
        ) : hasPathnameBeforeRedirect && timeStampIsBeforeOneHour ? (
          <AppRedirect path="/" exact redirectPath={pathname} name="redirect" />
        ) : (
          <AppRedirect
            path="/"
            exact
            redirectPath="/dashboard"
            name="redirect"
          />
        );
      }}
    />
  );
}

export function ProtectedLoginRouteTab() {
  return [
    {
      name: 'welcome',
      path: '/',
      component: Welcome,
      exact: true,
      footer: FooterConnexion,
      title: 'Identifiant',
      routeType: 'ProtectedLoginRoute',
    },
    {
      name: 'login',
      path: '/login',
      component: Login,
      exact: true,
      footer: FooterConnexion,
      routeType: 'ProtectedLoginRoute',
    },
    {
      name: 'create-account',
      path: '/create-account',
      component: CreateAccount,
      exact: true,
      footer: FooterConnexion,
      navBar: NavbarCreateAccount,
      routeType: 'ProtectedLoginRoute',
    },
    {
      name: 'confirm-email',
      path: '/confirm-email',
      component: ConfirmEmail,
      exact: true,
      footer: FooterConnexion,
      navBar: NavbarCreateAccount,
      routeType: 'ProtectedLoginRoute',
    },
    {
      name: 'difficulty-to-connect',
      path: '/difficulty-to-connect',
      component: DifficultyToConnect,
      exact: true,
      footer: FooterConnexion,
      navBar: NavbarDifficultyToConnect,
      routeType: 'ProtectedLoginRoute',
    },
    {
      name: 'connection-problem',
      path: '/connection-problem',
      component: ConnectionProblem,
      exact: true,
      footer: FooterConnexion,
      navBar: NavbarDifficultyToConnect,
      routeType: 'ProtectedLoginRoute',
    },
    {
      name: 'forbidden-password',
      path: '/reset-password',
      component: ResetPassword,
      exact: true,
      footer: FooterConnexion,
      navBar: NavbarDifficultyToConnect,
      routeType: 'ProtectedLoginRoute',
    },
  ];
}
