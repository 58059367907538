import React from 'react';

import { useAuth } from '@authProvider';
import { Route } from 'react-router-dom';

import { setRedirectionPathname } from '@helpers';

import Logout from '@pages/logout/logout';
import Resiliation from '@pages/resiliation/resiliation';

import AppLayout from '../AppLayout/AppLayout';
import FooterConnexion from '../Footer/FooterConnexion';
import FooterDefault from '../Footer/FooterDefault';
import NavbarDefault from '../Navbar/NavbarDefault';
import NavbarDifficultyToConnect from '../Navbar/NavbarDifficultyToConnect';

export default function AppRoute({
  component,
  hasCreateAppointmentProvider,
  ...routeProps
}) {
  const [logged] = useAuth();

  !logged && setRedirectionPathname();
  return (
    <Route
      {...routeProps}
      render={(props) => {
        return (
          <AppLayout {...props} {...routeProps}>
            {React.createElement(component, props)}
          </AppLayout>
        );
      }}
    />
  );
}

export function AppRouteTab(logged) {
  return [
    {
      name: 'logout',
      path: '/logout',
      exact: true,
      component: Logout,
      routeType: 'AppRoute',
    },
    {
      name: 'resiliation',
      path: '/resiliation',
      exact: true,
      component: Resiliation,
      footer: logged ? FooterDefault : FooterConnexion,
      navBar: logged ? NavbarDefault : NavbarDifficultyToConnect,
      routeType: 'AppRoute',
      toggleFeature: 'RESILIATION',
    },
  ];
}
