import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import Holidays from 'date-holidays';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import Skeleton from 'react-loading-skeleton';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { Checkbox } from '@atoms';

import { useSkynetCalendarBusySlot } from '@hooks/useApi';

import { convertTimeToMs, getRandomInt } from '@helpers.js';

import { ReactComponent as Alert } from '@img/icons/alert-icon-not-full.svg';
import { ReactComponent as Arrow } from '@img/icons/arrow.svg';

import LoadingButton from '../LoadingButton/LoadingButton';

import './Booking.scss';

dayjs.extend(require('dayjs/plugin/isToday'));
dayjs.extend(require('dayjs/plugin/isBetween'));

export default function Booking({
  onClickNext,
  date,
  setDate,
  format,
  motif,
  buttonName = 'Suivant',
  loading,
  loadingButton = false,
  isDeadline = false,
  infoResponsable,
}) {
  const hd = new Holidays('FR');

  const skeleton = [2, 5, 6, 1, 4];

  const [slider, setSlider] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(
    date ? dayjs(date).format('MM/01/YYYY') : dayjs().format('MM/01/YYYY')
  );
  const [nbDaysInSelectedMonth, setNbDaysInSelectedMonth] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [listDays, setListDays] = useState(null);
  const [creneaux, setCreneaux] = useState(null);
  const [conditionChecked, setConditionChecked] = useState(false);

  const sliderGoTo = (index) => {
    setTimeout(() => {
      if (slider && slider2) {
        slider?.slickGoTo(index);
        slider2?.slickGoTo(index);
      }
    }, 500);
  };

  const getAvailableDates = (startDate, interval, availabilityView) => {
    let availableDates = [];
    const availabilityViewTab = [...availabilityView];
    const hd = new Holidays('FR');
    const isBefore18H = (calc) =>
      !dayjs(dayjs(calc).add(interval, 'minutes')).isAfter(
        dayjs(calc).set('hour', 18).set('minute', 0),
        'minute'
      );
    const isAfter9H = (calc) => parseInt(calc.format('H')) >= 9;
    const isNotHoliday = (calc) => !hd.isHoliday(calc.format('YYYY-MM-DD'));
    const isNotWeekend = (calc) =>
      calc.format('d') !== '6' &&
      calc.format('d') !== '0' &&
      calc.format('d') !== '1';
    const isMondayAfter14H = (calc) =>
      calc.format('d') === '1' && calc.format('HH') >= 14;

    const isNotBetween12HAnd13H = (calc) =>
      !dayjs(calc).isBetween(
        dayjs(calc).set('hour', 11).set('minute', 59),
        dayjs(calc).set('hour', 13).set('minute', 0),
        'minute'
      );
    const isMeetingNeverStopBetween12HAnd13H = (calc) =>
      !dayjs(dayjs(calc).add(interval, 'minutes')).isBetween(
        dayjs(calc).set('hour', 12).set('minute', 0),
        dayjs(calc).set('hour', 13).set('minute', 1),
        'minute'
      );
    const isNotInTheNext24H = (calc) =>
      !dayjs(calc).isBetween(dayjs(), dayjs().add(24, 'hour'), 'minute');

    const isNotInTheNext24HAndAfterTheWeekend = (calc) => {
      if (dayjs().format('d') === '5') {
        return !dayjs(calc).isBetween(
          dayjs(),
          dayjs().add(72, 'hour'),
          'minute'
        );
      } else {
        return isNotInTheNext24H(calc);
      }
    };

    availabilityViewTab.forEach((item, index) => {
      if (parseInt(item) === 0) {
        const calc = dayjs(startDate).add(interval * index, 'minutes');
        if (
          isBefore18H(calc) &&
          isAfter9H(calc) &&
          isNotHoliday(calc) &&
          isNotInTheNext24HAndAfterTheWeekend(calc) &&
          isNotBetween12HAnd13H(calc) &&
          isMeetingNeverStopBetween12HAnd13H(calc)
        ) {
          if (isNotWeekend(calc)) {
            availableDates.push(calc.format());
          } else if (isMondayAfter14H(calc)) {
            availableDates.push(calc.format());
          }
        }
      }
    });
    return availableDates;
  };

  const switchToNextMonthIfNoAvailableDates = (availableDates) => {
    let findIndexMonth = months.findIndex(
      (month) => month.monthId === selectedMonth
    );

    //Tests s'il n'y a pas de creneaux disponibles et switch sur la prochain mois
    if (availableDates.length <= 0 && findIndexMonth !== months.length - 1) {
      if (findIndexMonth !== -1) {
        onClickMonth(
          months[
            months.findIndex((month) => month.monthId === selectedMonth) + 1
          ].monthId
        );
      }
    }
  };

  const getCreneaux = (startDate, interval, availabilityView) => {
    const availableDates = getAvailableDates(
      startDate,
      interval,
      availabilityView
    );
    switchToNextMonthIfNoAvailableDates(availableDates);

    const creneaux = [];
    listDays.forEach((item) => {
      const result = availableDates.filter(
        (date) =>
          dayjs(date).format('MM/DD/YY') === dayjs(item.date).format('MM/DD/YY')
      );
      creneaux.push(result);
    });

    setCreneaux(creneaux);

    setTimerForHourSelection(availableDates);
  };

  const setTimerForHourSelection = (availableDates) => {
    setTimeout(() => {
      if (document.querySelectorAll('.hour').length) {
        if (
          date &&
          dayjs(date).isSame(selectedMonth, 'month') &&
          document.querySelectorAll('.hour[data-date="' + date + '"').length
        ) {
          document
            .querySelector('.hour[data-date="' + date + '"')
            .classList.add('selected');
          setIsSelected(true);
        } else {
          if (
            document.querySelector(
              '.hour[data-date="' + availableDates[0] + '"'
            )
          )
            document
              .querySelector('.hour[data-date="' + availableDates[0] + '"')
              .classList.add('selected');

          if (
            document.querySelector('.hour.selected') &&
            document
              .querySelector('.hour.selected')
              .closest('.slick-slide')
              .closest('.slick-slide')
          )
            sliderGoTo(
              document
                .querySelector('.hour.selected')
                .closest('.slick-slide')
                .getAttribute('data-index')
            );

          setDate(availableDates[0]);
          setIsSelected(true);
        }
      }
    }, 100);
  };

  useEffect(() => {
    setMonths([
      {
        monthId: dayjs().format('MM/01/YYYY'),
        name: dayjs().locale('fr').format('MMMM YYYY'),
      },
      {
        monthId: dayjs().add(1, 'month').format('MM/01/YYYY'),
        name: dayjs().locale('fr').add(1, 'month').format('MMMM YYYY'),
      },
      {
        monthId: dayjs().add(2, 'month').format('MM/01/YYYY'),
        name: dayjs().locale('fr').add(2, 'month').format('MMMM YYYY'),
      },
    ]);
    setNbDaysInSelectedMonth(dayjs(selectedMonth).daysInMonth());
  }, []);

  const isCurrentMonth = (month) => {
    return dayjs().isSame(month, 'month');
  };

  const createSliderWeek = () => {
    let weeks = [];
    let firstDay = dayjs().format('DD');
    let weeksTab = [];
    // let sameMonthListDays = listDays !== null && listDays.length && !dayjs(listDays[0].date).isSame(selectedMonth, 'month')

    if (!dayjs().isSame(selectedMonth, 'month')) firstDay = 1;

    for (let i = firstDay; i <= nbDaysInSelectedMonth; i++) {
      let day = dayjs(selectedMonth).date(i);
      let dayFormatD = day.format('d');
      if (day.format('d'))
        if (
          i === firstDay &&
          dayFormatD !== 1 &&
          dayFormatD !== '6' &&
          dayFormatD !== '0'
        ) {
          for (let j = dayFormatD - 1; j > 0; j--) {
            const dayBefore = day.subtract(j, 'day');
            weeksTab.push({
              date: dayBefore.format(),
              isHoliday: true,
            });
            weeks.push(divWeek(dayBefore, true));
          }
        }
      if (dayFormatD !== '6' && dayFormatD !== '0') {
        // pas le weekend
        weeksTab.push({
          date: day.format(),
          isHoliday: false,
        });
        weeks.push(divWeek(day));
      }
      if (
        parseInt(i) === nbDaysInSelectedMonth &&
        dayFormatD !== 5 &&
        dayFormatD !== '6' &&
        dayFormatD !== '0'
      ) {
        for (let k = 1; k < 5 - dayFormatD + 1; k++) {
          const dayAfter = day.add(k, 'day');
          weeksTab.push({
            date: dayAfter.format(),
            isHoliday: true,
          });
          weeks.push(divWeek(dayAfter, true));
        }
      }
    }
    listDays === null && setListDays(weeksTab);
    return weeks;
  };

  const divWeek = (day, disabled = false) => {
    return (
      <div key={day}>
        <div
          className={`date ${disabled ? 'disabled' : ''}`}
          data-day={day.format('YYYY-MM-DD')}
        >
          {day.locale('fr').format('dddd')}
          <span>{day.locale('fr').format('DD MMM')}</span>
        </div>
      </div>
    );
  };

  const onClickMonth = (monthID) => {
    setSelectedMonth(monthID);
    setCreneaux(null);
    setListDays(null);

    createSliderWeek();
    setNbDaysInSelectedMonth(dayjs(monthID).daysInMonth());
  };

  const {
    data: dataCalendar,
    isLoading: isLoadingCalendar,
    isFetching: isFetchingCalendar,
    isSuccess,
  } = useSkynetCalendarBusySlot(format, motif, selectedMonth, isCurrentMonth, {
    enabled:
      format !== null &&
      motif !== null &&
      motif !== undefined &&
      selectedMonth !== null &&
      String(format !== 0 ? 30 : parseInt(motif?.duration)) !== 'NaN',
    cacheTime: convertTimeToMs(1, 'minute'),
    staleTime: convertTimeToMs(1, 'minute'),
    refetchInterval: convertTimeToMs(1, 'minute'),
  });

  useEffect(() => {
    if (
      isSuccess &&
      dataCalendar !== null &&
      dataCalendar !== undefined &&
      listDays !== null
    ) {
      setTimeout(() => {
        getCreneaux(
          dataCalendar.startDate,
          format !== 0 ? 30 : parseInt(motif.duration),
          dataCalendar.availabilityView
        );
      }, 100);
    }
  }, [dataCalendar, isSuccess, listDays, isFetchingCalendar]);

  useEffect(() => {
    if (slider && dataCalendar !== null && dataCalendar !== undefined) {
      setTimeout(() => {
        sliderGoTo(
          document
            .querySelector('.date:not(.disabled):first-child')
            .closest('.slick-slide')
            .getAttribute('data-index')
        );
      }, 100);
    }

    if (listDays !== null && !isLoadingCalendar && motif !== null) {
      const randomTab = [];
      listDays.forEach((days) => {
        randomTab.push(getRandomInt(2, 6));
      });
    }
  }, [listDays, dataCalendar, isLoadingCalendar, motif]);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <Arrow
        className={`${className} ${isLoadingCalendar ? 'slick-disabled' : ''}`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <Arrow
        className={`${className} ${isLoadingCalendar ? 'slick-disabled' : ''}`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  };

  const onClickHour = (e, date) => {
    setIsSelected(true);
    setDate(date);
  };

  const settings = {
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={`booking ${isLoadingCalendar ? 'disabled' : ''}`}>
      <div className="selector-month">
        {months.map((month, index) => {
          return (
            <div
              className={`month ${
                selectedMonth === month.monthId ? 'selected' : ''
              } ${isLoadingCalendar ? 'disabled' : ''}`}
              onClick={() => onClickMonth(month.monthId)}
              key={index}
            >
              {month.name}
            </div>
          );
        })}
      </div>
      <div className="slider-container">
        <div className="slider-header">
          <Slider
            asNavFor={slider2}
            slidesToShow={5}
            slidesToScroll={5}
            ref={(slider) => setSlider(slider)}
            arrows={true}
            className="nav1"
            infinite={false}
            nextArrow={<SampleNextArrow />}
            prevArrow={<SamplePrevArrow />}
            {...settings}
          >
            {nbDaysInSelectedMonth !== null && createSliderWeek()}
          </Slider>
        </div>
        <Slider
          asNavFor={slider}
          ref={(slider) => setSlider2(slider)}
          slidesToShow={5}
          swipe={false}
          arrows={false}
          infinite={false}
          className="nav2"
          {...settings}
        >
          {(loading || isLoadingCalendar || isFetchingCalendar) &&
          listDays !== null &&
          creneaux === null
            ? listDays.map((day, index) => {
                return day.isHoliday ? (
                  <div key={index}></div>
                ) : (
                  <div key={index} className="div-skeleton">
                    {[...Array(skeleton[index])].map((e, i) => (
                      <Skeleton className="hour skeleton" key={i} />
                    ))}
                  </div>
                );
              })
            : creneaux !== null &&
              creneaux.map((creneau, index) => {
                return (
                  <div data-cy="creneaux" key={index}>
                    {creneau.length
                      ? creneau.map((dateCreneau, index) => {
                          return (
                            <div
                              className={classNames(
                                'hour',
                                dateCreneau === date && 'selected'
                              )}
                              data-cy="hour"
                              data-date={dateCreneau}
                              onClick={(e) => onClickHour(e, dateCreneau)}
                              key={index}
                            >
                              {dayjs(dateCreneau).format('H:mm')}
                            </div>
                          );
                        })
                      : ''}
                  </div>
                );
              })}
        </Slider>
      </div>
      {isDeadline && (
        <React.Fragment>
          <div className="deadline-warning">
            <div className="side-rect">
              <Alert />
            </div>
            <div className="content">
              <div className="body-text">
                Compte tenu que l’heure de votre rendez-vous est très proche,
                nous vous invitons aussi à contacter votre interlocuteur par
                téléphone afin de le prévenir du report du rendez-vous.
              </div>
              <div className="tel-number">
                Voici son numéro :{' '}
                <span>{infoResponsable && infoResponsable.telephone}</span>
              </div>
            </div>
          </div>
          <Checkbox
            label="Je confirme avoir prévenu mon interlocuteur de l’annulation du rendez-vous."
            name="condition"
            id="condition"
            data-cy="deadline-warning-input-check"
            checked={conditionChecked}
            onChange={() => setConditionChecked(!conditionChecked)}
          />
        </React.Fragment>
      )}
      <div className="text-center button">
        <LoadingButton
          type="submit"
          classes={`btn btn-primary rounded-pill fw-600 ${
            (isDeadline && !conditionChecked) || !isSelected ? 'disabled' : ''
          }`}
          text={buttonName}
          onClick={onClickNext}
          loading={loadingButton}
          spinnerHeight="20"
          disabled={
            (isDeadline && !conditionChecked) ||
            !isSelected ||
            isLoadingCalendar
              ? 'disabled'
              : ''
          }
          dataCy="submit-button-next"
        />
      </div>
    </div>
  );
}
