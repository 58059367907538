import React from 'react';

import './AtelierCadrage.scss';

export default function AtelierCadrage() {
  return (
    <div className="project-content col-12 bg-white atelier-cadrage">
      <h5 className="rect_info_title">Atelier de cadrage</h5>

      <div>
        Etape clé de la mise à disposition de votre solution Edenred, l'atelier
        de cadrage a pour objectifs de : <br />
        <br />
        <ul>
          <li>
            Faire part de vos besoins spécifiques à votre interlocuteur Edenred
          </li>
          <li>
            Vérifier que nous disposons des différents éléments nécessaires pour
            le déploiement (choix du design, nom de domaine, règles
            d'attribution pour les subventions et / ou chèques cadeaux …)
          </li>
          <li>
            Définir le planning jusqu'à l'ouverture du site aux bénéficiaires
          </li>
        </ul>
        Votre interlocuteur Edenred aura alors tous les éléments pour déployer
        votre solution. <br />
        La date de l'atelier de cadrage sera fixée avec votre interlocuteur
        Edenred.
      </div>
    </div>
  );
}
