import React, { useState } from 'react';

import Markdown from 'markdown-to-jsx';

import Useful from '@components/Useful/Useful';

import { useFaqPagesPUT } from '@hooks/useApi';

import './Question.scss';

export default function Question({ index, id, title, content, open, vote }) {
  const [voted, setVoted] = useState(false);

  const imgQuestion = ({ children, ...props }) => {
    let propsData = { ...props };
    propsData.src = `${process.env.REACT_APP_ASSETS}${propsData.src}`;
    return (
      <img {...propsData} alt="question">
        {children}
      </img>
    );
  };

  const iframeQuestion = ({ children, ...props }) => {
    return (
      <span className="ratio">
        <iframe {...props} title="iframe question">
          {children}
        </iframe>
      </span>
    );
  };

  const { mutate: mutatePutFAQPages } = useFaqPagesPUT({
    onSuccess: (data) => {
      setVoted(true);
    },
  });

  const onClickQuestion = (e) => {
    if (
      !voted &&
      e.currentTarget.getAttribute('aria-expanded') !== 'false' &&
      !open
    ) {
      mutatePutFAQPages({
        id: id,
        data: {
          numberView: 1,
        },
      });
    }
  };

  return (
    <div className="card w-100 questionFAQ">
      <div className="card-header" id={`heading${index}`}>
        <h5 className="mb-0">
          <button
            className={`btn btn-link text-primary ${open ? '' : 'collapsed'}`}
            data-bs-toggle="collapse"
            data-bs-target={`#collapse${index}`}
            aria-expanded="false"
            aria-controls={`collapse${index}`}
            onClick={onClickQuestion}
          >
            {title}
            <i className="fa fa-angle-down"></i>
          </button>
        </h5>
      </div>

      <div
        id={`collapse${index}`}
        className={`collapse ${open ? 'show' : ''}`}
        aria-labelledby={`heading${index}`}
        data-bs-parent="#accordion"
      >
        <div className="card-body">
          <div className="content">
            {content === null ? (
              ''
            ) : (
              <Markdown
                options={{
                  overrides: {
                    img: {
                      component: imgQuestion,
                    },
                    iframe: {
                      component: iframeQuestion,
                    },
                  },
                }}
              >
                {content}
              </Markdown>
            )}
          </div>

          <Useful index={index} id={id} vote={vote} />
        </div>
      </div>
    </div>
  );
}
