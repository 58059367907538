import React from 'react';

import PropTypes from 'prop-types';

import { merge } from '../../utils';

import './card.scss';

export const Card = ({
  backgroundColor = '#FFFFFF',
  minHeight = 510,
  width = 540,
  boxShadow = '0px 10px 20px rgb(208 214 222 / 50%)',
  style,
  children,
  ...props
}) => {
  return (
    <div
      className={['card-ec', 'card-center', 'gx-0'].join(' ')}
      style={{
        minHeight,
        boxShadow,
        backgroundColor,
      }}
      {...props}
    >
      <div
        className="card"
        style={merge(
          {
            width: width,
          },
          style
        )}
      >
        {children}
      </div>
    </div>
  );
};

Card.propTypes = {
  backgroundColor: PropTypes.string,
  minHeight: PropTypes.number,
  width: PropTypes.number,
  boxShadow: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
};

Card.defaultProps = {
  backgroundColor: '#FFFFFF',
  minHeight: 510,
  width: 540,
  boxShadow: '0px 10px 20px rgb(208 214 222 / 50%)',
  style: {},
  children: null,
};
