import React from 'react';

import Loader from 'react-loader-spinner';

import './LoadingButton.scss';

export default function LoadingButton({
  type,
  classes,
  id,
  text,
  onClick,
  loading,
  spinnerHeight,
  disabled,
  style,
  dataCy,
}) {
  return (
    <button
      type={type}
      className={`loading-button ${classes}`}
      id={id}
      onClick={onClick && onClick}
      disabled={disabled}
      style={style}
      data-cy={dataCy}
    >
      {loading ? (
        <Loader type="Oval" color="#00BFFF" height={spinnerHeight} />
      ) : (
        text
      )}
    </button>
  );
}
