import React from 'react';

import CardRdv from '@components/Card_rdv/card_rdv';
import Modal from '@components/Modal/ModalDefault';

export default function ModalInfoAppointment({
  showModal,
  setShowModal,
  setShowModalPlace,
  setDataModalPlace,
  data,
  onClickCancel,
}) {
  return (
    <Modal
      show={showModal}
      handleClose={() => {
        setShowModal(false);
      }}
      className="modal_rdv"
    >
      <div className="">
        <h2 className="fw-bold text-primary text-center">
          Informations de votre rendez-vous
        </h2>
        {data && (
          <CardRdv
            data={data}
            onClickCancel={onClickCancel}
            setDataModalPlace={setDataModalPlace}
            setShowModal={setShowModal}
            setShowModalPlace={setShowModalPlace}
          />
        )}
      </div>
    </Modal>
  );
}
