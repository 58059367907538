import React from 'react';

import Skeleton from 'react-loading-skeleton';

import { ReactComponent as Add } from '@img/icons/add.svg';

import './CarouselItem.scss';

export default function CarouselItem({
  image,
  image2,
  title,
  description,
  selected,
  showModalService,
  loading,
}) {
  return loading ? (
    <div className="col-12 skeleton carousel_item">
      <section className="d-flex flex-column align-items-center justify-center w-100 mt-5">
        <Skeleton height={65} width={65} circle={true} className="mb-4" />
        <Skeleton height={20} width="100%" className="mb-1" />
        <Skeleton height={20} width="70%" className="mb-4" />
        <Skeleton height={20} width="100%" count="2" className="mb-1" />
        <Skeleton height={20} width="100%" className="mb-4" />

        <Skeleton
          height={30}
          width="80%"
          className="mb-4 rounded-pill button"
        />
      </section>
    </div>
  ) : (
    <div className="carousel_item" data-cy="equipment-slider-item">
      {image2 ? (
        <div className="images">
          {image} <Add className="add" /> {image2}
        </div>
      ) : (
        image
      )}
      <h6 className="title">{title}</h6>
      <p className="description">{description}</p>
      {selected ? (
        <p className="text-success mb-0 btn float-end rounded-pill">
          Souscrit <i className="fas fa-check"></i>
        </p>
      ) : (
        <button
          type="button"
          className="btn btn-primary float-end rounded-pill"
          onClick={() => showModalService(title)}
        >
          Sélectionner
        </button>
      )}
    </div>
  );
}
