import React from 'react';

import Modal from '@components/Modal/ModalDefault';

import './ModalChequeCadeau.scss';

export default function ModalChequeCadeau({
  show,
  handleClose,
  getDataAndShowModalContactCDC,
}) {
  return (
    <Modal
      className="modal-cheque-cadeau"
      show={show}
      handleClose={handleClose}
    >
      <div className="text-primary">
        <h2 className="fw-bold text-primary text-center">
          Comment créer une nouvelle campagne ?
        </h2>
        <div className="text-content">
          <p>
            Rendez-vous sur l’espace d’administration de votre site CSE dans
            l’onglet “<strong>Chèques cadeaux</strong>” pour créer vos campagnes
            de chèque cadeau en tout autonomie.
          </p>
          <p>
            Vous pourrez notamment choisir l’évènement, le montant, la date de
            lancement et créer votre communication.
          </p>
          <p>
            Si vous rencontrez des difficultés, contactez votre chargé de
            clientèle.
          </p>
          <div className="text-center button">
            <button
              type="button"
              className="btn border border-primary text-primary rounded-pill"
              onClick={() => getDataAndShowModalContactCDC('Chèque cadeau')}
            >
              Contacter mon chargé de clientèle
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
