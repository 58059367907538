import React, { useContext, useEffect } from 'react';

import { login, logout, useAuth } from '@authProvider';
import axios from 'axios';
import { Link, useHistory, useLocation } from 'react-router-dom';

import CardCenter from '@components/Card/CardCenter';

import { LoadingScreenContext } from '@context/LoadingScreenContext';

import { convertSearchToObject, getQueryParameters } from '@helpers';

import Edenred from '@img/Logo_Edenred.svg';

import './welcome.scss';

export default function Welcome() {
  const { toggleLoading } = useContext(LoadingScreenContext);
  const [logged] = useAuth();
  const history = useHistory();

  let location = useLocation();

  useEffect(() => {
    localStorage.clear();
    if (!logged) toggleLoading(false);
    const params = convertSearchToObject(location.search);

    if (
      params.code &&
      params.scope &&
      params.session_state &&
      logged === false
    ) {
      toggleLoading(true);
      let data = getQueryParameters(location.search);
      axios
        .post(`${process.env.REACT_APP_BACK_URL}/api/edenred/connect/token`, {
          code: data.code,
        })
        .then((res) => {
          localStorage.setItem(
            'first-connection',
            JSON.stringify(res.data.firstConnect)
          );
          login(res.data.token);
        })
        .catch((error) => {
          toggleLoading(false);
          if (error?.response?.data?.response) {
            logout();
            const id_token_hint = error?.response?.data?.response?.id_token;
            window.location.href =
              process.env.REACT_APP_LOGOUT_URL +
              '?post_logout_redirect_uri=' +
              process.env.REACT_APP_FRONT_URL +
              '&id_token_hint=' +
              id_token_hint;
          } else {
            setTimeout(() => {
              history.push('/');
            }, 2000);
          }
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="welcome min-height-full w-100 d-flex align-items-center">
      <CardCenter data-cy="Card-welcome">
        <div className="card-body text-center">
          <h2 className="card-title fw-bold">
            Bienvenue sur votre Espace Client
          </h2>
          <img src={Edenred} className="edenred-logo" alt="logo edenred" />
          <p className="card-text">
            Votre espace personnel sécurisé qui centralise vos informations clés
            et qui facilite le contact avec vos interlocuteurs Edenred
          </p>
          <div className="buttons">
            <p className="mb-0">Première visite ?</p>
            <Link
              to={{ pathname: `/create-account` }}
              className="create-account"
            >
              <div
                className="btn btn-block btn-primary rounded-pill"
                data-cy="createAccount"
              >
                Je crée mon compte
              </div>
            </Link>
            <p className="mb-0">Vous avez déja validé votre compte ?</p>
            <Link to={{ pathname: `/login` }} className="login">
              <div className="btn btn-block btn-white-border rounded-pill">
                Je me connecte
              </div>
            </Link>
          </div>
        </div>
      </CardCenter>
    </div>
  );
}
