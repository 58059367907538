import React, { useState } from 'react';

import './DropdownButton.scss';

const DropdownButton = ({ name, items, onClick }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="btn-group dropdown-button">
      <button type="button" className="btn btn-danger" onClick={onClick}>
        {name}
      </button>
      <button
        type="button"
        className="btn btn-danger dropdown-toggle dropdown-toggle-split"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <span className="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul className={`dropdown-menu ${open ? 'show' : ''}`}>
        {items.map(
          (item, index) =>
            item.displayed !== false && (
              <li key={index + item}>
                <a
                  className="dropdown-item"
                  href={item.href}
                  onClick={item.onClick}
                >
                  {item.name}
                </a>
              </li>
            )
        )}
      </ul>
    </div>
  );
};

export default React.forwardRef((props, ref) => (
  <DropdownButton ref={ref} {...props} />
));
