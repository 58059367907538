import React from 'react';

import { Link } from 'react-router-dom';

import './Navbar.scss';
import './NavbarDifficultyToConnect.scss';

export default function NavbarDifficultyToConnect() {
  return (
    <nav className="navbar navbar-difficulty-to-connect navbar-btn-full-mobile navbar-expand-md navbar-light bg-transparent border-bottom d-none d-md-block">
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarsExample04"
        aria-controls="navbarsExample04"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarsExample04">
        <ul className="">
          <Link to={{ pathname: `/` }} className="btn_home">
            <li className="nav-item active">
              <p className="btn text-primary" href="#">
                <i className="fa fa-arrow-left"></i>Retour
              </p>
            </li>
          </Link>
        </ul>
      </div>
    </nav>
  );
}
