import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

library.add(
  faCheckSquare,
  faSearch
  // more icons go here
);

export default function FontAwesome() {
  return <div></div>;
}
