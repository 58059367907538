export const French = {
  sEmptyTable: 'Aucune donnée disponible dans le tableau',
  sInfo: "Affichage de l'élément _START_ à _END_ sur _TOTAL_ éléments",
  sInfoEmpty: "Affichage de l'élément 0 à 0 sur 0 élément",
  sInfoFiltered: '(filtré à partir de _MAX_ éléments au total)',
  sInfoPostFix: '',
  sInfoThousands: ',',
  sLengthMenu: 'Afficher _MENU_ éléments',
  sLoadingRecords: 'Chargement...',
  sProcessing: 'Traitement...',
  sSearch: 'Rechercher :',
  sZeroRecords: 'Aucun élément correspondant trouvé',
  oPaginate: {
    sFirst: 'Premier',
    sLast: 'Dernier',
    sNext: '<i class="fa fa-arrow-right"></i>',
    sPrevious: '<i class="fa fa-arrow-left">',
  },
  oAria: {
    sSortAscending: ': activer pour trier la colonne par ordre croissant',
    sSortDescending: ': activer pour trier la colonne par ordre décroissant',
  },
  select: {
    rows: {
      0: 'Aucune ligne sélectionnée',
      1: '1 ligne sélectionnée',
      _: '%d lignes sélectionnées',
    },
  },
};
