import React from 'react';

import { ReactComponent as Phone } from '@img/icons/Phone.svg';
import { ReactComponent as Mail } from '@img/icons/email.svg';
import { ReactComponent as User } from '@img/icons/user.svg';

import './Lancement.scss';

export default function Lancement({ infoResponsable }) {
  return (
    <div className="project-content col-12 bg-white lancement">
      <h5 className="rect_info_title">Lancement</h5>
      <p>
        Nous sommes ravis que votre site internet soit lancé et nous savons
        aussi que c'est un moment important pour vous. Entre une nouvelle
        solution à adopter et répondre aux nombreuses questions de salariés,
        c'est une mission qui peut s'avérer difficile. Mais soyez rassuré, nous
        sommes là pour vous accompagner.
      </p>
      <p>
        Maintenant que votre site est lancé, découvrez qui contacter si vous
        avez besoin d'un coup de pouce :
      </p>

      <h5 className="rect_info_title">Utilisation de votre solution</h5>

      <h6>Votre Support Client Edenred France</h6>
      <p>
        Le Support Client est à votre disposition via le formulaire sur votre
        site ou par téléphone. Vous bénéficiez d'un Support Technique pour
        toutes vos questions liées à la gestion, la communication, la
        comptabilité, et d'un Support Elu pour toutes vos demandes liées à la
        connexion à votre site, une subvention, une campagne de chèques cadeaux
        ou Meyclub.
      </p>
      <h6>Support Client :</h6>
      <p>
        <span>
          <Phone />
          Par téléphone au 04 76 63 31 65 du lundi au vendredi de 9h à 17h30.
        </span>
        <span>
          <Mail />
          Par votre site via la rubrique "Contacter le support"{' '}
        </span>
      </p>
      <h5 className="rect_info_title">Vie de votre contrat</h5>

      <h6>Votre Chargé de clientèle</h6>
      <p>
        Votre Chargé de clientèle est votre interlocuteur privilégié pour la vie
        de votre contrat. Il vous contactera très prochainement (si ce n'est pas
        déjà fait) pour faire un bilan suite au lancement de votre site et vous
        accompagnera pour faire du lancement une réussite à long terme !
      </p>
      <h6>Votre Chargé de clientèle :</h6>
      <p>
        {infoResponsable && infoResponsable.prenom && (
          <span>
            <User />
            {infoResponsable.prenom + ' ' + infoResponsable.nom}
          </span>
        )}
        {infoResponsable && infoResponsable.mail && (
          <span>
            <Mail />
            {infoResponsable.mail}
          </span>
        )}
        {infoResponsable && infoResponsable.telephone && (
          <span>
            <Phone />
            {infoResponsable.telephone}
          </span>
        )}
      </p>
      <h5 className="rect_info_title">Pour vos bénéficiaires</h5>

      <h6>Service Client Meyclub</h6>
      <p>
        Le Service Client est à la disposition de vos bénéficiaires dans la
        rubrique Aide et Contact ou par téléphone du lundi au vendredi de 9h à
        18h au 08 125 808 000 (0,18 centimes d'euros par minute) pour toutes
        questions liées à une commande en cours ou passée, à l'utilisation d'un
        avantage ou à une demande d'information sur un produit.
      </p>
      <p>
        Note : "Mon projet" restera visible pendant 3 mois. Passé ce délai, il
        disparaîtra de votre menu.
      </p>
    </div>
  );
}
