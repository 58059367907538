import React from 'react';

import Password from '@components/Password/Password';

import ForbiddenFinishImage from '@img/forbidden_finish.svg';

import './MDP.scss';

export default function MDP(props) {
  return (
    <div className="MDP">
      {!props.passwordIsChanged ? (
        <div className="row">
          <div className="m-auto col-md-10 col-lg-8">
            <h4 className="card-title fw-bold">Modification du mot de passe</h4>
            <Password
              setPassword={props.setPassword}
              loadingButton={props.loadingButton}
              setCurrent={props.setCurrent}
              handleSubmit={props.handleSubmit}
              needCurrent={true}
            />
          </div>
        </div>
      ) : (
        <div className="row finish">
          <div className="m-auto">
            <h4 className="fw-bold">Vous avez changé votre mot de passe.</h4>
            <img
              src={ForbiddenFinishImage}
              className="img-fluid"
              alt="mot de passe changé"
            ></img>
            <p className="text">
              Vous venez de réinitialiser votre mot de passe. Vous pouvez dès
              maintenant vous connecter avec votre nouveau mot de passe.
            </p>
            <button
              className="btn btn-primary rounded-pill"
              onClick={() => props.setPasswordIsChanged(false)}
            >
              Retour
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
