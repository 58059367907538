import React from 'react';

import './CardCenter.scss';

export default function CardCenter(props) {
  return (
    <div
      className={`w-100 h-100 ${
        props.col_class !== undefined ? props.col_class : ''
      }`}
      id={props.id ?? ''}
    >
      <div
        className={`row justify-content-center align-items-center h-100 ${
          props.row_class !== undefined ? props.row_class : ''
        }`}
      >
        <div className="card-center gx-0" data-cy={props['data-cy']}>
          <div className="card">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
