import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import Loader from '@components/Loader/Loader';

import { getQueryParameters } from '@helpers';

import './pdfViewer.scss';

export default function PdfViewer() {
  const [pdf, setPdf] = useState('');
  const [loading, setLoading] = useState(true);
  const { pdfId } = useParams();
  let location = useLocation();

  useEffect(() => {
    const request = getQueryParameters(location.search).isDocument
      ? `${process.env.REACT_APP_BACK_URL}/api/skynet/document/${pdfId}`
      : `${process.env.REACT_APP_BACK_URL}/api/skynet/edfa/pdf/${pdfId}`;
    axios
      .get(request, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem('REACT_TOKEN_AUTH_KEY')
          )}`,
        },
      })
      .then((res) => {
        // setData(JSON.parse(res.data.infos))
        setLoading(false);
        getQueryParameters(location.search).isDocument
          ? setPdf(res.data.document)
          : setPdf(JSON.parse(res.data));
      })
      .catch((error) => {
        setLoading(false);
        // setError(true)
      });
  }, [pdfId, location.search]);

  return loading ? (
    <div className="min-height-full w-100 d-flex flex-column justify-content-center align-items-center text-center">
      <h1 className="d-block fw-bold mb-5 text-primary">
        Chargement de votre document...
      </h1>
      <Loader />
    </div>
  ) : (
    <object
      id="pdf"
      data={pdf}
      type="application/pdf"
      aria-label="pdf"
    ></object>
  );
}
