import React from 'react';

import './checkbox.scss';

export const Checkbox = ({ label, name, isDisabled = false, ...props }) => {
  const id = `checkbox-${name?.replace(/\W/g, '-').toLowerCase()}`;

  const options = {
    id: id,
    'data-cy': id,
    name: name,
    ...props,
  };

  return (
    <div
      className={`custom-control custom-checkbox ${
        isDisabled ? 'disabled' : ''
      }`}
    >
      <input
        className="form-check-input custom-control-input"
        type="checkbox"
        disabled={isDisabled}
        {...options}
      />
      <label
        className="form-check-label custom-control-label cursor-pointer text-primary"
        htmlFor={options.id}
      >
        {label}
      </label>
    </div>
  );
};
