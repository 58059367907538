import React, { useEffect } from 'react';

import { useAuth } from '@authProvider';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import AppLayout from '@components/AppLayout/AppLayout';

import { setRedirectionPathname } from '@helpers';

import './notFound.scss';

export default function NotFound(props) {
  const [logged] = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!logged) {
      setRedirectionPathname();
      history.push('/');
    }
  }, [logged]);

  return (
    logged && (
      <AppLayout name="not-found">
        <div className="error-404 col-md-12 h-100 min-h-100{">
          <div className="content">
            <h1 className="text-primary">Erreur 404</h1>
            <p>La page que vous recherchez n'est pas disponible.</p>
            <div className="buttons">
              <Link to={{ pathname: `/` }}>
                <p className="btn btn-primary text-white btn-lg me-2 rounded-pill">
                  Retourner sur le site{' '}
                </p>
              </Link>
            </div>
          </div>
          <div className="image">
            <img
              src="https://click.edenred.fr/edenredv2/styles/400x400/azblob/media/image/2019-05/illustration-page404_0.png?itok=jVB18fCI"
              alt=""
              width="400"
              height="400"
            />
          </div>
        </div>
      </AppLayout>
    )
  );
}
