import React, { useState, useEffect, useContext } from 'react';

import $ from 'jquery';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick.min.js';

import { ProjectContext } from '@context/ProjectContext';

import {
  useSkynetProjectClient,
  useSkynetEquipmentClient,
  useSkynetMainResponsable,
} from '@hooks/useApi';

import { ReactComponent as Phone } from '@img/icons/Phone.svg';
import { ReactComponent as User } from '@img/icons/user.svg';

import CarouselItem from './CarouselItem';
import ModalContactCdp from './ModalContactCdp';
import ModalSendRequest from './ModalSendRequest';
import './project.scss';
import Affectation from './steps/Affectation';
import AtelierCadrage from './steps/AtelierCadrage';
import Deploiement from './steps/Deploiement';
import Lancement from './steps/Lancement';
import Preparation from './steps/Preparation';

export default function Project() {
  const [isNddRequestSent, setIsNddRequestSent] = useState(false);
  const [numberSlide, setNumberSlide] = useState(0);
  const [etapes, setEtapes] = useState(null);
  const [completeds, setCompleteds] = useState(null);
  const [isNomDomaine, setIsNomDomaine] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState();
  const [showModalContact, setShowModalContact] = useState(false);
  const [showModalSendRequest, setShowModalSendRequest] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [items, setItems] = useState([
    {
      title: 'Affectation',
      isActive: false,
      passed: false,
    },
    {
      title: 'Préparation',
      isActive: false,
      passed: false,
    },
    {
      title: 'Atelier de cadrage',
      isActive: false,
      passed: false,
    },
    {
      title: 'Déploiement',
      isActive: false,
      passed: false,
    },
    {
      title: 'Lancement',
      isActive: false,
      passed: false,
    },
  ]);
  const { hasProject } = useContext(ProjectContext);

  const currentStep =
    completeds !== null && completeds?.length === items?.length
      ? completeds?.length
      : completeds?.length + 1;

  const { data: dataProjectClient, isLoading: isLoadingProjectClient } =
    useSkynetProjectClient({
      enabled: hasProject,
    });
  const { data: dataMainResponsable, isLoading: isLoadingMainResponsable } =
    useSkynetMainResponsable();
  const { data: dataEquipmentClient, isLoading: isLoadingEquipmentClient } =
    useSkynetEquipmentClient();

  const isLoadingData =
    isLoadingProjectClient ||
    isLoadingMainResponsable ||
    isLoadingEquipmentClient;

  useEffect(() => {
    if (dataProjectClient) {
      setIsNddRequestSent(dataProjectClient[0]?.isNDDRequestSent ?? false);

      let etapesTab = [];
      Object.entries(JSON.parse(dataProjectClient[0].etapes)).map((etape) => {
        return etapesTab.push({ id: etape[0], ...etape[1] });
      });

      let completedsTab = etapesTab.filter((etape) => etape.completed === true);

      items.forEach((item, index) => {
        let copyItems = [...items];
        copyItems[index].isActive =
          etapesTab[index].completed || completedsTab.length === index;
        copyItems[index].passed = etapesTab[index].completed_at !== null;
        setItems(copyItems);
      });

      setCompleteds(completedsTab);
      setEtapes(etapesTab);
    }
  }, [dataProjectClient]);

  useEffect(() => {
    handleResize();

    function handleResize() {
      let containerWidth = $('.project').width();
      $('.js-nav-link').outerWidth(containerWidth / 5 - 6);
      let itemLength = $('.js-nav-link').length;
      let itemWidth = $('.js-nav-link').outerWidth(true);
      let numberSlideCalc = containerWidth / itemWidth;
      setNumberSlide(
        numberSlideCalc > itemLength ? itemLength : numberSlideCalc
      );
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    swipe: true,
    slidesToShow: numberSlide,
    centerMode: false,
  };

  const renderEtape = (param) => {
    switch (param) {
      case 1:
        return <Affectation infoProject={dataProjectClient[0]} />;
      case 2:
        return (
          <Preparation
            infoProject={dataProjectClient[0]}
            equipmentData={dataEquipmentClient}
            setShowModalSendRequest={setShowModalSendRequest}
            setShowModalContact={setShowModalContact}
            setIsNomDomaine={setIsNomDomaine}
            setSelectedDomain={setSelectedDomain}
            isNddRequestSent={isNddRequestSent}
          />
        );
      case 3:
        return <AtelierCadrage />;
      case 4:
        return <Deploiement />;
      case 5:
        return <Lancement infoResponsable={dataMainResponsable} />;
      default:
        return false;
    }
  };

  return (
    <div className="project w-100 text-primary uk-animation-scale-up">
      <div className="row align-items-center">
        <div className="col-md-6 col-xl-4">
          <h3 className="fw-bold">Mon projet</h3>
          <div className="col-12 project-infos bg-white">
            <h5>Votre abonnement souscrit :</h5>
            {isLoadingData ? (
              <Skeleton color="#e6e6e6" width={250} />
            ) : dataEquipmentClient ? (
              <div className="content">
                <p>
                  {dataEquipmentClient.licence && dataEquipmentClient.licence}
                  {dataEquipmentClient.logiciel &&
                    'Logiciel à la carte Com/Gestion'}
                  {dataEquipmentClient.meyclub && 'Meyclub'}
                </p>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="col-md-0 col-xl-4 d-none d-xl-block"></div>
        {etapes !== null && etapes[0].completed && (
          <div className="col-md-6 col-xl-4">
            <div className="col-12 project-infos project-infos-project d-flex">
              <div className="infos">
                <h5 className="fw-bold">Mon chef de projet</h5>
                {dataProjectClient[0] ? (
                  <div className="content">
                    <p
                      className={
                        !dataProjectClient[0].leader.nom ? 'd-none' : ''
                      }
                    >
                      <User />{' '}
                      {dataProjectClient[0].leader.prenom +
                        ' ' +
                        dataProjectClient[0].leader.nom}
                    </p>
                    <p
                      className={`d-none ${
                        dataProjectClient[0].leader.telephone && 'd-md-block'
                      }`}
                    >
                      <Phone /> {dataProjectClient[0].leader.telephone}
                    </p>
                  </div>
                ) : (
                  ''
                )}
                <button
                  type="button"
                  onClick={() => setShowModalContact(true)}
                  className="btn rounded-pill btn-white-border contact-mail"
                >
                  Contacter par e-mail
                </button>
              </div>
              <div className="d-md-none phone-content">
                <a
                  href={`tel:${
                    dataProjectClient &&
                    dataProjectClient[0] &&
                    dataProjectClient[0].leader.telephone
                  }`}
                  className={`phone btn ${
                    dataProjectClient[0]
                      ? dataProjectClient[0].leader.telephone
                        ? ''
                        : 'disabled'
                      : 'disabled'
                  }`}
                >
                  <Phone />
                </a>
              </div>
            </div>
            <ModalSendRequest
              showModal={showModalSendRequest && !isNddRequestSent}
              setShowModal={setShowModalSendRequest}
              selectedDomain={selectedDomain}
              setIsDomainName={setIsNomDomaine}
              setSuccessMessage={setShowModalSuccess}
              projectId={dataProjectClient[0]?.id ?? null}
              setIsNddRequestSent={setIsNddRequestSent}
            />
            <ModalContactCdp
              showModalContact={showModalContact}
              setShowModalContact={setShowModalContact}
              showModalSuccess={showModalSuccess}
              setShowModalSuccess={setShowModalSuccess}
              isNomDomaine={isNomDomaine}
              setIsNomDomaine={setIsNomDomaine}
              selectedDomain={selectedDomain}
            />
          </div>
        )}
      </div>
      <Slider {...settings} className="slider slider-project nav nav-tabs">
        {items.map((item, index) => {
          return (
            <CarouselItem
              title={item.title}
              number={index}
              isActive={item.isActive}
              isPassed={item.passed}
              key={index}
            />
          );
        })}
      </Slider>
      <div className="tab-content rect_info" id="myTabContent">
        {dataProjectClient && dataProjectClient[0] && completeds !== 'null' ? (
          renderEtape(currentStep)
        ) : (
          <div className="col-12 skeleton">
            <SkeletonTheme width="100%">
              <section className="d-flex flex-column align-items-center justify-center w-100">
                <Skeleton height={20} width="30%" className="mb-4" />
                <Skeleton height={20} width="100%" className="mb-2" />
                <Skeleton height={20} width={150} className="mb-4" />
                <Skeleton height={20} width="100%" count={3} className="mb-2" />
                <Skeleton height={20} width="80%" className="mb-4" />
                <Skeleton height={20} width="50%" className="mb-2" />
                <Skeleton height={20} width="60%" className="mb-4" />
                <Skeleton height={20} width="100%" className="mb-2" />
                <Skeleton height={20} width="10%" className="mb-2" />
              </section>
            </SkeletonTheme>
          </div>
        )}
      </div>
    </div>
  );
}
