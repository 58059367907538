import React from 'react';

import Modal from '@components/Modal/ModalDefault';

export default function ModalPlaceAppointment({
  showModal,
  setShowModal,
  setShowModalInfo,
  data,
  onClickCancel,
}) {
  return (
    <Modal
      show={showModal}
      handleClose={() => {
        setShowModal(false);
      }}
      className="modal_rdv"
    >
      <div className="px-md-5">
        <h2 className="fw-bold text-primary text-center">
          Adresse du rendez-vous
        </h2>
        {data && (
          <p
            dangerouslySetInnerHTML={{ __html: data.location }}
            className="mb-4 d-block text-primary"
          />
        )}

        <div className="text-center button">
          <button
            type="button"
            className={`btn btn-primary rounded-pill fw-600`}
            onClick={() => {
              if (typeof setShowModalInfo === 'function')
                setShowModalInfo(false);
              if (typeof setShowModal === 'function') setShowModal(false);
            }}
          >
            Fermer
          </button>
        </div>
      </div>
    </Modal>
  );
}
