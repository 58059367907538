import React from 'react';

import { Link } from 'react-router-dom';

import CardCenter from '@components/Card/CardCenter';

import GoogleTagManagement from '@GoogleTagManagement';

import ConnectionProblemImage from '@img/connection_problem.svg';

import './MailNotExist.scss';

export default function MailNotExist() {
  return (
    <CardCenter row_class="min-height-full-with-navbar" id="mail-not-exist">
      <GoogleTagManagement titlePage="Difficultés à me connecter : Votre mail n'est pas reconnu" />
      <div className="card-body text-center">
        <h2 className="card-title fw-bold text-center">
          Votre mail n'est pas reconnu
        </h2>
        <img
          src={ConnectionProblemImage}
          className="img-fluid"
          alt="probleme de connexion"
        ></img>
        <p className="card-text mt-3">
          L'adresse mail saisie n'est pas reconnue. <br />
          Vérifiez votre adresse ou contactez votre chargé de compte.
        </p>
        <div className="buttons">
          <Link to={{ pathname: `/welcome` }}>
            <button
              type="button"
              className="btn btn-primary btn-block rounded-pill"
            >
              J'ai compris
            </button>
          </Link>
        </div>
      </div>
    </CardCenter>
  );
}
