import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { Checkbox, Radio } from '@atoms';

import { SelectList, TextField } from '@molecules';

import LoadingButton from '@components/LoadingButton/LoadingButton';

import { UserContext } from '@context/UserContext';

import { getSizeDocument } from '@helpers';

// import { ReactComponent as FileDownload } from "@img/icons/file_download.svg";
import FileDownload from '@img/icons/common-file-download--icon.png';
import { ReactComponent as Question } from '@img/icons/question.svg';

import './FirstConnect.scss';

export default function FirstConnect({
  setLoading,
  infoContact,
  infoClient,
  functionContact,
  listFunction,
  setIsInput,
  setModalFirstconnection,
}) {
  const cguDocumentLink = '/documents/Def_CGU_Espace_Client_ERF_2024.pdf';
  const [cguDocumentSize, setCguDocumentSize] = useState('');

  const { changeUserName } = useContext(UserContext);
  const [loadingButton, setLoadingButton] = useState(false);
  const isBilling =
    infoClient && infoClient.adresses
      ? infoClient.adresses.find((address) => address.isBilling)
      : null;
  const isMeeting =
    infoClient && infoClient.adresses
      ? infoClient.adresses.find((address) => address.isMeeting)
      : null;
  const [showPostale, setShowPostale] = useState(false);
  const [showPostaleRDV, setShowPostaleRDV] = useState(false);

  const options =
    listFunction &&
    listFunction['hydra:member'].length &&
    listFunction['hydra:member'].map((functionData) => {
      return { value: functionData['@id'], label: functionData.fonction };
    });
  const [errorTel, setErrorTel] = useState(false);

  const { register, handleSubmit, formState, control, watch, setValue } =
    useForm({
      defaultValues: {
        civilite: infoContact?.civilite,
        nom: infoContact?.nom,
        prenom: infoContact?.prenom,
        mail: infoContact?.mail,
        telephone: infoContact?.telephone,
        telephonePortable: infoContact?.telephonePortable,
        fonction: {
          value: functionContact && functionContact['@id'],
          label: functionContact && functionContact.fonction,
        },
        vip: infoContact?.vip,
        refProjet: infoContact?.refProjet,
        isFactHandler: infoContact?.isFactHandler,
        preferenceAm: infoContact?.preferenceAm,
        preferencePm: infoContact?.preferencePm,
        postale: {
          adresseLine1: isBilling && isBilling.adresseLine1,
          adresseLine2: isBilling && isBilling.adresseLine2,
          codePostal: isBilling && isBilling.codePostal,
          ville: isBilling && isBilling.ville,
        },
        rdv: {
          adresseLine1: isMeeting && isMeeting.adresseLine1,
          adresseLine2: isMeeting && isMeeting.adresseLine2,
          codePostal: isMeeting && isMeeting.codePostal,
          ville: isMeeting && isMeeting.ville,
        },
        societeNbSalaries: infoClient && infoClient.societeNbSalaries,
        dateElection:
          infoClient && dayjs(infoClient.dateElection).format('YYYY-MM-DD'),
        condition: false,
        isIdentical: isBilling && isBilling.isMeeting,
      },
    });

  useEffect(() => {
    //Récupération de la taille du document cgu
    async function asyncGetSizeDocument() {
      setCguDocumentSize(await getSizeDocument(cguDocumentLink));
    }
    asyncGetSizeDocument();
  }, []);

  const isBillingTest = {
    adresseLine1: isBilling.adresseLine1,
    adresseLine2: isBilling.adresseLine2,
    codePostal: isBilling.codePostal,
    ville: isBilling.ville,
    isBilling: true,
    isMeeting: isBilling.isMeeting,
    nom: isBilling.nom,
  };

  const onSubmit = (data) => {
    // Si adresse fact et aussi meeting, afficher quand meme une meet de secours pour si la personne decoche le "identique".
    // Si adresse fact et aussi meeting mais pas de meeting de secours, lors du submit post une nouvelle adresse de rdv.
    // Si juste modification du complement d'adresse pour la fact, update. Si plus, post (création nouvelle)
    // Toujours update pour les meeting

    setErrorTel(false);
    if (
      data.telephone.match(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/) ||
      data.telephonePortable.match(
        /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
      )
    ) {
      const formData = {
        civilite: data.civilite,
        fonction: data.fonction.value,
        isFactHandler: data.isFactHandler,
        nom: data.nom,
        prenom: data.prenom,
        preferenceAm: data.preferenceAm,
        preferencePm: data.preferencePm,
        refProjet: data.refProjet,
        telephone: data.telephone,
        telephonePortable: data.telephonePortable,
        validatedCgu: true,
      };

      const isBillingData = {
        adresseLine1: data.postale.adresseLine1,
        adresseLine2: data.postale.adresseLine2,
        codePostal: data.postale.codePostal,
        ville: data.postale.ville,
        isBilling: true,
        isMeeting: data.isIdentical,
        nom: isBilling.nom,
      };

      const isMeetingData = {
        adresseLine1: data.rdv.adresseLine1,
        adresseLine2: data.rdv.adresseLine2,
        codePostal: data.rdv.codePostal,
        ville: data.rdv.ville,
        isMeeting: !data.isIdentical,
      };

      const salariesElectionData = {
        dateElection: data.dateElection,
        societeNbSalaries: data.societeNbSalaries,
      };

      setLoadingButton(true);

      const requestOne =
        !watch('isIdentical') &&
        isMeeting &&
        axios.post(
          `${process.env.REACT_APP_BACK_URL}/api/skynet/address`,
          JSON.stringify(isMeetingData),
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem('REACT_TOKEN_AUTH_KEY')
              )}`,
            },
          }
        );

      const requestTwo =
        JSON.stringify(isBillingData) !== JSON.stringify(isBillingTest) &&
        axios.put(
          `${process.env.REACT_APP_BACK_URL}/api/skynet/address/${isMeeting.id}`,
          JSON.stringify(isBillingData),
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem('REACT_TOKEN_AUTH_KEY')
              )}`,
            },
          }
        );
      const requestThree = axios.put(
        `${process.env.REACT_APP_BACK_URL}/api/skynet/contact`,
        JSON.stringify(formData),
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem('REACT_TOKEN_AUTH_KEY')
            )}`,
          },
        }
      );

      const requestFour = axios.put(
        `${process.env.REACT_APP_BACK_URL}/api/skynet/client`,
        JSON.stringify(salariesElectionData),
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem('REACT_TOKEN_AUTH_KEY')
            )}`,
          },
        }
      );

      let requests = [requestTwo, requestThree, requestFour];
      if (
        showPostale &&
        JSON.stringify(isBillingData) !== JSON.stringify(isBillingTest)
      ) {
        requests.push(requestOne);
      }

      axios
        .all(requests)
        .then((res) => {
          changeUserName(formData.prenom, formData.nom);
          setModalFirstconnection(false);
          setLoadingButton(false);
          localStorage.setItem('first-connection', false);
        })
        .catch((error) => {
          setLoadingButton(false);
        });
    } else {
      setErrorTel(true);
    }
  };

  return (
    <div className="">
      <form onSubmit={handleSubmit(onSubmit)} className="firstConnect">
        <h4 className="fw-bold text-primary">Vérifiez vos informations</h4>
        <p>
          Pour nous assurer d'avoir un contact avec vous, vérifiez que nous
          disposons des bonnes informations.
        </p>
        <p className="obligation">
          Tous les champs sont obligatoires sauf mention contraire. Renseignez
          au moins 1 numéro de téléphone.
        </p>
        <h5 className="fw-bold text-primary">Informations générales</h5>
        <div className="form-group px-0 col-md-12 d-flex flex-column">
          <label htmlFor="inputCivilite">Civilité</label>
          <div className="form-group d-flex mb-3">
            <div className="me-2">
              <Controller
                name="civilite"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Radio
                    label={'Monsieur'}
                    name={'civilite'}
                    value={'M'}
                    id="inlineRadioCivilite1"
                    data-cy="inlineRadioCivilite1"
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultChecked={value}
                  />
                )}
              />
            </div>
            <div className="ms-2">
              <Controller
                name="civilite"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Radio
                    label={'Madame'}
                    name={'civilite'}
                    value={'Mme'}
                    id="inlineRadioCivilite2"
                    data-cy="inlineRadioCivilite2"
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultChecked={value}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="form-group px-0 col-md-12">
          <label htmlFor="inputTextNom">Nom</label>
          <TextField
            name="nom"
            id="inputTextNom"
            {...register('nom', { required: true })}
          />
        </div>
        <div className="form-group px-0 col-md-12">
          <label htmlFor="inputPrenom">Prénom</label>
          <TextField
            name="prenom"
            id="inputPrenom"
            {...register('prenom', { required: true })}
          />
        </div>
        <h5 className="fw-bold text-primary">Informations de contact</h5>
        <div className="form-inline d-flex flex-wrap flex-column px-0 col-12 p-0 pb-2 informations_contact_fonction_type_contact">
          <div className="form-group px-0 col-12 fonction-select">
            <label htmlFor="inputFonction">Fonction</label>
            <Controller
              control={control}
              name="fonction"
              render={({ field: { onChange, value, ref } }) => (
                <SelectList
                  options={options}
                  placeholder="Selectionner une valeur"
                  onChange={(val) => onChange(val)}
                />
              )}
            />
          </div>
          <div className="form-group px-0 col-md-12 type-contact">
            <label htmlFor="InputTypeContact">
              Type de contact
              <Question
                className="stroke-primary questionIcon not-disabled"
                data-tip
                data-for="questionTypeContact"
              />
              <ReactTooltip
                id="questionTypeContact"
                place="right"
                type="info"
                effect="solid"
                className="reactTooltipInformation"
              >
                <span>
                  Principal : Vous êtes le contact à privilégier pour n’importe
                  quel sujet.
                  <br />
                  Projet : Vous êtes le contact pour la mise en place de votre
                  projet
                  <br />
                  Facturation : Vous êtes le contact pour tout sujet lié aux
                  factures
                </span>
              </ReactTooltip>
            </label>
            <div className="form-inline d-flex flex-wrap flex-column">
              <div className="mb-2">
                <Controller
                  name="vip"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Checkbox
                      label={'Interlocuteur principal'}
                      name={'vip'}
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultChecked={value}
                      isDisabled={true}
                    />
                  )}
                />
              </div>
              <div className="mb-2">
                <Controller
                  name="refProjet"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Checkbox
                      label={'Interlocuteur projet'}
                      name={'refProjet'}
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultChecked={value}
                      required={false}
                    />
                  )}
                />
              </div>
              <div className="mb-2">
                <Controller
                  name="isFactHandler"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Checkbox
                      label={'Interlocuteur facturation'}
                      name={'isFactHandler'}
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultChecked={value}
                      required={false}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`form-group px-0 col-md-12 telephone-principal ${
            errorTel && 'error'
          }`}
        >
          <label htmlFor="inputTelephonePrincipal">Téléphone fixe</label>
          <input
            type="tel"
            name="telephone"
            className="px-0 col-12 form-control mt-2"
            id="inputTelephonePrincipal"
            {...register('telephone', {
              required: watch('telephonePortable') === '',
              pattern: '^(?:(?:+|00)33|0)s*[1-9](?:[s.-]*d{2}){4}$',
            })}
          />
          {errorTel && (
            <span>
              Renseignez <strong>au moins 1</strong> numéro de téléphone et
              respectez le format
            </span>
          )}
        </div>
        <div className={`form-group px-0 col-md-12 ${errorTel && 'error'}`}>
          <label htmlFor="inputTelephoneSecondaire">Téléphone portable</label>
          <input
            type="tel"
            name="telephonePortable"
            className="px-0 col-12 form-control mt-2"
            id="inputTelephoneSecondaire"
            {...register('telephonePortable', {
              required: watch('telephone') === '',
            })}
          />
          {errorTel && (
            <span>
              Renseignez <strong>au moins 1</strong> numéro de téléphone et
              respectez le format
            </span>
          )}
        </div>
        <div className="form-group px-0 col-md-12 preference-contact">
          <label htmlFor="InputTypeContact">
            Préférence de contact (facultatif)
          </label>
          <div className="form-inline">
            <div className="me-2 py-2">
              <Controller
                name="preferenceAm"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Checkbox
                    label={'Matin'}
                    name={'preferenceAm'}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultChecked={value}
                  />
                )}
              />
            </div>
            <div className="ms-2 py-2">
              <Controller
                name="preferencePm"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Checkbox
                    label={'Après-midi'}
                    name={'preferencePm'}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultChecked={value}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="form-group px-0 col-md-12 disabled">
          <label htmlFor="InputEmail" className="not-disabled">
            Adresse e-mail (identifiant)
            <Question
              className="stroke-primary questionIcon not-disabled"
              data-tip
              data-for="questionSubventions"
            />
            <ReactTooltip
              id="questionSubventions"
              place="right"
              type="info"
              effect="solid"
              className="reactTooltipInformation"
            >
              <span>
                L’adresse e-mail étant aussi votre identifiant, il n’est pas
                possible de le modifier. Si la modification est nécessaire,
                contactez le votre chargé de clientèle.
              </span>
            </ReactTooltip>
          </label>
          <input
            type="email"
            defaultValue={formState.defaultValues.mail}
            name="mail"
            className="form-control dontTake"
            id="InputMail"
          />
        </div>
        <h5 className="fw-bold text-primary">Adresse du CSE</h5>

        <div className="adressePostale">
          <p className="title p-0">Adresse postale</p>
          <div className="content">
            <div className="adresse">
              {isBilling && isBilling.adresseLine1}
              <br />
              {isBilling &&
                isBilling.adresseLine2 &&
                `${isBilling && isBilling.adresseLine2}<br/>`}
              {isBilling && isBilling.codePostal} {isBilling && isBilling.ville}
            </div>
            <button
              type="button"
              className="btn border border-primary rounded-pill"
              onClick={() => setShowPostale(!showPostale)}
            >
              Modifier mon adresse
            </button>
          </div>
        </div>
        {showPostale && (
          <>
            <div className="form-group px-0 col-md-12">
              <label htmlFor="InputAdressePostale">Adresse postale</label>
              <TextField
                name="adresseLine1"
                className="addressForm isBilling"
                id="InputAdressePostale"
                {...register('postale.adresseLine1')}
              />
            </div>
            <div className="form-group px-0 col-md-12">
              <label htmlFor="InputAdressePostale2">
                Complément d'adresse (facultatif)
              </label>
              <TextField
                name="adresseLine2"
                className="addressForm isBilling"
                id="InputAdressePostale2"
                {...register('postale.adresseLine2')}
              />
            </div>
            <div className="form-inline px-0 col-12 p-0 pb-2">
              <div className="form-group px-0 col-12">
                <label htmlFor="inputCodePostal">Code postal</label>
                <TextField
                  name="codePostal"
                  className="px-0 col-12 mt-2 addressForm isBilling"
                  id="inputCodePostal"
                  {...register('postale.codePostal')}
                />
              </div>
              <div className="form-group px-0 col-12">
                <label htmlFor="inputVille">Ville</label>
                <TextField
                  name="ville"
                  className="px-0 col-12 mt-2 addressForm isBilling"
                  id="inputVille"
                  {...register('postale.ville')}
                />
              </div>
            </div>
          </>
        )}

        <div className="adressePostaleRDV">
          <p className="title p-0">Adresse postale pour les rendez-vous</p>
          <div className="content">
            <div className="adresse">
              {isMeeting && isMeeting.adresseLine1}
              <br />
              {isMeeting &&
                isMeeting.adresseLine2 &&
                `${isMeeting.adresseLine2}<br/>`}
              {isMeeting && isMeeting.codePostal} {isMeeting && isMeeting.ville}
            </div>
            <button
              type="button"
              className="btn border border-primary rounded-pill"
              onClick={() => setShowPostaleRDV(!showPostaleRDV)}
            >
              Modifier mon adresse
            </button>
          </div>
        </div>
        {showPostaleRDV && (
          <>
            <div className="my-3">
              <Controller
                name="isIdentical"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Checkbox
                    label={
                      ' L’adresse postale pour les rendez-vous est identique'
                    }
                    name={'isIdentical'}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultChecked={value}
                  />
                )}
              />
            </div>
            <div
              className={`form-group px-0 col-md-12 ${
                watch('isIdentical') && 'disabled'
              }`}
            >
              <label htmlFor="InputAdresseRDV">
                Adresse postale pour les rendez-vous
              </label>
              <TextField
                name="adresseLine1"
                className="addressForm isMeeting"
                id="InputAdresseRDV"
                {...register('rdv.adresseLine1', { required: true })}
              />
            </div>
            <div
              className={`form-group px-0 col-md-12 ${
                watch('isIdentical') && 'disabled'
              }`}
            >
              <label htmlFor="InputAdresseRDV2">Complément d'adresse</label>
              <TextField
                name="adresseLine2"
                className="addressForm isMeeting"
                id="InputAdresseRDV2"
                {...register('rdv.adresseLine2')}
              />
            </div>
            <div className="form-inline px-0 col-12 p-0 pb-2">
              <div
                className={`form-group px-0 col-12 ${
                  watch('isIdentical') && 'disabled'
                }`}
              >
                <label htmlFor="inputCodePostal2">Code postal</label>
                <TextField
                  name="codePostal"
                  className="px-0 col-12 mt-2 addressForm isMeeting"
                  id="inputCodePostal2"
                  {...register('rdv.codePostal', { required: true })}
                />
              </div>
              <div
                className={`form-group px-0 col-12 ${
                  watch('isIdentical') && 'disabled'
                }`}
              >
                <label htmlFor="inputVille2">Ville</label>
                <TextField
                  name="ville"
                  className="px-0 col-12 mt-2 addressForm isMeeting"
                  id="inputVille2"
                  {...register('rdv.ville', { required: true })}
                />
              </div>
            </div>
          </>
        )}
        <h5 className="fw-bold text-primary">Informations complémentaires</h5>
        <div className="form-inline px-0 col-12 p-0 pb-2">
          <div className="form-group px-0 col-md-12">
            <label htmlFor="inputEffectif">Effectif de la société</label>
            <input
              type="number"
              name="societeNbSalaries:number"
              className="px-0 col-12 form-control mt-2 salaries_election"
              id="inputEffectif"
              {...register('societeNbSalaries', { required: true })}
            />
          </div>
          <div className="form-group px-0 col-md-12 elections">
            <label htmlFor="inputElections">
              Date des prochaines élections
            </label>
            <input
              type="date"
              name="dateElection"
              className="px-0 col-12 form-control mt-2 salaries_election"
              id="inputElections"
              {...register('dateElection', { required: true })}
            />
          </div>
        </div>
        <h5 className="fw-bold text-primary">
          Conditions générales d'utilisation
        </h5>
        <div className="form-inline px-0 col-12 p-0 pb-2 conditions-generales">
          <p>
            <span>
              Pour activer votre espace, veuillez prendre connaissance et
              accepter les Conditions Générales d’Utilisation de l’Espace
              Client.
            </span>
            <span>
              Retrouvez le détail de nos Conditions d’Utilisation&nbsp;
              <Link
                to={cguDocumentLink}
                target="_blank"
                rel="noopener noreferrer"
                download
                className="link"
              >
                <u>
                  dans ce document (PDF, Français, {cguDocumentSize} Ko).
                  <img
                    className="stroke-primary file-download-icon not-disabled"
                    src={FileDownload}
                    alt="logo Edenred Solutions CSE (ex ProwebCE)"
                  />
                </u>
              </Link>
            </span>
          </p>
          <Controller
            name="condition"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Checkbox
                label={
                  'J’ai lu et j’accepte les Conditions Générales d’Utilisation de l’Espace Client'
                }
                name={'condition'}
                onChange={onChange}
                onBlur={onBlur}
                defaultChecked={value}
              />
            )}
          />
        </div>
        <div className="text-center">
          <LoadingButton
            type="submit"
            classes="btn btn-primary rounded-pill btn-block "
            id="sendlogin"
            text="Valider les informations"
            loading={loadingButton}
            spinnerHeight="20"
            disabled={watch('condition') === false}
          />
        </div>
      </form>
    </div>
  );
}
