import React from 'react';

import Loader from '@components/Loader/Loader';

import './LoadingScreen.scss';

export default function LoadingScreen() {
  return (
    <div className="loading-screen position-fixed h-100 w-100 d-flex flex-column justify-content-center align-items-center text-center">
      <h1 className="d-block fw-bold mb-5 text-primary">
        Récupération de votre téléchargement...
      </h1>
      <Loader />
    </div>
  );
}
