import React, { useContext } from 'react';

import 'dayjs/locale/fr';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import Booking from '@components/Booking/Booking';

import { CreateAppointmentContext } from '@context/CreateAppointmentContext';

import GoogleTagManagement from '@GoogleTagManagement';

import { ReactComponent as Distance } from '@img/icons/distance.svg';

import './rendez-vous.scss';

export default function RendezVous() {
  const { goToNextStep, date, setDate, format, motif } = useContext(
    CreateAppointmentContext
  );

  return (
    <div className="step rendez-vous">
      <GoogleTagManagement titlePage="Date du rendez-vous" />
      <span className="badge badge-primary">
        <>
          <Distance className="float-start" /> {motif.subject}
        </>
      </span>
      <div className="title text-primary fw-bold text-center">
        Quand souhaitez-vous prendre rendez-vous ?
        <span>Sélectionnez un horaire</span>
      </div>

      <Booking
        onClickNext={goToNextStep}
        date={date}
        setDate={setDate}
        format={format}
        motif={motif}
      />
    </div>
  );
}
