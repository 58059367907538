import React from 'react';

import PropTypes from 'prop-types';
import Select from 'react-select';

import './SelectList.scss';

export const SelectList = ({
  options,
  placeholder,
  className,
  defaultValue,
  onChange,
  ...props
}) => {
  return (
    <Select
      options={options}
      placeholder={placeholder}
      defaultValue={defaultValue}
      className={`form-control-react-select ${className}`}
      onChange={onChange}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          text: 'white',
          primary25: '#96D9FB',
          primary: '#151D49',
        },
      })}
      {...props}
    />
  );
};

SelectList.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
};

SelectList.defaultProps = {
  options: [],
  placeholder: '',
  className: '',
  defaultValue: null,
  onChange: () => {},
};
