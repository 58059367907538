import React from 'react';

import { Link } from 'react-router-dom';

import CardCenter from '@components/Card/CardCenter';

import GoogleTagManagement from '@GoogleTagManagement';

import ForbiddenImage from '@img/forbidden.svg';

import './UserNotExist.scss';

export default function UserNotExist() {
  return (
    <CardCenter row_class="min-height-full-with-navbar" id="user-not-exist">
      <GoogleTagManagement titlePage="Difficultés à me connecter : Vous n'avez pas encore créé votre compte" />
      <div className="card-body text-center">
        <h2 className="card-title fw-bold text-center">
          Vous n'avez pas encore créé votre compte !
        </h2>
        <img
          src={ForbiddenImage}
          className="img-fluid"
          alt="mot de passe oublie"
        ></img>
        <p className="card-text mt-4">
          Pour accéder à l'Espace Client vous devez créer votre compte
        </p>
        <div className="buttons ">
          <Link
            to={{ pathname: `/create-account` }}
            className="create-account "
          >
            <div className="btn btn-block btn-primary rounded-pill mb-0">
              Je crée mon compte
            </div>
          </Link>
        </div>
      </div>
    </CardCenter>
  );
}
