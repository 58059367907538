import React, { useContext } from 'react';

import { useAuth } from '@authProvider';
import { Route } from 'react-router-dom';

import CreateAppointmentProvider from '@context/CreateAppointmentContext';
import { ProjectContext } from '@context/ProjectContext';

import { setRedirectionPathname } from '@helpers';

import Appointment from '@pages/appointment/appointment';
import CreateAppointment from '@pages/createAppointment/createAppointment';
import Dashboard from '@pages/dashboard/dashboard';
import Documents from '@pages/documents/documents';
import DocumentsToSign from '@pages/documentsToSign/documentsToSign';
import Equipment from '@pages/equipment/equipment';
import FaqContact from '@pages/faq/contact/contact';
import Faq from '@pages/faq/faq';
import FaqCategory from '@pages/faq/faq-category';
import FaqSubCategory from '@pages/faq/faq-sub-category';
import FaqQuestion from '@pages/faq/question/question';
import PdfViewer from '@pages/pdfViewer/pdfViewer';
import PostponeAppointment from '@pages/postponeAppointment/postponeAppointment';
import Profil from '@pages/profil/profil';
import Project from '@pages/project/project';
import Requests from '@pages/requests/requests';

import AppLayout from '../AppLayout/AppLayout';
import AppRedirect from '../AppRedirect/AppRedirect';
import FooterDefault from '../Footer/FooterDefault';
import NavbarDefault from '../Navbar/NavbarDefault';
import SideBarDefault from '../Sidebar/SidebarDefault';

export default function ProtectedContentRoute({
  component,
  hasCreateAppointmentProvider,
  ...routeProps
}) {
  const [logged] = useAuth();

  !logged && setRedirectionPathname();
  return (
    <Route
      {...routeProps}
      render={(props) => {
        return logged ? (
          hasCreateAppointmentProvider ? (
            <CreateAppointmentProvider>
              <AppLayout {...props} {...routeProps}>
                {React.createElement(component, props)}
              </AppLayout>
            </CreateAppointmentProvider>
          ) : (
            <AppLayout {...props} {...routeProps}>
              {React.createElement(component, props)}
            </AppLayout>
          )
        ) : (
          <AppRedirect path="/" exact redirectPath="/" name="redirect" />
        );
      }}
    />
  );
}

export function ProtectedContentRouteTab() {
  const { hasProject } = useContext(ProjectContext);

  return [
    {
      name: 'appointment',
      path: '/appointment',
      exact: true,
      navBar: NavbarDefault,
      footer: FooterDefault,
      sideBar: SideBarDefault,
      component: Appointment,
      title: 'Mes rendez-vous',
      toggleFeature: 'RENDEZ_VOUS',
      routeType: 'ProtectedContentRoute',
    },
    {
      name: 'create_appointment',
      path: '/appointment/create',
      exact: true,
      navBar: NavbarDefault,
      footer: FooterDefault,
      sideBar: SideBarDefault,
      component: CreateAppointment,
      toggleFeature: 'RENDEZ_VOUS',
      hasCreateAppointmentProvider: true,
      routeType: 'ProtectedContentRoute',
    },
    {
      name: 'delay_appointment',
      path: '/appointment/postpone/:rdvId',
      exact: true,
      navBar: NavbarDefault,
      footer: FooterDefault,
      sideBar: SideBarDefault,
      component: PostponeAppointment,
      toggleFeature: 'RENDEZ_VOUS',
      routeType: 'ProtectedContentRoute',
    },
    {
      name: 'dashboard',
      path: '/dashboard',
      exact: true,
      navBar: NavbarDefault,
      footer: FooterDefault,
      sideBar: SideBarDefault,
      component: Dashboard,
      title: 'Tableau de bord',
      routeType: 'ProtectedContentRoute',
    },
    {
      name: 'profil',
      path: '/profil',
      exact: true,
      navBar: NavbarDefault,
      footer: FooterDefault,
      sideBar: SideBarDefault,
      component: Profil,
      title: 'Mon profil',
      routeType: 'ProtectedContentRoute',
    },
    {
      name: 'pdfViewer',
      path: '/pdfViewer/:pdfId',
      exact: true,
      component: PdfViewer,
      title: 'Document',
      routeType: 'ProtectedContentRoute',
    },
    {
      name: 'documents',
      path: '/documents-list',
      exact: true,
      navBar: NavbarDefault,
      footer: FooterDefault,
      sideBar: SideBarDefault,
      component: Documents,
      title: 'Mes documents',
      routeType: 'ProtectedContentRoute',
    },
    {
      name: 'requests',
      path: '/requests',
      exact: true,
      navBar: NavbarDefault,
      footer: FooterDefault,
      sideBar: SideBarDefault,
      component: Requests,
      title: 'Mes demandes',
      toggleFeature: 'DEMANDE_CLIENT',
      routeType: 'ProtectedContentRoute',
    },
    {
      name: 'documents-to-sign',
      path: '/documents-to-sign',
      exact: true,
      navBar: NavbarDefault,
      footer: FooterDefault,
      sideBar: SideBarDefault,
      component: DocumentsToSign,
      title: 'Documents à signer',
      routeType: 'ProtectedContentRoute',
    },
    {
      name: 'equipment',
      path: '/equipment',
      exact: true,
      navBar: NavbarDefault,
      footer: FooterDefault,
      sideBar: SideBarDefault,
      component: Equipment,
      title: 'Mon équipement',
      routeType: 'ProtectedContentRoute',
    },
    {
      name: 'faq',
      path: '/help',
      exact: true,
      navBar: NavbarDefault,
      footer: FooterDefault,
      sideBar: SideBarDefault,
      component: Faq,
      title: "Besoin d'aide",
      routeType: 'ProtectedContentRoute',
    },
    {
      name: 'faq-contact',
      path: '/help/contact',
      exact: true,
      navBar: NavbarDefault,
      footer: FooterDefault,
      sideBar: SideBarDefault,
      component: FaqContact,
      title: 'Contactez-nous',
      routeType: 'ProtectedContentRoute',
    },
    {
      name: 'faq-category',
      path: '/help/:thematic',
      exact: true,
      navBar: NavbarDefault,
      footer: FooterDefault,
      sideBar: SideBarDefault,
      component: FaqCategory,
      routeType: 'ProtectedContentRoute',
    },
    {
      name: 'faq-page',
      path: '/help/question/:nbPage',
      exact: true,
      navBar: NavbarDefault,
      footer: FooterDefault,
      sideBar: SideBarDefault,
      component: FaqQuestion,
      title: "Question d'aide",
      routeType: 'ProtectedContentRoute',
    },
    {
      name: 'faq-sub-category',
      path: '/help/:thematic/:subThematic',
      exact: true,
      navBar: NavbarDefault,
      footer: FooterDefault,
      sideBar: SideBarDefault,
      component: FaqSubCategory,
      routeType: 'ProtectedContentRoute',
    },
    {
      name: 'project',
      path: '/project',
      exact: true,
      navBar: NavbarDefault,
      footer: FooterDefault,
      sideBar: SideBarDefault,
      component: Project,
      title: 'Mon projet',
      condition: hasProject,
      routeType: 'ProtectedContentRoute',
    },
  ];
}
