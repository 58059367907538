import React, { createContext, useState } from 'react';

export const LoadingScreenContext = createContext();

const LoadingScreenContextProvider = (props) => {
  const [loading, setLoading] = useState(true);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  return (
    <LoadingScreenContext.Provider value={{ loading, toggleLoading }}>
      {props.children}
    </LoadingScreenContext.Provider>
  );
};

export default LoadingScreenContextProvider;
