import React, { useContext } from 'react';

import { ToggleFeatureContext } from '@context/ToggleFeatureContext';

export default function Feature({ name, children }) {
  const { toggleFeature } = useContext(ToggleFeatureContext);
  const feature = toggleFeature !== null && toggleFeature[name];

  if (feature) {
    if (feature.actire) {
      return children;
    }
    return children;
  } else {
    return <span></span>;
  }
}
