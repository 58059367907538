import React from 'react';

export default function Affectation({ infoProject }) {
  return (
    <div className="project-content col-12 bg-white">
      <h5 className="rect_info_title">Affectation</h5>
      <p>
        Votre projet a démarré ! Pour vous accompagner tout au long du projet,
        un chef de projet vous est dédié et sera votre contact privilégié.
      </p>
      <div className="infos_chef">
        <p>
          Votre Chef de projet :{' '}
          <span className="fw-bold">
            {infoProject.leader.prenom} {infoProject.leader.nom}
          </span>
        </p>
        {infoProject.leader.mail && (
          <p>
            Son adresse e-mail :{' '}
            <span className="fw-bold">{infoProject.leader.mail}</span>
          </p>
        )}
        {infoProject.leader.telephone && (
          <p>
            Numéro de téléphone :{' '}
            <span className="fw-bold">{infoProject.leader.telephone}</span>
          </p>
        )}
      </div>

      <p className="fw-bold">
        Votre chef de projet vous contactera très vite pour vous parler du
        déroulé de votre projet.
      </p>
    </div>
  );
}
