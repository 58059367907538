import React, { useEffect, useState } from 'react';

import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import { Link, useParams } from 'react-router-dom';

import Question from '@components/Question/Question';

import '../faq.scss';

export default function FaqQuestion() {
  const { nbPage } = useParams();
  const [question, setQuestion] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/faq_pages/${nbPage}`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem('REACT_TOKEN_AUTH_KEY')
          )}`,
        },
      })
      .then((res) => {
        const data = res.data;
        setQuestion([
          {
            id: data.id,
            title: data.title,
            disabled: !data.enabled,
            content: data.content,
            vote: data.vote,
          },
        ]);

        axios.put(
          `${process.env.REACT_APP_BACK_URL}/api/faq_pages/${nbPage}`,
          { numberView: 1 },
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem('REACT_TOKEN_AUTH_KEY')
              )}`,
            },
          }
        );

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  }, [nbPage]);

  return (
    <div className="w-100 faq">
      <div className="header-faq">
        <h3 className="title_page">Besoin d'aide</h3>
        <Link
          to={{ pathname: '/help/contact' }}
          className="btn btn-primary rounded-pill contact"
        >
          Contactez-nous
        </Link>
      </div>

      <div className="row">
        <div className="col-12 rect_info rect_info_search_bill">
          <div className="col-12">
            {error ? (
              <h5 className="mb-0 text-error">
                <span className="fw-bold">
                  <i className="fas fa-exclamation-triangle text-error me-2"></i>
                  Erreur :
                </span>{' '}
                Un problème est survenu lors de la récupération des données.
              </h5>
            ) : (
              <>
                <Link
                  to={{ pathname: `/help` }}
                  className="return text-primary text-decoration-none"
                >
                  <i className="fa fa-arrow-left me-2"></i>Retour
                </Link>
                <div className="thematic">
                  <div className="questions">
                    {loading ? (
                      <div className="w-100 skeleton">
                        <section className="d-flex flex-column align-items-center justify-center w-100">
                          <Skeleton height={20} width={300} className="mb-4" />

                          <Skeleton
                            height={20}
                            width="100%"
                            count={3}
                            className="mb-1"
                          />
                          <Skeleton height={20} width="60%" className="mb-4" />
                        </section>
                      </div>
                    ) : (
                      question.map((questionsItem, index) => {
                        return (
                          <div id="accordion" key={index}>
                            <Question
                              index={index}
                              id={questionsItem.id}
                              title={questionsItem.title}
                              disabled={questionsItem.disabled}
                              content={questionsItem.content}
                              vote={questionsItem.vote}
                              open={true}
                            />
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
                <div className="contact">
                  <h5 className="fw-bold">
                    Vous ne trouvez pas de réponse à vos questions ?
                  </h5>
                  <Link
                    to={{ pathname: '/help/contact' }}
                    className="btn btn-primary rounded-pill"
                  >
                    Contactez-nous
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
