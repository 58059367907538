import React, { useState, useEffect } from 'react';

import $ from 'jquery';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// import "./carousel.scss";
import CarouselItem from '@components/Card_rdv/mini_card_rdv';

import './carouselRDV.scss';

// import { ReactComponent as AccompagnementGestion } from "../../img/icons/accompagnement_gestion.svg";
// import { ReactComponent as AccompagnementComm } from "../../img/icons/accomp_com.svg";
// import { ReactComponent as Formation } from "../../img/icons/formation.svg";
// import { ReactComponent as Conseil } from "../../img/icons/Conseil.svg";

export default function CarouselComponent({
  data,
  setShowModal,
  setModalData,
  setShowModalPlace,
  loading,
}) {
  const [numberSlide, setNumberSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: numberSlide,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    handleResize();

    function handleResize() {
      const container = $('.slider-rdv .slick-list');
      let containerWidth = container.width();
      let itemWidth = $('.slick-slide:nth-child(2)', container).outerWidth(
        true
      );
      let numberSlideCalc = itemWidth ? containerWidth / itemWidth : 2;
      setNumberSlide(numberSlideCalc);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Slider {...settings} className="slider slider-rdv">
      {data &&
        data.map((item, index) => {
          return (
            <CarouselItem
              key={index}
              data={item}
              setShowModal={setShowModal}
              setModalData={setModalData}
              setShowModalPlace={setShowModalPlace}
            />
          );
        })}
    </Slider>
  );
}
