import React, { useContext, useEffect } from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { CreateAppointmentContext } from '@context/CreateAppointmentContext';

import GoogleTagManagement from '@GoogleTagManagement';

import {
  useMotifs,
  useSkynetMainResponsable,
  usePrefetchSkynetCalendarBusySlotDistance,
} from '@hooks/useApi';

import { convertMinsToHrsMins, convertTimeToMs } from '@helpers';

import { ReactComponent as Profile } from '@img/icons/profile.svg';

import './motif.scss';

export default function Motif() {
  const { motif, setMotif, setFormat, goToNextStep } = useContext(
    CreateAppointmentContext
  );

  const { data: dataMotif, isLoading: isLoadingMotif } = useMotifs();

  useEffect(() => {
    if (!isLoadingMotif && dataMotif && dataMotif?.length !== 0) {
      setMotif(dataMotif[0]);
    }
  }, [isLoadingMotif, dataMotif]);

  const onSubmit = (e) => {
    e.preventDefault();
    setFormat(0);
    goToNextStep();
  };

  const onChangeInput = (e) => {
    const indexMotif = dataMotif.findIndex(
      (element) =>
        element.id === parseInt(e.target.getAttribute('data-id-motif'))
    );
    const selectedMotif = dataMotif[indexMotif];
    setMotif(selectedMotif);
  };

  const { data: dataMainResponsable } = useSkynetMainResponsable({
    cacheTime: convertTimeToMs(1, 'day'),
    staleTime: convertTimeToMs(1, 'day'),
  });

  usePrefetchSkynetCalendarBusySlotDistance(0, motif);

  return (
    <div className="step motif">
      <GoogleTagManagement titlePage="Motif du rendez-vous" />

      <div className="title-interlocuteur text-primary fw-bold text-center">
        Voici l'interlocuteur de votre rendez-vous
      </div>
      <div className="interloculteur-infos">
        <Profile className="icon-profile" />
        <div className="infos">
          <div className="name text-primary">
            {dataMainResponsable &&
              dataMainResponsable?.prenom + ' ' + dataMainResponsable?.nom}
          </div>
          <span className="text-placeholder">Chargé de clientèle</span>
        </div>
      </div>

      <div className="title text-primary fw-bold text-center">
        Pour quel motif souhaitez-vous planifier cet échange ?
      </div>
      <form
        onSubmit={(e) => onSubmit(e)}
        className="form-motif"
        data-cy="form-motif"
      >
        {!isLoadingMotif ? (
          dataMotif?.map((motifItem, index) => {
            return (
              <div
                className="form-check custom-control custom-radio form-check-inline"
                key={`inputRadioMotif${index}`}
              >
                <input
                  className="custom-control-input form-check-input"
                  type="radio"
                  name="radioMotif"
                  id={`radioMotif${index}`}
                  value={motifItem.subject}
                  checked={motif?.id === motifItem?.id ? 'checked' : ''}
                  data-id-motif={motifItem.id}
                  onChange={onChangeInput}
                  data-cy="motif-radio"
                />
                <label
                  className="custom-control-label text-primary form-check-label"
                  htmlFor={`radioMotif${index}`}
                >
                  {motifItem.subject}
                  <span className="text-placeholder">
                    {' '}
                    Durée estimée - {convertMinsToHrsMins(motifItem.duration)}
                  </span>
                </label>
              </div>
            );
          })
        ) : (
          <div className="col-12 skeleton">
            <SkeletonTheme width="100%" height="100%">
              <section className="d-flex flex-column align-items-center justify-center w-100">
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />
              </section>
            </SkeletonTheme>
          </div>
        )}

        <div className="text-center button">
          <button
            type="submit"
            className="btn btn-primary rounded-pill fw-600"
            data-cy="submit-button-next"
          >
            Suivant
          </button>
        </div>
      </form>
    </div>
  );
}
