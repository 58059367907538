import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';
import dayjs from 'dayjs';
import $ from 'jquery';
import { createRoot } from 'react-dom/client';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import Table from '@components/Table/TableDefault';

import { NewDocumentContext } from '@context/NewDocumentContext';

import { ReactComponent as Man } from '@img/man.svg';
import { ReactComponent as Women } from '@img/women.svg';

import '../documents/documents.scss';

import './documentsToSign.scss';

export default function DocumentsToSign() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState('');
  const [datatableState, setDatatableState] = useState(null); // eslint-disable-line no-unused-vars

  const { changeDocumentToSign } = useContext(NewDocumentContext);

  const columns = [
    {
      title: 'Libellé',
      data: 'libelle',
      className: 'dt-left',
      orderable: false,
      // width: "125px",
      render: function (data, type, row) {
        if (data) {
          return '<span class="me-1">' + data;
        }
        return 'Enveloppe_' + row.id;
      },
      createdCell: function (td, cellData, rowData, row, col) {
        $(td).attr('data-search', cellData);
      },
    },
    {
      title: 'Type',
      data: null,
      className: '',
      visible: false,
      render: function (data, type, row) {
        return '';
      },
    },
    {
      title: 'Date',
      data: 'createdAt',
      className: 'dt-date dt-right',
      type: 'date-euro',
      render: function (data, type, row) {
        const date = dayjs(data).format('DD/MM/YYYY');
        return date !== 'Invalid date'
          ? '<span class="d-none">' + data + '</span>' + date
          : '';
      },
    },
    {
      title: 'Action',
      data: null,
      orderable: false,
      className: 'dt-right',
      width: '210px',
      createdCell: (td, cellData, rowData, row, col) =>
        createRoot(td).render(
          <div className="d-flex justify-content-end bill-actions">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => redirectDocusign(rowData.envelopeId)}
              style={{
                maxWidth: '155px',
                paddingLeft: '0px',
                paddingRight: '0px',
              }}
            >
              Signer les documents
            </button>
          </div>
        ),
    },
  ];

  const redirectDocusign = (envelopeId) => {
    axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/skynet/envelope/sign/${envelopeId}`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem('REACT_TOKEN_AUTH_KEY')
            )}`,
          },
        }
      )
      .then((res) => {
        if (res.data.url !== undefined) {
          localStorage.setItem('envelope_id', envelopeId);
          window.location.href = res.data.url;
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/skynet/envelopes/list`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem('REACT_TOKEN_AUTH_KEY')
          )}`,
        },
      })
      .then((res) => {
        let tab = [];
        res.data.forEach((item) => {
          if (
            item.status !== 'completed' &&
            item.contactStatus !== 'completed'
          ) {
            tab.push(item);
          }
        });

        res.data.findIndex(
          (item) =>
            item.status !== 'completed' && item.contactStatus !== 'completed'
        ) !== -1
          ? changeDocumentToSign(true)
          : changeDocumentToSign(false);
        setData(tab);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-100 documents-to-sign documents">
      <h3 className="title_page">Documents à signer</h3>
      <div className="row">
        <div className="col-12 rect_info">
          <div className="col-12">
            {loading ? (
              <div className="col-12 skeleton">
                <SkeletonTheme width="100%">
                  <section className="d-flex flex-column align-items-center justify-center w-100">
                    <Skeleton height={60} className="mb-2" />
                    <Skeleton
                      height={34}
                      count={5}
                      className="mb-1 line-table"
                    />
                  </section>
                </SkeletonTheme>
              </div>
            ) : error ? (
              <h5 className="mb-0 text-error">
                <span className="fw-bold">
                  <i className="fas fa-exclamation-triangle text-error me-2"></i>
                  Erreur :
                </span>{' '}
                Un problème est survenu lors de la récupération des données.
              </h5>
            ) : data.length > 0 ? (
              <>
                <Table
                  data={data}
                  columns={columns}
                  setDatatable={setDatatableState}
                />
              </>
            ) : (
              <div className="d-flex justify-content-between align-items-center no-documents-to-sign">
                <Women />
                <p>
                  Il n'y a aucun document à signer.<span>Tout est ok !</span>
                </p>
                <Man />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
