import React, { useState, useEffect } from 'react';

import axios from 'axios';
import dayjs from 'dayjs';
import $ from 'jquery';
import { createRoot } from 'react-dom/client';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { TextField } from '@molecules';

import DropdownButton from '@components/DropdownButton/DropdownButton';
import Table from '@components/Table/TableDefault';

import { getEnvironment } from '@helpers';

import Info from '@img/icons/Info.svg';
import { ReactComponent as Delete } from '@img/icons/delete.svg';
import { ReactComponent as Search } from '@img/icons/search.svg';

import ModalTimeline from './ModalTimeline';

export default function Bill({ loading, error, data, setLoadingDownload }) {
  const [searchBarInput, setSearchBarInput] = useState('');
  const [datatableState, setDatatableState] = useState(null);
  const [dataTimeline, setDataTimeline] = useState(null);
  const [showModalTimeline, setShowModalTimeline] = useState(false);
  const [modalTimelineBillID, setModalTimelineBillID] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const dropdownButtonsRef = React.useRef([]);
  const tooltipsRef = React.useRef([]);

  useEffect(() => {
    return () => {
      setTimeout(() => {
        dropdownButtonsRef?.current?.forEach((dropdownButton) => {
          dropdownButton?.unmount();
        });
        tooltipsRef?.current?.forEach((tooltip) => {
          tooltip?.unmount();
        });
      });
    };
  }, []);

  const columns = [
    {
      title: '',
      data: null,
      defaultContent: '',
      orderable: false,
      className: 'control',
    },
    {
      title: '',
      data: null,
      defaultContent: '',
      orderable: false,
      className: 'select-checkbox',
    },
    {
      title: 'N° Document',
      data: 'numDfa',
      className: 'dt-left fw-bold',
    },
    {
      title: 'Libellé',
      data: 'name',
      className: 'dt-left',
      orderable: false,
      width: '125px',
      render: function (data, type, row) {
        return data || '';
      },
    },
    {
      title: 'Type de document',
      data: 'numDfa',
      className: 'dt-left',
      render: function (data, type, row) {
        if (data[0] === 'A') return 'Avoir';
        return 'Facture';
      },
    },
    {
      title: 'Date',
      data: 'dateDfa',
      className: 'dt-date dt-right',
      type: 'date-euro',
      render: function (data, type, row) {
        const date = dayjs(data).format('DD/MM/YYYY');
        return date !== 'Invalid date'
          ? '<span class="d-none">' + data + '</span>' + date
          : '';
      },
    },
    {
      title: 'Montant',
      data: 'montantTotalFacture',
      className: 'dt-right fw-bold',
      type: 'currency',
      render: function (data, type, row) {
        return data + ' €';
      },
    },
    {
      title: 'Payé',
      data: 'pourcentageEncaissement',
      className: 'dt-right',
      createdCell: (td, cellData, rowData, row, col) => {
        const diffDay = dayjs().diff(rowData.dateDfa, 'day');
        if (
          rowData.dateDfa &&
          diffDay < 1 &&
          (cellData === 0 || cellData === null)
        ) {
          const tooltip = createRoot(td);

          tooltipsRef.current.push(tooltip);

          return tooltip.render(
            <>
              <img
                src={Info}
                data-tip
                data-for="infoPayement"
                alt="icone info"
                className="info-payement-icon"
              />
              <ReactTooltip
                id="infoPayement"
                place="bottom"
                type="info"
                effect="solid"
                className="reactTooltip"
              >
                <span>
                  Cette information est en cours de traitement, elle sera
                  disponible d'ici 24h
                </span>
              </ReactTooltip>
            </>
          );
        }

        return $(td).html(cellData !== null ? `${cellData} %` : '0 %');
      },
    },
    {
      title: 'Statut',
      data: null,
      className: 'dt-right fw-bold',
      orderable: true,
      searchable: false,
      render: function (data, type, row) {
        let classe = '';
        let message = 'En cours';
        if (row.isEnRetard) {
          classe = 'text-error';
          message = 'En retard';
        } else if (parseInt(row.pourcentageEncaissement) === 100) {
          classe = 'text-success';
          message = 'Payé';
        } else if (row.numDfa[0] === 'A') {
          // eslint-disable-line no-dupe-else-if
          classe = 'text-start-color';
          message = 'Traité';
        }
        return '<span class="' + classe + '">' + message + '</span>';
      },
    },
    {
      title: 'Action',
      data: null,
      orderable: false,
      className: 'dt-right',
      width: '115px',
      createdCell: (td, cellData, rowData, row, col) => {
        const items = [
          {
            name: 'Télécharger',
            onClick: () => downloadDocument([rowData.numDfa], rowData.name),
          },
          {
            name: "Afficher l'échéancier",
            displayed: rowData.paiementsEcheance.length > 0,
            onClick: () => {
              setModalTimelineBillID(rowData.numDfa);
              setDataTimeline(rowData.paiementsEcheance);
              setShowModalTimeline(true);
            },
          },
        ];

        const dropdownButton = createRoot(td);

        dropdownButtonsRef.current.push(dropdownButton);

        return dropdownButton.render(
          <DropdownButton
            name="Consulter"
            onClick={() => {
              goPdfViewer(rowData.numDfa);
            }}
            items={items}
          />
        );
      },
    },
  ];

  const onChangeSearchBar = (e) => {
    e.preventDefault();
    setSearchBarInput(e.target.value);
  };

  const handleClickDownload = () => {
    let bills = [];
    datatableState
      .rows('.selected')
      .data()
      .map((data) => {
        return bills.push(data.numDfa);
      });
    bills.length && downloadDocument(bills);
  };

  const goPdfViewer = (id) => {
    let win = window.open(`/pdfViewer/${id}`, '_blank');
    win.focus();
    // history.push(`/pdfViewer/${id}`);
  };

  const downloadDocument = (documents, name) => {
    setLoadingDownload(true);
    axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/skynet/edfa/pdf/${documents}`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem('REACT_TOKEN_AUTH_KEY')
            )}`,
          },
        }
      )
      .then((res) => {
        const linkSource = `${JSON.parse(res.data)}`;
        const downloadLink = document.createElement('a');
        let fileName = '';
        name
          ? (fileName = name.split(' ').join('_'))
          : (fileName = `document_${dayjs()
              .locale('fr')
              .format('DDMMYYYY')}.pdf`);

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        setLoadingDownload(false);
      })
      .catch((error) => {
        setLoadingDownload(false);
        // setError(true)
      });
  };

  const getFaqUrl = () => {
    let environment = getEnvironment();

    switch (environment) {
      case 'local':
        return '/help/6/12';
      case 'recette':
        return '/help/7/18';
      case 'pre-prod':
        return '/help/7/18';
      case 'prod':
        return '/help/7/18';
      default:
        return '/help/7/18';
    }
  };

  return (
    <div>
      <div className="row">
        <div className="row mx-0 rect_info rect_info_search">
          {loading ? (
            <div className="col-12 skeleton">
              <SkeletonTheme width="100%">
                <section className="d-flex flex-column align-items-center justify-center w-100">
                  <Skeleton height={20} width="30%" className="mb-4" />
                  <Skeleton
                    height={20}
                    width="100%"
                    className="mb-0 search-bar"
                  />
                </section>
              </SkeletonTheme>
            </div>
          ) : (
            <div className="col-12">
              <h5 className="rect_info_title">Rechercher par n° ou libellé</h5>
              <form className="form-inline form-search d-flex flex-nowrap">
                <TextField
                  className="form-control-sm me-3 w-100 border-0"
                  placeholder="Ex : Chèque cadeau ou FG18325"
                  aria-label="Search"
                  onChange={(e) => onChangeSearchBar(e)}
                  value={searchBarInput}
                  data-cy="bill-search"
                />
                <Delete
                  className={`delete-icon ${
                    searchBarInput !== '' ? 'active' : ''
                  }`}
                  onClick={() => setSearchBarInput('')}
                />
                <Search className="search-icon" />
              </form>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 rect_info document">
          <div className="col-12">
            {loading ? (
              <div className="col-12 skeleton">
                <SkeletonTheme width="100%">
                  <section className="d-flex flex-column align-items-center justify-center w-100">
                    <div className="text-end w-100">
                      <Skeleton
                        // height={60}
                        height={30}
                        // width="55%"
                        width={300}
                        className="mb-4 rounded-pill button"
                      />
                    </div>
                    <Skeleton height={60} className="mb-2" />
                    <Skeleton
                      height={34}
                      count={10}
                      className="mb-1 line-table"
                    />
                  </section>
                </SkeletonTheme>
              </div>
            ) : error ? (
              <h5 className="mb-0 text-error">
                <span className="fw-bold">
                  <i className="fas fa-exclamation-triangle text-error me-2"></i>
                  Erreur :
                </span>{' '}
                Un problème est survenu lors de la récupération des données.
              </h5>
            ) : data.length > 0 ? (
              <>
                <button
                  type="button"
                  className="btn btn-download btn-white-border float-end rounded-pill"
                  onClick={handleClickDownload}
                >
                  Télécharger les documents sélectionnées
                </button>
                <Table
                  name="bill"
                  data={data}
                  columns={columns}
                  searchBarInput={searchBarInput}
                  setDatatableThis={setDatatableState}
                  downloadDocument={downloadDocument}
                  dataCy="bill-table"
                  serverSide={false}
                  refreshTable={refreshTable}
                  setRefreshTable={(boolean) => setRefreshTable(boolean)}
                />
                <div className="contact">
                  <h5 className="fw-bold" onClick={getFaqUrl}>
                    Vous avez une question sur vos factures ?
                  </h5>
                  <Link
                    to={{ pathname: getFaqUrl() }}
                    className="btn btn-primary rounded-pill"
                  >
                    Consulter la FAQ
                  </Link>
                </div>
              </>
            ) : (
              <h5 className="fw-bold text-primary">
                Vous n'avez aucune facture à disposition
              </h5>
            )}
          </div>
        </div>
      </div>
      <ModalTimeline
        show={showModalTimeline}
        // onHide={() => setShowModal(false)}
        setShowModal={setShowModalTimeline}
        dataTimeline={dataTimeline}
        billID={modalTimelineBillID}
        onExited={() => {
          setRefreshTable('bill');
        }}
      />
    </div>
  );
}
