import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { Link } from 'react-router-dom';

import { Checkbox } from '@atoms';

import LoadingButton from '@components/LoadingButton/LoadingButton';

import { getSizeDocument } from '@helpers';

import FileDownload from '@img/icons/common-file-download--icon.png';

import Modal from '../Modal/ModalDefault';

import './ModalCGU.scss';

export default function ModalCGU({ show, setShow }) {
  const cguDocumentLink = '/documents/Def_CGU_Espace_Client_ERF_2024.pdf';
  const [cguDocumentSize, setCguDocumentSize] = useState('');
  const [conditionChecked, setConditionChecked] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    //Récupération de la taille du document cgu
    async function asyncGetSizeDocument() {
      setCguDocumentSize(await getSizeDocument(cguDocumentLink));
    }
    asyncGetSizeDocument();
  }, []);

  const onClickButton = () => {
    setLoadingButton(true);
    axios
      .put(
        `${process.env.REACT_APP_BACK_URL}/api/skynet/contact`,
        JSON.stringify({ validatedCgu: true }),
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem('REACT_TOKEN_AUTH_KEY')
            )}`,
          },
        }
      )
      .then((res) => {
        setLoadingButton(false);
        setShow(false);
        setConditionChecked(false);
      })
      .catch((error) => {});
  };

  return (
    <Modal
      show={show}
      className="modal-cgu"
      noHeader={true}
      data-cy="modal-cgu"
    >
      <h2 className="fw-bold text-primary text-center">
        Mise à jour de nos Conditions d’Utilisation
      </h2>
      <p className="content">
        Les fonctionnalités et Conditions Générales d’Utilisation de votre
        espace Client évoluent. Pour accéder à votre espace, veuillez prendre
        connaissance et accepter les nouvelles Conditions d’Utilisation.
      </p>
      <div className="form-inline px-0 col-12 p-0 pb-2 conditions-generales">
        <p>
          <span>
            Retrouvez le détail de nos Conditions d’Utilisation&nbsp;
            <Link
              to={cguDocumentLink}
              target="_blank"
              rel="noopener noreferrer"
              download
              className="link"
            >
              <u>
                dans ce document (PDF, Français, {cguDocumentSize} Ko).
                <img
                  className="stroke-primary file-download-icon not-disabled"
                  src={FileDownload}
                  alt="logo Edenred Solutions CSE (ex ProwebCE)"
                />
              </u>
            </Link>
          </span>
        </p>
        <Checkbox
          label="J’ai lu et j’accepte les Conditions Générales d’Utilisation de l’Espace Client"
          name="condition"
          id="condition"
          checked={conditionChecked}
          onChange={() => setConditionChecked(!conditionChecked)}
          data-cy="condition-cgu"
        />
      </div>
      <div className="text-center">
        <LoadingButton
          type="submit"
          classes="btn btn-primary rounded-pill btn-block "
          id="sendlogin"
          text="Valider les conditions"
          loading={loadingButton}
          spinnerHeight="20"
          disabled={!conditionChecked}
          onClick={onClickButton}
          dataCy="validate-cgu"
        />
      </div>
    </Modal>
  );
}
