import React from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import {
  useSubsidyWalletAmountRequest,
  useGiftVoucherBalanceRequest,
} from '@hooks/useApi';

import { ReactComponent as ChequeCadeau } from '@img/icons/cheque_cadeau_icon.svg';
import { ReactComponent as Subventions } from '@img/icons/subventions.svg';

import './wallet.scss';

export default function Wallet() {
  const {
    data: subsidyWalletAmountData,
    error: subsidyWalletAmountError,
    isLoading: subsidyWalletAmountIsLoading,
    isError: subsidyWalletAmountIsError,
  } = useSubsidyWalletAmountRequest();

  const {
    data: giftVoucherBalanceData,
    error: giftVoucherBalanceError,
    isLoading: giftVoucherBalanceIsLoading,
    isError: giftVoucherBalanceIsError,
  } = useGiftVoucherBalanceRequest();

  return (
    <div className="col-12">
      <h5 className="fw-bold text-primary rect_info_title">
        Mes porte-monnaies
      </h5>
      {subsidyWalletAmountIsLoading || giftVoucherBalanceIsLoading ? (
        <div className="col-12 skeleton">
          <SkeletonTheme width="100%">
            <section className="d-flex flex-column align-items-center justify-center w-100">
              <div className="d-flex justify-center flex-column w-100 gap-3">
                <Skeleton height={87} width="100%" className="" />

                <Skeleton height={87} width="100%" className="" />
              </div>
            </section>
          </SkeletonTheme>
        </div>
      ) : (
        <div className="h-100">
          <div className="wallets">
            <div className="wallet" data-cy="cheque-cadeau">
              <div className="solde">
                Solde :
                <span className="ps-1 amount">
                  {giftVoucherBalanceIsError
                    ? giftVoucherBalanceError?.response?.data?.value?.value
                    : giftVoucherBalanceData?.value}
                </span>
              </div>
              <span className="solde-app">Chèques Cadeaux</span>
              <div className="svg">
                <ChequeCadeau />
              </div>
            </div>
            <div className="wallet" data-cy="subventions">
              <div className="solde">
                Solde :
                <span className="ps-1 amount">
                  {!subsidyWalletAmountError && subsidyWalletAmountData?.value
                    ? subsidyWalletAmountData?.value?.toLocaleString()
                    : 'N.C €'}
                </span>
              </div>

              <span className="solde-app">Subventions</span>
              <Subventions />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
