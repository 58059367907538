import React, { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Feature from '@components/Feature/Feature';

import { convertMinsToHrsMins } from '@helpers';

import { ReactComponent as CalendarEmpty } from '@img/icons/calendar_empty.svg';
import { ReactComponent as Clock } from '@img/icons/clock.svg';
import { ReactComponent as Distance } from '@img/icons/distance.svg';
import { ReactComponent as Lieu } from '@img/icons/lieu.svg';
import { ReactComponent as Presentiel } from '@img/icons/presentiel.svg';
import { ReactComponent as Question } from '@img/icons/question.svg';
import { ReactComponent as Rappel } from '@img/icons/rappel.svg';
import { ReactComponent as User } from '@img/icons/user.svg';

import './card_rdv.scss';

export default function Card_rdv({
  hasButtons = true,
  hasDashboard = false,
  setShowModal,
  onClickCancel,
  setShowModalPlace,
  setDataModalPlace,
  data,
  loading,
}) {
  const [isPhysical, setIsPhysical] = useState(false);
  const [duration, setDuration] = useState(null);
  const [displayTeams, setDisplayTeams] = useState(false);

  useEffect(() => {
    if (data) {
      if (data.sousTypeAction === 'RDV physique') setIsPhysical(true);

      const dateEcheance = dayjs(data.dateEcheance);
      setDuration(dateEcheance.diff(data.dateDebut, 'minute'));

      const dayDiff = dayjs(data.dateDebut).diff(dayjs(), 'hour');
      if (dayDiff <= 24) setDisplayTeams(true);
    }
  }, [data]);

  return (
    <div className={`card card-rdv ${hasDashboard && 'card-rdv-dashboard'}`}>
      {!loading && data && (
        <div className="card-header d-flex">
          <>
            <div className="d-flex card-icon">
              <div className="icon">
                <CalendarEmpty className="float-start" />
              </div>

              <div className="text-start d-flex flex-column justify-content-center">
                <span className="text-primary title-span">Date</span>
                <h5 className="text-primary">
                  {dayjs(data.dateDebut).format('DD/MM/YYYY')}
                </h5>
              </div>
            </div>
            <div className="d-flex card-icon">
              <div className="icon">
                <Clock className="float-start" />
              </div>
              <div className="text-start d-flex flex-column justify-content-center">
                <span className="text-primary title-span">Heure</span>
                <h5 className="text-primary">
                  {dayjs(data.dateDebut).format('HH:mm')}
                </h5>
              </div>
            </div>
          </>
        </div>
      )}

      <div className="card-body">
        {loading ? (
          <div className="col-12 skeleton">
            <SkeletonTheme width="100%" height="100%">
              <section className="d-flex flex-column align-items-center justify-center w-100">
                <Skeleton height={20} width={210} className="mb-4" />

                <Skeleton height={10} width={30} className="mb-1" />
                <Skeleton
                  height={30}
                  width="100%"
                  className="mb-2 skeleton-input"
                />

                <div className="text-center w-100 d-flex flex-md-row flex-column ">
                  <Skeleton
                    height={52}
                    width="80%"
                    className="mb-2 mb-md-4 mb-md-0 rounded-pill mt-3"
                  />

                  <Skeleton
                    height={52}
                    width="80%"
                    className="mb-md-4 rounded-pill mt-md-3"
                  />
                </div>
              </section>
            </SkeletonTheme>
          </div>
        ) : (
          <div className="card-text d-flex flex-wrap flex-column flex-md-row mb-2">
            {data && data.utilisateursAssignes && (
              <div className="d-flex card-icon">
                <div className="icon">
                  <User className="float-start" />
                </div>
                <div className="text-start d-flex flex-column justify-content-center">
                  <span className="text-primary title-span">
                    Votre interlocuteur
                  </span>
                  <h5 className="text-primary fw-bold text-big">
                    {data.utilisateursAssignes[0]}
                  </h5>
                  <small className="text-placeholder">
                    Chargé de clientèle
                  </small>
                </div>
              </div>
            )}
            <div className="d-flex flex-wrap mb-2">
              {data && data.sousTypeAction && (
                <div className="d-flex card-icon">
                  <div className="icon">
                    <Question className="float-start" />
                  </div>
                  <div className="text-start d-flex flex-column justify-content-start">
                    <span className="text-primary title-span">Motif</span>
                    <h5 className="text-primary">{data.description}</h5>
                    {duration && (
                      <small className="text-placeholder">
                        Durée estimée : {convertMinsToHrsMins(duration)}
                      </small>
                    )}
                  </div>
                </div>
              )}
              {data &&
                data.sousTypeAction === 'RDV à distance' &&
                displayTeams === true && (
                  <div className="d-flex align-items-start justify-content-center justify-content-md-between btn-link">
                    <button
                      className="btn rounded-pill btn-primary fw-semi-bold"
                      disabled={data.location === '' ? true : false}
                      onClick={() => {
                        window.open(data.location, '_blank');
                      }}
                    >
                      Accéder à la réunion
                    </button>
                  </div>
                )}
            </div>
            {/* {data && data.sousTypeAction === "RDV demande de rappel" && data.location !== "" &&(
             */}
            {data &&
              data.sousTypeAction === 'RDV physique' &&
              data.location !== '' && (
                <div className="d-flex card-icon">
                  <div className="icon">
                    <Lieu className="float-start" />
                  </div>
                  <div className="text-start d-flex flex-column justify-content-start">
                    <span className="text-primary title-span">Lieu</span>
                    <h5 className="text-primary">{data.locationType}</h5>
                    <small
                      className="text-placeholder cursor-pointer"
                      onClick={() => {
                        if (typeof setShowModalPlace === 'function')
                          setShowModalPlace(true);
                        if (typeof setDataModalPlace === 'function')
                          setDataModalPlace(data);
                        if (typeof setShowModal === 'function')
                          setShowModal(false);
                      }}
                    >
                      <u className="text-capitalize">
                        {data.city
                          ? `À ${data.city.toLowerCase()}`
                          : "Voir l'adresse"}
                      </u>
                    </small>
                  </div>
                </div>
              )}
          </div>
        )}

        {!loading && hasButtons !== false && (
          // <div className="d-flex align-items-center justify-content-end flex-column flex-xl-row flex-wrap">
          <div className="d-flex align-items-center justify-content-between flex-column flex-xl-row flex-wrap">
            <Feature name="REPORT_RENDEZ_VOUS">
              <span
                data-tip
                data-for="buttonReport"
                data-tip-disable={!isPhysical}
                className="mb-3 mb-xl-0"
              >
                <Link
                  to={{ pathname: `/appointment/postpone/${data.id}` }}
                  className={`btn rounded-pill btn-white-border fw-semi-bold ${
                    isPhysical && 'disabled'
                  }`}
                  data-cy="postpone-button"
                >
                  Reporter le rendez-vous
                </Link>
              </span>
              <ReactTooltip
                id="buttonReport"
                place="bottom"
                type="info"
                effect="solid"
                className="reactTooltip"
              >
                <span>
                  Vous ne pouvez pas reporter un rendez-vous en présentiel.
                  Annulez ce rendez-vous et prenez un nouveau rendez-vous.
                </span>
              </ReactTooltip>
            </Feature>
            <div
              className="fw-semi-bold text-red-edenred text-annuler-rdv cursor-pointer"
              data-cy="cancel-button"
              onClick={() => onClickCancel(data.id)}
            >
              <u>Annuler le rendez-vous</u>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
