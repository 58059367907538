import React, { useEffect, useState } from 'react';

import LoadingButton from '@components/LoadingButton/LoadingButton';
import Switch from '@components/Switch/Switch';

import { useSkynetContactPut } from '@hooks/useApi';

import './communication.scss';

export default function Communication({ infoContact, isMeyclub, isLicence }) {
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);

  useEffect(() => {
    if (infoContact.optins) {
      setCheck1(infoContact.optins.commercial);
      setCheck2(infoContact.optins.ecommerce);
    }
  }, [infoContact.optins]);

  const {
    mutate: mutationSkynetContactPut,
    isLoading: isSkynetContactPutLoading,
  } = useSkynetContactPut();

  const onSubmit = (e) => {
    e.preventDefault();

    const optins = {
      commercial: check1,
      ecommerce: check2,
    };

    mutationSkynetContactPut(JSON.stringify({ optins: optins }));
  };

  return (
    <div className="communication">
      <div className="row">
        <div className="communication-content">
          <h5 className="fw-bold mb-4">Gestion des communications</h5>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <div className="form-check">
                {/* <input onChange={()=>setCheck1(!check1)} className="form-check-input d-md-none" type="checkbox" checked={check1} id="defaultCheck1"/> */}
                <Switch check={check1} setCheck={setCheck1} className="" />
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Vous serez informé lorsqu'un nouveau produit ou services
                  Edenred Solutions CSE (ex ProwebCE) sera disponible
                </label>
              </div>
              {(isMeyclub || isLicence) && (
                <div className="form-check">
                  {/* <input onChange={()=>setCheck2(!check2)} className="form-check-input d-md-none" type="checkbox" checked={check2} id="defaultCheck1"/> */}
                  <Switch check={check2} setCheck={setCheck2} className="" />
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Recevoir les nouveautés E-commerce
                  </label>
                </div>
              )}
            </div>
            <LoadingButton
              type="submit"
              classes="btn btn-primary rounded-pill btn-block "
              id="sendlogin"
              text="Enregistrer les modifications"
              loading={isSkynetContactPutLoading}
              spinnerHeight="20"
            />
          </form>
        </div>
      </div>
    </div>
  );
}

// 1er commercial

// si meyclub : recevoir les nouveautés ecommerce
