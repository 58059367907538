import React from 'react';

import Loader from '@components/Loader/Loader';

export default function Login() {
  // const {loading, toggleLoading} = useContext(LoadingScreenContext)

  // const link = 'https://sso.sbx.edenred.io/connect/authorize?response_type=code&client_id=52845fded667444098dd98a67e00739e&scope=openid&redirect_uri='+window.location.origin+'/&state=&nonce=123&acr_values=tenant:prowebce&ui_locales=fr'
  const link = process.env.REACT_APP_LOGIN_URL;

  window.location.href = link;
  return (
    <div className="min-height-full w-100 d-flex flex-column justify-content-center align-items-center text-center">
      <h1 className="d-block fw-bold mb-5 text-primary">Redirection ...</h1>
      <Loader />
    </div>
  );
}
