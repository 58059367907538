import React from 'react';

import dayjs from 'dayjs';

import LoadingButton from '@components/LoadingButton/LoadingButton';

import { useResiliationPost } from '@hooks/useApi';

import { ReactComponent as ArrowLeft } from '@img/icons/arrow-left.svg';

export default function Recapitulatif({ dataForm, setCurrentSlide }) {
  const [mailHasBeenSent, setMailHasBeenSent] = React.useState(false);
  const {
    mutate: mutateResiliationPost,
    isLoading: isResiliationLoading,
    isError: isResiliationError,
  } = useResiliationPost({
    onSettled: (data, error, variables, context) => {
      if (data?.result === false) {
        const errorMessage = data?.message || "L'un des champs est incorrect";
        throw new Error(errorMessage);
      } else if (data?.message === 'Email sent successfully') {
        setMailHasBeenSent(true);
      }
    },
  });

  return (
    <div className="px-3">
      <div
        className="d-flex align-items-center mb-4 fw-bold text-primary cursor-pointer"
        onClick={() => setCurrentSlide(0)}
      >
        <ArrowLeft className="me-2" />
        Retour aux informations
      </div>
      {isResiliationError && (
        <div className="text-center" role="alert">
          <p className="text-error fw-bold">
            Le mail n'a pas pu être envoyé, car une erreur est survenue ou
            certaines informations sont incorrectes.
          </p>
        </div>
      )}
      <h6 className="fw-bold text-primary">Votre demande de résiliation</h6>
      <p className="text-primary mb-4">
        <small className="d-block mb-2">
          Merci de vérifier les informations saisies et de confirmer votre
          demande.
        </small>
      </p>
      <div className="row col-12 p-0 pb-2">
        <div className="form-group col-12 col-md-4">
          <label htmlFor="inputId">Numéro client</label>
          <p className="h6 info-recap border-bottom">{dataForm?.id}</p>
        </div>
        <div className="form-group col-12 col-md-4">
          <label htmlFor="inputName">Votre nom</label>
          <p className="h6 info-recap border-bottom">{dataForm?.name}</p>
        </div>
        <div className="form-group col-12 col-md-4">
          <label htmlFor="inputFirstname">Votre prénom</label>
          <p className="h6 info-recap border-bottom">{dataForm?.firstname}</p>
        </div>
        <div className="form-group col-12 col-md-6">
          <label htmlFor="inputMailCSE">
            Votre mail au sein de votre organisme
          </label>
          <p className="h6 info-recap border-bottom text-break">
            {dataForm?.mailCSE}
          </p>
        </div>
        <div className="form-group col-12 col-md-6">
          <label htmlFor="inputFonctionCSE">
            Votre fonction au sein de votre organisme
          </label>
          <p className="h6 info-recap border-bottom">
            {dataForm?.fonctionCSE?.label}
          </p>
        </div>
      </div>

      <h6 className="fw-bold text-primary mt-4">
        Service(s) que vous souhaitez résilier
      </h6>
      <div className="row col-12 p-0 pb-2">
        <ul className="col-12 ps-5 text-primary">
          {dataForm?.service?.map((service, index) => (
            <li className="" key={`service-${index}`}>
              {service}
            </li>
          ))}
        </ul>
      </div>
      <div className="row">
        <div className="row mb-3 col-12 pe-0">
          <h6 className="fw-bold text-primary mt-4">
            Motif de résiliation :{' '}
            {dataForm?.otherMotifResiliation ??
              dataForm?.motifResiliation?.label}
          </h6>
          <h6 className="fw-bold text-primary mt-4">
            Date de résiliation souhaitée :{' '}
            {dayjs(dataForm?.dateResiliation).format('DD/MM/YYYY')}
          </h6>
        </div>
      </div>
      <div
        className="col-12 border border-primary text-center text-primary py-3 px-4 fs-7"
        id="info-mail"
      >
        Afin de s'assurer que vous recevrez bien les informations liées à votre
        demande et dans le but de sécuriser l'envoi d'informations concernant
        votre organisme, nous vous demandons de bien vouloir confirmer votre
        demande en cliquant sur le lien qui apparait dans le mail qui sera
        envoyé. Ce lien est valable pour une durée de 24 heures. <br />
        <strong className="d-block my-2">
          Sans confirmation de votre part, votre demande ne pourra pas être
          traitée.
        </strong>
      </div>
      {mailHasBeenSent ? (
        <div className="text-center fs-7 mt-4" role="alert">
          <span className="fw-bold d-block">Le mail est envoyé !</span>
          <span>
            Merci de vérifier votre boite mail, si vous n'avez pas reçu de mail,
            pensez à vérifier vos spams.
          </span>
        </div>
      ) : (
        <LoadingButton
          type="submit"
          classes="btn btn-primary rounded-pill fw-bold px-4 py-2 mt-3 d-block mx-auto"
          id="sendlogin"
          text="Envoyer le mail de confirmation"
          loading={isResiliationLoading}
          spinnerHeight="20"
          onClick={() => {
            mutateResiliationPost(dataForm);
          }}
        />
      )}
    </div>
  );
}
