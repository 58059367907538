import React, { useContext } from 'react';

import dayjs from 'dayjs';

import { CreateAppointmentContext } from '@context/CreateAppointmentContext';

import { convertMinsToHrsMins } from '@helpers';

import { ReactComponent as CalendarEmpty } from '@img/icons/calendar_empty.svg';
import { ReactComponent as Clock } from '@img/icons/clock.svg';
import { ReactComponent as Distance } from '@img/icons/distance.svg';
import { ReactComponent as Presentiel } from '@img/icons/presentiel.svg';
import { ReactComponent as Question } from '@img/icons/question.svg';
import { ReactComponent as Rappel } from '@img/icons/rappel.svg';
import { ReactComponent as User } from '@img/icons/user.svg';

import './card_rdv.scss';
import './recap_card_rdv.scss';

export default function Recap_card_rdv({
  data,
  infoResponsable,
  hasDashboard = false,
  setShowModal,
  setModalData,
}) {
  const { goToStep, motif, date, format, telephone } = useContext(
    CreateAppointmentContext
  );

  return (
    <div className="card card-rdv recap_card_rdv">
      <div
        className={`card-header d-flex ${hasDashboard && 'flex-wrap'}`}
      ></div>
      <div className="card-body">
        <div className="card-text d-flex flex-wrap">
          <div className="d-flex">
            <div className="d-flex card-icon card-date">
              <div className="icon">
                <CalendarEmpty className="float-start" />
              </div>

              <div className="text-start d-flex flex-column justify-content-center">
                <span className="text-primary title-span">Date</span>
                <h5 className="text-primary">
                  {dayjs(date).format('DD/MM/YYYY')}
                </h5>
              </div>
            </div>
            <div className="d-flex card-icon">
              <div className="icon">
                <Clock className="float-start" />
              </div>
              <div className="text-start d-flex flex-column justify-content-center">
                <span className="text-primary title-span">Heure</span>
                <h5 className="text-primary">{dayjs(date).format('HH:mm')}</h5>
              </div>
            </div>
            <div
              className="link-recap text-primary"
              onClick={() => goToStep(2)}
            >
              Modifier la date et l'heure
            </div>
          </div>
          <div className="d-flex card-icon">
            <div className="icon">
              <User className="float-start" />
            </div>
            <div className="text-start d-flex flex-column justify-content-center">
              <span className="text-primary title-span">
                Votre interlocuteur
              </span>
              <h5 className="text-primary fw-600">
                {/* {data.utilisateursAssignes[0]} */}
                {infoResponsable &&
                  infoResponsable?.prenom + ' ' + infoResponsable?.nom}
              </h5>
              <small className="text-placeholder">Chargé de clientèle</small>
            </div>
          </div>
          <div className="d-flex card-icon">
            <div className="d-flex">
              <div className="icon">
                <Question className="float-start" />
              </div>
              <div className="text-start d-flex flex-column justify-content-center">
                <span className="text-primary title-span">
                  Motif du rendez-vous
                </span>
                <h5 className="text-primary fw-600">{motif.subject}</h5>
                <small className="text-placeholder">
                  Durée estimée : {convertMinsToHrsMins(motif.duration)}
                </small>
              </div>
            </div>
            <div
              className="link-recap text-primary"
              onClick={() => goToStep(1)}
            >
              Changer le motif
            </div>
          </div>
        </div>
        {/* <CalendarLogo className="calendar_logo" /> */}
      </div>
    </div>
  );
}
