import React from 'react';

import dayjs from 'dayjs';

import Modal from '@components/Modal/ModalDefault';
import Table from '@components/Table/TableDefault';

import './ModalTimeline.scss';

const Modaltimeline = ({
  show,
  setShowModal,
  dataTimeline,
  billID,
  ...rest
}) => {
  const columns = [
    {
      title: '',
      data: null,
      defaultContent: '',
      orderable: false,
      className: 'control',
    },
    {
      title: 'Échéance',
      data: null,
      className: 'dt-left',
      render: function (data, type, row, meta) {
        if (meta.row === 0) {
          return '1ère échéance';
        } else {
          return meta.row + 1 + 'ème échéance';
        }
      },
    },
    {
      title: 'Date',
      data: 'datePaiement',
      className: 'dt-date dt-right',
      type: 'date-euro',
      render: function (data, type, row) {
        const date = dayjs(data).format('DD/MM/YYYY');
        return date !== 'Invalid date'
          ? '<span class="d-none">' + data + '</span>' + date
          : '';
      },
    },
    {
      title: 'Montant',
      data: 'montant',
      className: 'dt-right fw-bold',
      type: 'currency',
      render: function (data, type, row) {
        return data + ' €';
      },
    },
    {
      title: 'Statut',
      data: 'isPayed',
      className: 'dt-right fw-bold',

      render: function (data, type, row) {
        let classe = '';

        switch (data) {
          case 'Retard':
          case 'Rejeté':
            classe = 'text-error';
            break;
          case 'A venir':
            classe = 'text-start-color';
            break;
          default:
            classe = 'text-success';
        }

        return '<span class="' + classe + '">' + data + '</span>';
      },
    },
    {
      title: 'Paiement',
      data: null,
      orderable: false,
      className: 'dt-right fw-bold',
      render: function (data, type, row) {
        if (row.isPayed === 'Acquittée' && row.typePaiement !== undefined)
          return `Réglé par ${row?.typePaiement?.toLowerCase()}`;
        if (row.isPayed !== 'A venir') return '';
        return '<button type="button" class="btn btn-primary disabled" aria-disabled="true">Régler l\'échéance</button>';
      },
    },
  ];

  return (
    <Modal
      show={show}
      handleClose={() => {
        setShowModal(false);
      }}
      size="lg"
      className="modal_timeline"
      {...rest}
    >
      <h2 className="fw-bold text-primary text-center">
        Échéancier pour la facture {billID}
      </h2>
      {dataTimeline !== null ? (
        <Table
          name="billTimeline"
          data={dataTimeline}
          columns={columns}
          dataCy="bill-timeline-table"
          serverSide={false}
          dom="tp"
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

export default React.memo(Modaltimeline);
