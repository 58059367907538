import React, { useEffect, useState, useContext } from 'react';

import { useAuth } from '@authProvider';
import 'bootstrap/dist/js/bootstrap.min.js';
import $ from 'jquery';
// eslint-disable-line no-unused-vars
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import FirstConnect from '@components/FirstConnect/FirstConnect';
import FontAwesomeInit from '@components/FontAwesome/FontAwesome';
import Loader from '@components/Loader/Loader';
import Modal from '@components/Modal/ModalDefault';
import ModalCGU from '@components/ModalCGU/ModalCGU';

import { LoadingScreenContext } from '@context/LoadingScreenContext';
import { ProjectContext } from '@context/ProjectContext';
import { ToggleFeatureContext } from '@context/ToggleFeatureContext';
import { UserContext } from '@context/UserContext';

import {
  useSkynetContact,
  useSkynetContactFunction,
  useSkynetProjectClient,
  useToggleFeature,
} from '@hooks/useApi';

import { catchErrors, pushToDataLayer } from '@helpers';

import Maintenance from '@pages/maintenance/Maintenance';

import './App.scss';
import './Error.scss';
import './authProvider';
import './extraSlickOptions.js';
import Routes from './routes';

function App(props) {
  const [logged] = useAuth();
  const [error, setError] = useState(false);
  const [modalFirstconnection, setModalFirstconnection] = useState(
    localStorage.getItem('first-connection')
  );
  const [modalCGU, setModalCGU] = useState(false);
  const { loading, toggleLoading } = useContext(LoadingScreenContext);
  const { toggleHasProject } = useContext(ProjectContext);
  const { setUser } = useContext(UserContext);
  const { toggleFeature, setToggleFeature } = useContext(ToggleFeatureContext);

  const [maintenance, setMaintenance] = useState(false);

  useEffect(() => {
    catchErrors();
    if (!logged) {
      toggleLoading(false);
    }

    if (
      process.env.REACT_APP_STOP_DISPLAYING_COOKIES_BAR &&
      JSON.parse(process.env.REACT_APP_STOP_DISPLAYING_COOKIES_BAR) === true
    )
      document.body.classList.add('no-cookies-bar');

    $('body').on('click', '#close-pc-btn-handler', function (e) {
      e.preventDefault();

      let OneTrust = global.OneTrust;

      if (typeof OneTrust !== 'object') return;

      OneTrust.Close();
    });
    return () => {
      $('body').off('click', '#close-pc-btn-handler');
    };
  }, []);

  const { data: dataSkynetContact } = useSkynetContact({ enabled: !!logged });

  const { data: dataSkynetContactFunction } = useSkynetContactFunction({
    enabled: !!logged,
  });

  const {
    data: dataSkynetProjectClient,
    isLoading: isLoadingSkynetProjectClient,
    isError: isErrorSkynetProjectClient,
  } = useSkynetProjectClient({
    enabled: !!logged,
  });

  const { data: dataToggleFeature, isLoading: isLoadingToggleFeature } =
    useToggleFeature();

  useEffect(() => {
    if (dataSkynetContact) {
      if (
        localStorage.getItem('first-connection') !== 'true' &&
        dataSkynetContact.validatedCgu === false
      ) {
        setModalCGU(true);
      }

      const jsonClient = dataSkynetContact.client;
      let infoClient = {
        id: jsonClient.id,
        societe: jsonClient.societe,
        societeNbSalaries: jsonClient.societeNbSalaries,
      };
      localStorage.setItem('infoClient', JSON.stringify(infoClient));

      const jsonContact = dataSkynetContact.contact;
      let infoContactProvider = {
        nom: jsonContact.nom,
        prenom: jsonContact.prenom,
        mail: jsonContact.mail,
        platform: dataSkynetContact?.platform?.name,
        platformLink: dataSkynetContact?.platform?.link,
        meyClubId: dataSkynetContact?.client?.adhMeyclub,
        jiraPlatform:
          dataSkynetContact.equipment.plateforme !== 'Non Communiqué'
            ? dataSkynetContact.equipment.plateforme
            : 'P50',
      };
      setUser(infoContactProvider);

      pushToDataLayer({
        event: 'userinfo',
        user: {
          id: parseInt(dataSkynetContact.idContact),
          email: dataSkynetContact.mailPrincipal,
          client: {
            id: dataSkynetContact.client.id,
            name: dataSkynetContact.client.societe,
            gateway: dataSkynetContact?.platform?.name,
          },
        },
      });
    }
  }, [dataSkynetContact, setUser]);

  useEffect(() => {
    if (isLoadingSkynetProjectClient) return;
    if (
      isErrorSkynetProjectClient ||
      dataSkynetProjectClient?.length === 0 ||
      dataSkynetProjectClient?.length === undefined
    ) {
      toggleHasProject(false);
    } else {
      toggleHasProject(true);
    }
  }, [
    isErrorSkynetProjectClient,
    dataSkynetProjectClient,
    isLoadingSkynetProjectClient,
    toggleHasProject,
  ]);

  useEffect(() => {
    if (!isLoadingToggleFeature) {
      setToggleFeature(dataToggleFeature);
    }
  }, [dataToggleFeature, isLoadingToggleFeature, setToggleFeature]);

  useEffect(() => {
    if (
      dataSkynetContact &&
      dataSkynetContactFunction &&
      dataToggleFeature !== undefined &&
      toggleFeature !== null
    ) {
      toggleLoading(false);
    }
  }, [
    dataSkynetContact,
    dataSkynetContactFunction,
    dataToggleFeature,
    toggleLoading,
    toggleFeature,
  ]);

  return maintenance ? (
    <Maintenance />
  ) : (
    <Router>
      {loading === true ? (
        <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center text-center loading-screen">
          <h1 className="d-block fw-bold mb-5 text-primary">
            Chargement du site...
          </h1>
          <Loader />
        </div>
      ) : error ? (
        <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center text-center error-screen">
          <h1 className="d-block fw-bold mb-3">
            <i className="fas fa-exclamation-triangle text-danger me-3"></i>
            Erreur lors du chargement du site !
          </h1>
          <Link to={{ pathname: `/logout` }} className="logout">
            <div className="btn btn-block btn-white-border rounded-pill">
              Déconnexion
            </div>
          </Link>
        </div>
      ) : (
        toggleFeature && (
          <>
            <Routes />
            {localStorage.getItem('first-connection') === 'true' &&
              dataSkynetContact &&
              dataSkynetContactFunction && (
                <Modal show={modalFirstconnection === 'true'}>
                  <FirstConnect
                    infoContact={dataSkynetContact.contact}
                    infoClient={dataSkynetContact.client}
                    functionContact={dataSkynetContact.function}
                    listFunction={dataSkynetContactFunction}
                    setModalFirstconnection={setModalFirstconnection}
                  />
                </Modal>
              )}
            {logged && <ModalCGU show={modalCGU} setShow={setModalCGU} />}
            <ToastContainer />
          </>
        )
      )}
    </Router>
  );
}

export default App;
