import React, { createContext, useState } from 'react';

const ProjectContext = createContext();

export { ProjectContext };

const ProjectContextProvider = (props) => {
  const [hasProject, setHasProject] = useState(true);

  const toggleHasProject = (value) => {
    setHasProject(value);
  };

  return (
    <ProjectContext.Provider value={{ hasProject, toggleHasProject }}>
      {props.children}
    </ProjectContext.Provider>
  );
};

export default ProjectContextProvider;
