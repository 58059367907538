import React from 'react';

import { Link } from 'react-router-dom';

import CardRdv from '@components/Card_rdv/mini_card_rdv';

import GoogleTagManagement from '@GoogleTagManagement.jsx';

import { ReactComponent as CoupleCongrat } from '@img/coupleCongrat.svg';

export default function PostponeCongrat({ dataRecap }) {
  return (
    <div className="step rdv_pris">
      <GoogleTagManagement titlePage="Report rendez-vous confirmé" />
      <div className="title text-primary fw-bold text-center">
        <CoupleCongrat className="happy-people" />
        Très bien ! C’est reporté !
        <span>Vous allez recevoir une confirmation par e-mail</span>
      </div>
      <div className="mini-recap">
        <span className="text-primary">Récapitulatif de votre rendez-vous</span>
        {dataRecap !== null && (
          <CardRdv data={dataRecap} hasButton={false} hasRecap={true} />
        )}
      </div>
      <div className="text-center button">
        <Link
          to={{ pathname: '/appointment' }}
          className="btn btn-primary rounded-pill fw-600"
          data-cy="back-rdv-button"
        >
          Retour à Mes rendez-vous
        </Link>
      </div>
    </div>
  );
}
