import React, { useState, useContext, useEffect } from 'react';

import $ from 'jquery';
import { NavLink } from 'react-router-dom';

import { NewDocumentContext } from '@context/NewDocumentContext';
import { NewJiraContext } from '@context/NewJiraContext';
import { ProjectContext } from '@context/ProjectContext';
import { ToggleFeatureContext } from '@context/ToggleFeatureContext';

import { ReactComponent as InfoLogo } from '@img/icons/Info.svg';
import { ReactComponent as FactureLogo } from '@img/icons/Mes factures.svg';
import { ReactComponent as ProjetLogo } from '@img/icons/Mon projet.svg';
import { ReactComponent as EquipementLogo } from '@img/icons/Mon équipement.svg';
import { ReactComponent as Demande } from '@img/icons/begging-hand-ask-icon.svg';
import { ReactComponent as RdvLogo } from '@img/icons/calendrier.svg';
import { ReactComponent as DashboardLogo } from '@img/icons/dashboard.svg';
import { ReactComponent as DeleteLogo } from '@img/icons/delete.svg';
import { ReactComponent as MenuLogo } from '@img/icons/menuIcon.svg';
import { ReactComponent as UserLogo } from '@img/icons/user.svg';

import './Sidebar.scss';

export default function SidebarDefault() {
  const { hasProject } = useContext(ProjectContext);
  const { hasNewDocument } = useContext(NewDocumentContext);
  const { hasNewJira } = useContext(NewJiraContext);
  const { toggleFeature } = useContext(ToggleFeatureContext);
  const [toggled, setToggled] = useState(false);
  const [itemsMenu, setItemsMenu] = useState(null);

  useEffect(() => {
    let itemsMenuTab = [
      {
        icon: DashboardLogo,
        name: 'Mon tableau de bord',
        nameMobile: 'Accueil',
        path: '/dashboard',
        class: 'btn-link',
        disabled: false,
      },
      {
        icon: ProjetLogo,
        name: 'Mon projet',
        nameMobile: 'Projet',
        path: '/project',
        class: 'btn-link',
        disabled: !hasProject,
      },
      {
        icon: FactureLogo,
        name: 'Mes documents',
        nameMobile: 'Documents',
        path: '/documents-list',
        disabled: false,
        hasNotification: hasNewDocument,
      },
      {
        icon: EquipementLogo,
        name: 'Mon équipement',
        nameMobile: 'Équipement',
        path: '/equipment',
        class: 'btn-link',
        disabled: false,
      },
      {
        icon: UserLogo,
        name: 'Mon profil',
        nameMobile: '',
        path: '/profil',
        disabled: false,
      },
      {
        icon: InfoLogo,
        name: "Besoin d'aide",
        nameMobile: "Besoin d'aide",
        path: '/help',
        class: 'btn-link',
        disabled: false,
      },
    ];

    toggleFeature !== null &&
      toggleFeature['RENDEZ_VOUS'] &&
      itemsMenuTab.splice(2, 0, {
        icon: RdvLogo,
        name: 'Mes rendez-vous',
        nameMobile: 'Rendez-vous',
        path: '/appointment',
        class: 'btn-link',
        disabled: false,
      });

    toggleFeature !== null &&
      toggleFeature['DEMANDE_CLIENT'] &&
      itemsMenuTab.splice(4, 0, {
        icon: Demande,
        name: 'Mes demandes',
        nameMobile: 'Demandes',
        path: '/requests',
        disabled: false,
        hasNotification: hasNewJira,
      });

    setItemsMenu(itemsMenuTab);
  }, [hasNewDocument, hasNewJira, hasProject, toggleFeature]);

  const onClickNavBarItem = () => {
    $('#root').animate(
      {
        scrollTop: $('#root').offset().top,
      },
      0
    );
  };

  return (
    <div
      className={`bg-white ${toggled ? 'toggled' : ''}`}
      data-cy="sidebar-wrapper"
      id="sidebar-wrapper"
    >
      <div className="sidebar-heading text-center">MENU</div>
      <div
        className="button-menu-mobile d-flex d-lg-none"
        onClick={() => setToggled(!toggled)}
      >
        {toggled ? <DeleteLogo /> : <MenuLogo />}
        <span>{toggled ? 'Fermer' : 'Menu'}</span>
      </div>
      <div className="list-group list-group-flush">
        {/* <div className="button-menu-mobile d-flex d-md-none"></div> */}
        {itemsMenu &&
          itemsMenu.map((item, index) => {
            return (
              item.disabled === false && (
                <NavLink
                  key={index}
                  exact
                  activeClassName="active"
                  onClick={onClickNavBarItem}
                  isActive={(match, location) => {
                    let pathStrings = location.pathname.split('/');
                    if (match) {
                      return true;
                    } else if ('/' + pathStrings[1] === item.path) {
                      return true;
                    } else {
                      return false;
                    }
                  }}
                  to={{ pathname: item.path }}
                  className={`nav-link list-group-item-action order-${index} ${
                    item.class ? item.class : ''
                  } ${item.nameMobile === '' ? 'd-none d-md-block' : ''}`}
                >
                  <div className="d-flex" onClick={onClickNavBarItem}>
                    <div
                      className={`icon ${
                        item.hasNotification ? 'has-new-notification' : ''
                      }`}
                    >
                      <item.icon />
                    </div>
                    <p
                      className={`p-0 mb-0 d-none d-md-block ${
                        item.hasNotification ? 'has-new-notification' : ''
                      }`}
                    >
                      {item.name}
                    </p>
                    <p className="p-0 mb-0 d-md-none">{item.nameMobile}</p>
                  </div>
                </NavLink>
              )
            );
          })}
      </div>
    </div>
  );
}
