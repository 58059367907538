import React, { useState } from 'react';

import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';

import { Checkbox } from '@atoms';

import { SelectList, TextField } from '@molecules';

import LoadingButton from '@components/LoadingButton/LoadingButton';

import { useResiliationValidationPost } from '@hooks/useApi';

export default function Informations({ setDataForm, setCurrentSlide }) {
  const [motifResiliationInput, setMotifResiliationInput] = useState('');
  const [fonctionInput, setFonctionInput] = useState('');
  const optionMotifResiliation = [
    { value: 'Résiliation classique', label: 'Résiliation classique' },
    { value: 'Résiliation conservatoire', label: 'Résiliation conservatoire' },
    {
      value: 'Résiliation pour manquement',
      label: 'Résiliation pour manquement',
    },
    { value: 'Dissolution', label: 'Dissolution' },
    { value: 'Carence', label: 'Carence' },
    {
      value: 'Absorption avec liquidation',
      label: 'Absorption avec liquidation',
    },
    {
      value: "Baisse d'effectif / moins de 50 salariés",
      label: "Baisse d'effectif / moins de 50 salariés",
    },
    { value: 'Liquidation de la société', label: 'Liquidation de la société' },
    { value: 'Autres', label: 'Autres' },
  ];

  const services = [
    'Licence Start',
    'Licence Smart',
    'Licence Premium',
    'Cap CSE',
    'Enjoy',
    'Services et Licences',
    'Meyclub',
    'Vazyvit',
    'Livrassur',
    'Loisirs Soleil',
    'OdyCE',
    'CIE',
    'Licence application mobile',
    'Pack Assistance juridique',
  ];

  const errorMessage =
    "Les informations communiquées n'ont pas permis d'enregistrer votre demande, merci de les vérifier ou de contacter votre chargé(e) de clientèle.";

  const errorMessageAlreadyExist = `Une demande de résiliation est déjà en cours de traitement. Nous ne pouvons pas enregistrer votre nouvelle demande pour le moment.`;

  const {
    mutate: mutateResiliationValidationPost,
    isLoading: isResiliationValidationLoading,
    isError: isResiliationValidationError,
    error: resiliationValidationError,
  } = useResiliationValidationPost({
    onSettled: (data, error, variables, context) => {
      switch (data?.result) {
        case false:
          throw new Error(errorMessage);
        case 'exist':
          throw new Error(errorMessageAlreadyExist);
        case true:
          setDataForm(variables);
          setCurrentSlide(1);
          break;

        default:
          break;
      }
    },
  });

  const {
    register,
    handleSubmit,
    formState,
    control,
    errors,
    watch,
    setValue,
  } = useForm();

  const onSubmit = (data) => {
    mutateResiliationValidationPost({
      ...data,
      service: data.service.filter((e) => e !== undefined),
    });
  };

  return (
    <div className="px-3">
      <form onSubmit={handleSubmit(onSubmit)} className="informations">
        {isResiliationValidationError && (
          <div className="text-center" role="alert">
            <p className="text-error fw-bold">
              {resiliationValidationError?.response?.data?.status !== 500 &&
                resiliationValidationError?.message}
            </p>
          </div>
        )}
        <div data-cy="general-informations-section">
          <h5 className="fw-bold text-primary">Informations générales</h5>
          <p className="text-primary mb-4">
            <small className="d-block mb-2">
              Tous les champs sont obligatoires.
            </small>
          </p>

          <div className="row col-12 p-0 pb-2">
            <div className="form-group col-12 col-md-4">
              <label htmlFor="inputIdClient">Numéro client</label>
              <TextField
                title="Numéro client"
                name="id"
                required
                className="col-12 mt-2 mb-4"
                id="inputIdClient"
                {...register('id', { required: true })}
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <label htmlFor="inputName">Votre nom</label>
              <TextField
                name="name"
                required
                className="col-12 mt-2 mb-4"
                id="inputName"
                {...register('name', { required: true })}
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <label htmlFor="inputFirstname">Votre prénom</label>
              <TextField
                name="firstname"
                required
                className="col-12 mt-2 mb-4"
                id="inputFirstname"
                {...register('firstname', { required: true })}
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <label htmlFor="inputMailCSE">
                Votre mail au sein de votre organisme
              </label>
              <input
                type="email"
                name="mailCSE"
                required
                className="col-12 form-control mt-2 mb-4"
                id="inputMailCSE"
                {...register('mailCSE', { required: true })}
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <label htmlFor="inputFonctionCSE">
                Votre fonction au sein de votre organisme
              </label>
              <div className="position-relative pe-0">
                <Controller
                  control={control}
                  name="fonctionCSE"
                  rules={{
                    required: true,
                    validate: (value) =>
                      value.value === 'FONCTION_TRESORIER' ||
                      value.value === 'FONCTION_SECRETAIRE',
                  }}
                  render={({ field: { onChange, value, ref } }) => (
                    <SelectList
                      options={[
                        { value: 'FONCTION_TRESORIER', label: 'Trésorier' },
                        { value: 'FONCTION_SECRETAIRE', label: 'Secrétaire' },
                      ]}
                      defaultValue={formState?.defaultValues?.fonctionCSE}
                      placeholder=""
                      required
                      onChange={(val) => {
                        onChange(val);
                        setFonctionInput(val.value);
                      }}
                    />
                  )}
                />
                <input
                  tabIndex={-1}
                  autoComplete="off"
                  style={{
                    opacity: 0,
                    height: 'calc(100% - 1rem)',
                    position: 'absolute',
                    pointerEvents: 'none',
                    top: 0,
                    left: 0,
                    width: '100%',
                  }}
                  value={fonctionInput}
                  onChange={() => {}}
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>
        <h5 className="fw-bold text-primary mt-2">
          Service(s) que vous souhaitez résilier
        </h5>
        <p className="text-primary mb-4">
          <small className="d-block mb-2">
            Sélectionnez les services concernés par votre demande
          </small>
        </p>
        <div className="row mb-3">
          {services.map((e, i) => (
            <div className="col-12 col-sm-4 col-md-4 col-xl-4 mb-2" key={i}>
              <Controller
                name={`service[${i}]`}
                control={control}
                rules={{
                  required:
                    !watch('service') ||
                    watch('service') === undefined ||
                    watch('service').length === 0,
                }}
                render={({ field: { onChange } }) => (
                  <Checkbox
                    label={e}
                    name={'service'}
                    onChange={(event) => {
                      onChange(event.target.checked ? e : undefined);
                    }}
                    value={e}
                    id={`service-${i}`}
                    data-cy={`service-${i}`}
                  />
                )}
              />
            </div>
          ))}
        </div>
        <div className="row">
          <div className="row mb-3 col-md-6 pe-0">
            <p
              className="text-primary mb-0 col-12"
              data-cy="motif-resiliation-title"
            >
              <small className="d-block">Motif de résiliation</small>
            </p>
            <div className="form-group d-inline d-md-flex align-items-end row col-12 pe-0">
              <div className="position-relative pe-0">
                <Controller
                  control={control}
                  name="motifResiliation"
                  rules={{
                    required: true,
                    onChange: (e) => {
                      if (e.target.value !== 'Autre') {
                        setValue('otherMotifResiliation', null);
                      }
                    },
                  }}
                  render={({ field: { onChange, value, ref } }) => (
                    <SelectList
                      options={optionMotifResiliation}
                      className="col-md-4 pe-0 mb-3"
                      placeholder=""
                      aria-invalid={errors?.fonctionCSE ? 'true' : 'false'}
                      required
                      onChange={(val) => {
                        onChange(val);
                        setMotifResiliationInput(watch('motifResiliation'));
                      }}
                    />
                  )}
                />
                <input
                  tabIndex={-1}
                  autoComplete="off"
                  style={{
                    opacity: 0,
                    height: 'calc(100% - 1rem)',
                    position: 'absolute',
                    pointerEvents: 'none',
                    top: 0,
                    left: 0,
                    width: '100%',
                  }}
                  value={motifResiliationInput}
                  onChange={() => {}}
                  required={true}
                />
              </div>

              <p className="text-primary mb-1 col-12">
                <small className="d-block">Date de résiliation souhaitée</small>
              </p>

              <div className="form-group col-12 pe-0">
                <input
                  type="date"
                  defaultValue={dayjs().format('YYYY-MM-DD')}
                  name="dateResiliation"
                  required
                  className="col-12 form-control"
                  min={dayjs().format('YYYY-MM-DD')}
                  onInput={(e) => e.target.setCustomValidity('')}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(
                      `Veuillez renseigner une date de résiliation supérieure ou égale à la date du jour au format jj/mm/aaaa`
                    );
                  }}
                  id="dateResiliation"
                  {...register('dateResiliation', { required: true })}
                />
              </div>
            </div>
          </div>

          {watch('motifResiliation')?.value === 'Autres' && (
            <div className="form-group col-md-6 mb-3 pe-4 pe-md-0 d-flex flex-column justify-content-between">
              <label htmlFor="otherMotifResiliation">
                Veuillez préciser le motif
              </label>
              <textarea
                name="otherMotifResiliation"
                className="col-12 form-control mt-2 no-resize"
                rows="4"
                required
                id="otherMotifResiliation"
                {...register('otherMotifResiliation', {
                  required: true,
                  disabled: watch('motifResiliation')?.value !== 'Autres',
                })}
              />
            </div>
          )}
        </div>
        <div className="text-center">
          <LoadingButton
            type="submit"
            classes="btn btn-primary rounded-pill fw-bold px-4 py-2"
            id="sendlogin"
            text="Enregistrer mes informations"
            loading={isResiliationValidationLoading}
            spinnerHeight="20"
          />
        </div>
      </form>
    </div>
  );
}
