import React, { useEffect, useState } from 'react';

import { TextField } from '@molecules';

import LoadingButton from '@components/LoadingButton/LoadingButton';

import { useGandiCheck } from '@hooks/useApi';

import { ReactComponent as NotAllowed } from '@img/icons/not_allowed.svg';
import { ReactComponent as ValidGreen } from '@img/icons/valid_green_big.svg';

const domainNameRegex =
  /^(?!-)[A-Za-z0-9-]+([\-]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/;

const restrictedWords = [
  'prowebcse',
  'prowebce',
  'edenred',
  'meyclub',
  'kadeos',
];

export const DomainAvailabilityChecker = ({
  setSelectedDomain,
  isAvailable,
  setIsAvailable,
}) => {
  const [domainName, setDomainName] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isRestricted, setIsRestricted] = useState(false);

  const { data, isFetching, refetch } = useGandiCheck(domainName, {
    enabled: false,
  });

  useEffect(() => {
    if (data) {
      setIsAvailable(data.status === 'available');
      setIsRestricted(data.status === 'restricted');
    }
  }, [data]);

  const checkDomainAvailability = (e) => {
    e.preventDefault();
    setIsDisabled(true);
    refetch().then(() => {
      setIsDisabled(false);
    });
  };

  const onChangeHandler = (e) => {
    const { value } = e.target;
    setDomainName(value);
    setSelectedDomain(value);
    setIsAvailable(null);

    const isRestricted = restrictedWords.some((word) =>
      value.toLowerCase().includes(word)
    );
    setIsRestricted(isRestricted);
    setIsDisabled(
      !(value.length > 3 && domainNameRegex.test(value) && !isRestricted)
    );
  };

  return (
    <div>
      <form onSubmit={checkDomainAvailability} className="form-domain-checker">
        <div>
          <label htmlFor="inputDomainName">Saisissez un nom de domaine</label>
          <TextField
            id="inputDomainName"
            data-cy="label-domain-name"
            placeholder="ex: monsupercse.fr"
            onChange={onChangeHandler}
            value={domainName}
          />
        </div>
        <LoadingButton
          type="button"
          classes="btn rounded-pill"
          dataCy="check-domain"
          text="Vérifier la disponibilité"
          loading={isFetching}
          onClick={checkDomainAvailability}
          spinnerHeight="20"
          disabled={isDisabled}
        />
        <p
          className={`text-success availability ${
            isAvailable !== true && 'd-none'
          }`}
          data-cy="domain-available"
        >
          <ValidGreen />
          Disponible
        </p>

        <p
          className={`text-error availability ${
            isAvailable !== false && 'd-none'
          }`}
          data-cy="domain-unavailable"
        >
          <NotAllowed />
          Non disponible
        </p>
      </form>
      {isRestricted && (
        <div className="d-flex align-items-center w-75">
          <div className="flex-grow-1">
            <NotAllowed style={{ width: 15 }} />
          </div>
          <p className="text-error flex-shrink-1 mb-0 p-2">
            Vous ne pouvez pas réserver de nom de domaine comprenant une marque
            du groupe Edenred France.
          </p>
        </div>
      )}
    </div>
  );
};
