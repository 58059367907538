import React, { useContext, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { isEqual, size } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';

import { Checkbox, Radio } from '@atoms';

import { SelectList, TextField } from '@molecules';

import LoadingButton from '@components/LoadingButton/LoadingButton';

import { UserContext } from '@context/UserContext';

import {
  useSkynetContactPut,
  useSkynetAddressMeetingPut,
  useSkynetClientPut,
  useSkynetAddressPost,
} from '@hooks/useApi';

import { ReactComponent as Question } from '@img/icons/question.svg';

const InformationGeneralesInputs = ({
  infoContact,
  infoClient,
  listFunction,
  functionContact,
  setIsInput,
  setLoading,
}) => {
  const queryClient = useQueryClient();

  const options = listFunction.map((functionData) => {
    return { value: functionData['@id'], label: functionData.fonction };
  });

  const billingAddress =
    infoClient && infoClient?.adresses.find((address) => address.isBilling);

  const meetingAddress =
    infoClient && infoClient.adresses.find((address) => address.isMeeting);

  const { changeUserName } = useContext(UserContext);

  const [isIdenticalAddress, setIsIdenticalAddress] = useState(
    billingAddress && billingAddress.isMeeting
  );

  const sanitizedNumber = (number = '') => {
    let sanitizedNumber = number.replace(/[^0-9+]/g, '');

    const firstPlusIndex = sanitizedNumber.indexOf('+');

    if (firstPlusIndex === 0) {
      sanitizedNumber = '+' + sanitizedNumber.replace(/\D/g, '');
    } else {
      sanitizedNumber = sanitizedNumber.replace(/\D/g, '');
    }

    return sanitizedNumber;
  };

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      infoContact: {
        ...infoContact,
        telephonePortable: sanitizedNumber(
          infoContact?.telephonePortable ?? ''
        ),
        telephone: sanitizedNumber(infoContact?.telephone ?? ''),
        optins: null,
        ecContactDetails: {},
      },
      infoClient: {
        societeNbSalaries: infoClient.societeNbSalaries,
        dateElection: infoClient.dateElection
          ? dayjs(infoClient.dateElection).format('YYYY-MM-DD')
          : '',
      },
      billingAddress: billingAddress,
      meetingAddress: meetingAddress,
      isIdenticalAddress: isIdenticalAddress,
    },
  });

  const isIdenticalAddressHandler = (event) => {
    setIsIdenticalAddress(event.currentTarget.checked);
    setValue('billingAddress.isMeeting', event.currentTarget.checked);
    setValue('meetingAddress.isMeeting', !event.currentTarget.checked);
  };

  const onSuccess = () => queryClient.invalidateQueries(['skynet-contact']);

  const {
    mutateAsync: mutateSkynetContact,
    isError: SkynetContactIsError,
    isLoading: SkynetContactIsLoading,
  } = useSkynetContactPut({
    onSuccess: onSuccess,
    onError: (error) => {
      const errors = error?.response?.data?.errors ?? [];
      for (const error of errors) {
        setError(`infoContact.${error.property}`, {
          type: 'server',
          message: error.message,
        });
        document
          .querySelector(`[name="infoContact.${error.property}"]`)
          .scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
      }
    },
  });

  const {
    mutateAsync: mutateSkynetAddressMeeting,
    isError: skynetAddressMeetingHasError,
    isLoading: skynetAddressMeetingIsLoading,
  } = useSkynetAddressMeetingPut({
    onSuccess: onSuccess,
  });

  const {
    mutateAsync: mutateSkynetClient,
    isError: skynetClientHasError,
    isLoading: skynetClientIsLoading,
  } = useSkynetClientPut({
    onSuccess: onSuccess,
  });

  const {
    mutateAsync: mutateSkynetAddressBilling,
    isError: SkynetAddressBillingHasError,
    isLoading: SkynetAddressBillingIsLoading,
  } = useSkynetAddressPost({
    onSuccess: onSuccess,
  });

  const isLoading =
    SkynetContactIsLoading ||
    skynetAddressMeetingIsLoading ||
    skynetClientIsLoading ||
    SkynetAddressBillingIsLoading;

  const onSubmit = async (data) => {
    setLoading(true);

    const infoContactData = {
      ...infoContact,
      optins: null,
      ecContactDetails: {},
    };

    if (!isEqual(infoContactData, data.infoContact)) {
      changeUserName(data.infoContact.prenom);
      await mutateSkynetContact(JSON.stringify(data.infoContact));
    }

    if (!isEqual(billingAddress, data.billingAddress)) {
      await mutateSkynetAddressBilling(JSON.stringify(data.billingAddress));
    }

    if (!isEqual(meetingAddress, data.meetingAddress)) {
      await mutateSkynetAddressMeeting({
        data: JSON.stringify(data.meetingAddress),
        id: meetingAddress.id,
      });
    }

    const infoClientData = {
      societeNbSalaries: infoClient.societeNbSalaries,
      dateElection: infoClient.dateElection
        ? dayjs(infoClient.dateElection).format('YYYY-MM-DD')
        : '',
    };

    if (!isEqual(infoClientData, data.infoClient)) {
      await mutateSkynetClient(
        JSON.stringify({
          ...data.infoClient,
          societeNbSalaries: parseInt(data.infoClient.societeNbSalaries),
        })
      );
    }

    setIsInput(false);
    setLoading(false);
  };

  const phoneFormatWatcher = (e) => {
    const { value } = e.target;
    e.target.value = sanitizedNumber(value);
  };

  const getError = (name) => {
    const propertyPath = name.split('.');
    return errors[propertyPath[0]]?.[propertyPath[1]]?.message;
  };

  const Label = (label, inputId) =>
    label && <label htmlFor={inputId}>{label}</label>;

  const Input = (name, label, type = 'text', options = {}, style = {}) => {
    const isRadioOrCheckbox = ['radio', 'checkbox'].includes(type);
    const inputId =
      'input' +
      name.replace('.', '-') +
      (isRadioOrCheckbox ? options?.value ?? '' : '');
    const error = getError(name);

    return (
      <div className={`${style?.wrapper ?? ''} ${error ? 'error' : ''}`}>
        {!isRadioOrCheckbox && Label(label, inputId)}

        {type !== 'text' ? (
          <input
            type={type}
            id={inputId}
            className={style?.input ? style?.input : `form-control`}
            value={options?.value}
            {...register(name, options)}
            data-cy={inputId}
          />
        ) : (
          <TextField
            id={inputId}
            className={style?.input ? style?.input : ``}
            value={options?.value}
            {...register(name, options)}
            data-cy={inputId}
          />
        )}

        {isRadioOrCheckbox && Label(label, inputId)}
        {error && <span data-cy={`${inputId}-error`}>{error}</span>}
      </div>
    );
  };

  const onInvalid = () => setLoading(false);

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onInvalid)}
      className="informations-generales-inputs"
      noValidate={true}
    >
      <h5 className="fw-bold mx-3 text-primary">Informations générales</h5>
      <p className="obligation mx-3">
        Tous les champs sont obligatoires sauf mention contraire. Renseignez au
        moins 1 numéro de téléphone.
      </p>
      <div className="form-group col-md-4">
        <label htmlFor="inputCivilite">Civilité</label>
        <div className="form-group d-inline d-md-flex mt-1">
          <div className="me-2">
            <Controller
              name="infoContact.civilite"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Radio
                  label={'Monsieur'}
                  name="infoContact.civilite"
                  value="M"
                  id="inlineRadioCivilite1"
                  data-cy="radio-civilite-m"
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultChecked={value === 'M'}
                  ref={ref}
                />
              )}
            />
          </div>

          <div className="ms-2">
            <Controller
              name="infoContact.civilite"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Radio
                  label={'Madame'}
                  name="infoContact.civilite"
                  value="Mme"
                  id="inlineRadioCivilite2"
                  data-cy="radio-civilite-mme"
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultChecked={value === 'Mme'}
                  ref={ref}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="form-group col-md-6">
        {Input('infoContact.nom', 'Nom', 'text', {
          required: 'Le nom est obligatoire',
          minLength: {
            value: 3,
            message: 'Le nom doit contenir au moins 2 caractères',
          },
        })}
      </div>
      <div className="form-group col-md-6">
        {Input('infoContact.prenom', 'Prenom', 'text', {
          required: 'Le prénom est obligatoire',
          minLength: {
            value: 3,
            message: 'Le prénom doit contenir au moins 2 caractères',
          },
        })}
      </div>

      <h5 className="fw-bold mx-3 text-primary">Informations de contact</h5>
      <div className="form-inline col-12 p-0 pb-2 informations_contact_fonction_type_contact">
        <div className="form-group col-12 col-md-6">
          <label htmlFor="inputFonction">Fonction</label>
          <Controller
            name="infoContact.fonction"
            control={control}
            render={({ field }) => (
              <SelectList
                options={options}
                defaultValue={{
                  value: functionContact && functionContact['@id'],
                  label: functionContact && functionContact.fonction,
                }}
                onChange={(e) => field.onChange(e.value)}
              />
            )}
          />
        </div>
      </div>

      <div className="form-group col-md-6">
        {Input('infoContact.telephonePortable', 'Téléphone portable', 'tel', {
          onChange: phoneFormatWatcher,
        })}
      </div>

      <div className="form-group col-md-6">
        {Input('infoContact.telephone', 'Téléphone fixe', 'tel', {
          onChange: phoneFormatWatcher,
        })}
      </div>

      <div className="form-group col-md-12 preference-contact">
        <label htmlFor="InputTypeContact">
          Préférence de contact (facultatif)
        </label>
        <div className="form-inline">
          <div className="me-2 py-2">
            <Controller
              name="infoContact.preferenceAm"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox
                  label={'Matin'}
                  name="infoContact.preferenceAm"
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultChecked={value}
                />
              )}
            />
          </div>
          <div className="ms-2 py-2">
            <Controller
              name="infoContact.preferencePm"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox
                  label={'Après-midi'}
                  name={'infoContact.preferencePm'}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultChecked={value}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className="form-group col-md-6 disabled">
        <label htmlFor="InputEmail">
          Adresse e-mail (identifiant)
          <Question
            className="stroke-primary questionIcon not-disabled"
            data-tip
            data-for="questionSubventions"
          />
          <ReactTooltip
            id="questionSubventions"
            place="right"
            type="info"
            effect="solid"
            className="reactTooltipInformation"
          >
            <span>
              L’adresse e-mail étant aussi votre identifiant, il n’est pas
              possible de le modifier. Si la modification est nécessaire,
              contactez votre chargé de clientèle.
            </span>
          </ReactTooltip>
        </label>
        {Input(
          'infoContact.mail',
          null,
          'email',
          {},
          {
            input: 'form-control dontTake',
          }
        )}
      </div>

      <h5 className="fw-bold mx-3 text-primary">Adresse du CSE</h5>
      <div className="form-group col-md-6">
        {Input('billingAddress.adresseLine1', 'Adresse postale', 'text', {})}
      </div>
      <div className="form-group col-md-6">
        {Input(
          'billingAddress.adresseLine2',
          "Complément d'adresse (facultatif)",
          'text',
          {}
        )}
      </div>

      <div className="d-flex align-items-md-center flex-column flex-md-row col-12 p-0 pb-2">
        <div className="form-group col-12 col-md-2">
          {Input('billingAddress.codePostal', 'Code postal', 'text', {})}
        </div>
        <div className="form-group col-12 col-md-4 px-3">
          {Input('billingAddress.ville', 'Ville', 'text', {})}
        </div>
      </div>

      <div className="p-3">
        <Controller
          name="isIdenticalAddress"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Checkbox
              label={'L’adresse postale pour les rendez-vous est identique'}
              name={'isIdenticalAddress'}
              onChange={(e) => onChange(e) && isIdenticalAddressHandler(e)}
              onBlur={onBlur}
              defaultChecked={value}
            />
          )}
        />
      </div>

      <div
        className={`form-group col-md-6 ${isIdenticalAddress && 'disabled'}`}
      >
        {Input(
          'meetingAddress.adresseLine1',
          'Adresse postale pour les rendez-vous'
        )}
      </div>
      <div
        className={`form-group col-md-6 ${isIdenticalAddress && 'disabled'}`}
      >
        {Input('meetingAddress.adresseLine2', "Complément d'adresse")}
      </div>

      <div className="d-flex align-items-md-center flex-column flex-md-row col-12 p-0 pb-2">
        <div
          className={`form-group col-12 col-md-2 ${
            isIdenticalAddress && 'disabled'
          }`}
        >
          {Input('meetingAddress.codePostal', 'Code postal')}
        </div>
        <div
          className={`form-group col-12 col-md-4 px-3 ${
            isIdenticalAddress && 'disabled'
          }`}
        >
          {Input('meetingAddress.ville', 'Ville')}
        </div>
      </div>

      <h5 className="fw-bold mx-3 text-primary">
        Informations complémentaires
      </h5>
      <div className="form-inline col-12 p-0 pb-2">
        <div className="form-group col-md-2">
          {Input('infoClient.societeNbSalaries', 'Effectif de la société')}
        </div>
        <div className="form-group col-md-4 elections">
          {Input(
            'infoClient.dateElection',
            'Date des prochaines élections',
            'date'
          )}
        </div>
      </div>

      <div className="text-center">
        {((SkynetContactIsError && !size(errors.infoContact)) ||
          skynetAddressMeetingHasError ||
          skynetClientHasError ||
          SkynetAddressBillingHasError) && (
          <div className="text-error mt-4" role="alert">
            Une erreur est survenue, veuillez réessayer plus tard.
          </div>
        )}
        <LoadingButton
          type="submit"
          classes="btn btn-primary rounded-pill btn-block"
          id="sendlogin"
          text="Enregistrer les modifications"
          loading={isLoading}
          spinnerHeight="20"
        />
      </div>
    </form>
  );
};

export default InformationGeneralesInputs;
