import React from 'react';

import PropTypes from 'prop-types';

import './button.scss';

export const Button = ({
  style = 'primary',
  backgroundColor,
  classNames,
  size,
  label,
  ...props
}) => {
  return (
    <button
      className={[
        'btn',
        'btn-ec',
        'rounded-pill',
        `btn-${style}`,
        classNames,
      ].join(' ')}
      style={{
        backgroundColor,
        fontWeight: 600,
      }}
      {...props}
    >
      {label}
    </button>
  );
};

Button.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  style: PropTypes.oneOf(['primary', 'secondary']),
  /**
   * What background color to use
   */
  backgroundColor: PropTypes.string,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Button.defaultProps = {
  backgroundColor: null,
  style: 'primary',
  size: 'medium',
  onClick: undefined,
};
