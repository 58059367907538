import React, { useState, useEffect, useContext } from 'react';

import dayjs from 'dayjs';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Table from '@components/Table/TableDefault';
import { French } from '@components/Table/traductions/FrenchTradRequests';

import { NewJiraContext } from '@context/NewJiraContext';
import { UserContext } from '@context/UserContext';

import { useJiraProjectClient } from '@hooks/useApi';

import { ReactComponent as Man } from '@img/business-man-on-phone.svg';
import { ReactComponent as Women } from '@img/business-woman-handing-documents.svg';
import Calendar from '@img/icons/calendar_empty.svg';
import Corrige from '@img/icons/corrige.svg';
import EnCours from '@img/icons/en-cours.svg';
import Inconnu from '@img/icons/inconnu.svg';
import { ReactComponent as Question } from '@img/icons/question.svg';
import Rejete from '@img/icons/rejete.svg';

import './requests.scss';

export default function Requests() {
  const { setHasNewJira } = useContext(NewJiraContext);
  const { user } = useContext(UserContext);

  const {
    data: jiraProjectClient,
    isError: jiraProjectClientError,
    isLoading: jiraProjectClientLoading,
  } = useJiraProjectClient();

  useEffect(() => {
    if (jiraProjectClient) {
      const nbNew = jiraProjectClient.filter(
        (item) => item.new === true
      ).length;
      setHasNewJira(nbNew > 0);
    }
  }, [jiraProjectClient, setHasNewJira]);

  const [datatableState, setDatatableState] = useState(null); // eslint-disable-line no-unused-vars

  const columns = [
    {
      title: 'Status',
      data: 'status',
      className: 'dt-left status-td',
      orderable: false,
      render: function (data, type, row) {
        let image = Inconnu;
        let newString = '';
        if (data === 'En cours') image = EnCours;
        if (data === 'Rejeté') image = Rejete;
        if (data === 'Corrigé') image = Corrige;

        if (row.new === true) newString = '<span class="new">Nouveau</span>';
        return data && image
          ? '<span class="status">' +
              '<img src="' +
              image +
              '"/>' +
              '<span class="status-text">' +
              '<span data-cy="status">' +
              data +
              '</span>' +
              newString +
              '</span>' +
              '</span>'
          : '';
      },
    },
    {
      title: 'Titre',
      data: 'name',
      className: 'dt-left title-td',
      orderable: false,
      width: '100%',
      render: function (data, type, row) {
        return (
          `<span class="title" data-cy="title">${data}${
            row.rejectionReason &&
            '<div class="rejection-reason">' + row.rejectionReason + '</div>'
          }</span>` || ''
        );
      },
    },
    {
      title: 'Id',
      data: 'key',
      className: 'dt-left text-placeholder id-td',
      orderable: false,
      render: function (data, type, row) {
        return (
          '<span class="id"><small class="d-block">ticket n°</small><span data-cy="id-ticket"># ' +
            data +
            '</span></span>' || ''
        );
      },
    },
    {
      title: 'Date',
      data: 'versionDate',
      className: 'dt-date dt-left text-placeholder date-td',
      type: 'date-euro',
      render: function (data, type, row) {
        let date = dayjs(data).format('DD/MM/YYYY');
        let versionName = row.versionName;
        if (date === 'Invalid Date' || data === undefined) date = 'Aucune';

        let title = '';

        if (user?.jiraPlatform?.includes('P50') && row.versionName !== '')
          versionName = 'version';

        if (user?.jiraPlatform?.includes('V12') && row.versionName !== '') {
          const versionNameSplit = row.versionName.split('.');
          let versionString = '';

          versionString = `version `;
          versionString += versionNameSplit[0];
          if (versionNameSplit[1]) versionString += `.${versionNameSplit[1]}`;
          if (versionNameSplit[2]) versionString += `.${versionNameSplit[2]}`;

          versionName = versionString;
        }
        if (row.status === 'Corrigé') {
          if (row.versionName === '') {
            title = `livré le`;
          } else {
            title = `livré dans la <span data-cy="version-name">${versionName}</span> du`;
          }
        } else if (
          row.status === 'En cours' &&
          versionName !== '' &&
          row.versionDate !== ''
        ) {
          title = `prévu dans la <span data-cy="version-name">${versionName}</span> du`;
        } else {
          title = `date de correction prévisionnelle`;
        }

        if (row.status === 'Rejeté' || row.status === 'Inconnu')
          date = 'Aucune';
        if (row.status === 'Rejeté') title = '';

        return (
          '<span class="d-none">' +
          data +
          '</span><div class="content"><div class="text"><small class="d-block">' +
          title +
          '</small><span class="version-date" data-cy="version-date"><img src="' +
          Calendar +
          '"/>' +
          date +
          '</span></div></div>'
        );

        // return date !== "Invalid Date"
        //   ? '<span class="d-none">' + data + '</span><small class="d-block">date de correction</small>' + date
        //   : '<small class="d-block">date de correction</small>Aucune';
      },
    },
  ];

  return (
    <div className="w-100 requests">
      <div className="header-requests">
        <h3 className="title_page" data-cy="title_page">
          Mes demandes
        </h3>
        <Link
          to={{ pathname: '/help/contact' }}
          className="btn btn-primary rounded-pill contact"
          data-cy="btn-contact-us"
        >
          Contactez-nous
        </Link>
      </div>
      <div className="text-primary infos">
        Retrouvez ici uniquement les demandes prises en compte par le support
        technique et leur date de correction
        <Question
          className="stroke-primary questionIcon not-disabled"
          data-tip
          data-for="questionTypeContact"
          data-cy="logo-tooltip"
        />
        <ReactTooltip
          id="questionTypeContact"
          place="left"
          type="info"
          effect="solid"
          className="reactTooltipInformation"
        >
          <span>
            Les dates de correction communiquées pour les demandes en cours de
            traitement peuvent être modifiées à tout instant pour des raisons
            techniques.
            <br />
            <br />
            Seules les dates de correction correspondant à des sorties de
            version sont définitives.
          </span>
        </ReactTooltip>
      </div>

      <div className="row">
        <div className="col-12 rect_info rect_info_search_bill">
          <div className="col-12">
            {jiraProjectClientError ? (
              <h5 className="mb-0 mt-0 mt-md-3 text-error">
                <span className="fw-bold">
                  <i className="fas fa-exclamation-triangle text-error me-2"></i>
                  Erreur :
                </span>{' '}
                Un problème est survenu lors de la récupération des données.
              </h5>
            ) : (
              <div className="content">
                {jiraProjectClientLoading ? (
                  <div className="col-12 skeleton">
                    <SkeletonTheme width="100%">
                      <section className="d-flex flex-column align-items-center justify-center w-100 mt-0 mt-md-3">
                        <Skeleton
                          height={81}
                          count={7}
                          className="mb-1 line-table"
                        />
                      </section>
                    </SkeletonTheme>
                  </div>
                ) : jiraProjectClient && jiraProjectClient?.length ? (
                  <Table
                    data={jiraProjectClient}
                    columns={columns}
                    setDatatable={setDatatableState}
                    name="requests_table thead-disabled text-primary"
                    pageLength={10}
                    dom={'rt<"bottom"ip><"clear">'}
                    language={French}
                    order={[]}
                    dataCy="requests_table"
                    // dom={'lBfrtip'}
                  />
                ) : (
                  <div className="d-flex justify-content-center justify-content-md-between no-requests">
                    <Women />
                    <div className="content">
                      <p className="title">
                        Tout va bien, vos demandes ont toutes été traitées !
                      </p>
                      <p>
                        Si vous avez déclaré une anomalie et qu’elle n’apparait
                        pas ici, c’est qu’elle est en cours de traitement par
                        notre support technique.
                      </p>
                    </div>
                    <Man />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
