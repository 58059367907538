import React from 'react';

import { useMutation } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import { useSkynetActionsList, useSkynetActionsList20 } from '@hooks/useApi';

import { convertTimeToMs } from '@helpers';

import './appointment.scss';
import ProchainRdv from './prochain_rdv/prochain_rdv';
import RdvPasses from './rdv_passes/rdv_passes';

export default function Appointment() {
  const {
    data: dataListActions,
    isLoading: isLoadingListActions,
    isError: isErrorListActions,
  } = useSkynetActionsList({
    staleTime: convertTimeToMs(5, 'minutes'),
  });

  const {
    data: dataListActions20,
    isLoading: isLoadingListActions20,
    isError: isErrorListActions20,
  } = useSkynetActionsList20({
    staleTime: convertTimeToMs(5, 'minutes'),
  });

  const dataListActionsMutation = useMutation((updateListActions) => {
    dataListActions.data = {
      ...dataListActions.data,
      actions: updateListActions.actions,
      total: updateListActions.total,
    };
  });

  const dataListActions20Mutation = useMutation((updateListActions20) => {
    dataListActions20.data = {
      ...dataListActions20.data,
      actions: updateListActions20.actions,
      total: updateListActions20.total,
    };
  });

  const removeRdv = (id) => {
    const idRdvTab = dataListActions.data.actions.findIndex(
      (item) => item.id === id
    );
    let SaveProchainRdvData = dataListActions.data.actions;

    dataListActionsMutation.mutate({
      actions: SaveProchainRdvData.filter((_, i) => i !== idRdvTab),
      total:
        dataListActions.data.total >= 1 ? dataListActions.data.total - 1 : 0,
    });
  };

  return (
    <div className="w-100 appointment">
      <div className="header-appointment">
        <h3 className="title_page">Mes rendez-vous</h3>
        {!isLoadingListActions &&
          !isLoadingListActions20 &&
          dataListActions?.data?.total !== 0 && (
            <Link
              to={{ pathname: '/appointment/create' }}
              className="btn btn-primary rounded-pill contact"
            >
              <span className="desktop-text d-none d-md-inline">
                Prendre rendez-vous
              </span>
              <span className="mobile-text d-inline d-md-none">
                Prendre RDV
              </span>
            </Link>
          )}
      </div>

      {isErrorListActions || isErrorListActions20 ? (
        <div className="row">
          <div className="col-12 rect_info rect_info_search_bill">
            <div className="col-12">
              <h5 className="mb-0 text-error">
                <span className="fw-bold">
                  <i className="fas fa-exclamation-triangle text-error me-2"></i>
                  Erreur :
                </span>{' '}
                Un problème est survenu lors de la récupération des données.
              </h5>
            </div>
          </div>
        </div>
      ) : (
        <>
          {dataListActions?.data?.total !== 0 ? (
            <ProchainRdv
              data={dataListActions?.data?.actions}
              loading={isLoadingListActions}
              removeRdv={removeRdv}
            />
          ) : (
            <div className="row">
              <div className="col-12 rect_info rect_info_no_rdv">
                <div className="col-12 prochain_rdv">
                  <div className="row prochain_rdv_row">
                    <div className="col-12">
                      <h5 className="fw-bold text-primary rect_info_title">
                        Prochain rendez-vous
                      </h5>
                      <div className="content d-flex flex-column align-items-center">
                        <p className="text-placeholder mb-5">
                          <span className="d-block mb-2 fw-bold">
                            Vous n'avez pas de rendez-vous prévu prochainement.
                          </span>
                          <span className="">
                            Nous serions ravis d'échanger avec vous sur votre
                            utilisation d'Edenred Solutions CSE (ex ProwebCE),
                            vous présenter les nouveautés ou encore de faire un
                            bilan de votre contrat.
                          </span>
                        </p>
                        <Link
                          to={{ pathname: '/appointment/create' }}
                          className="btn btn-primary rounded-pill contact fw-semi-bold px-3 py-2 fw-600"
                        >
                          Prendre rendez-vous
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {dataListActions20?.data?.total !== 0 ? (
            <RdvPasses
              data={dataListActions20?.data?.actions}
              setData={dataListActions20Mutation.mutate}
              loading={isLoadingListActions20}
            />
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
}
