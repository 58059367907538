import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';

import { getQueryParameters } from '@helpers';

import { ReactComponent as ArrowLeft } from '@img/icons/arrow-left.svg';

import './resiliation.scss';
import Informations from './steps/informations';
import Recapitulatif from './steps/recapitulatif';
import Send from './steps/send';

export default function Resiliation() {
  const location = useLocation();
  const [numberSlide, setNumberSlide] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(
    getQueryParameters(location.search).verified === 'true' ? 2 : 0
  );
  const [dataForm, setDataForm] = useState(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: numberSlide,
    slidesToScroll: 1,
    centerMode: false,
    enterPadding: 6,
    arrows: false,
  };

  useEffect(() => {
    $('#root').animate(
      {
        scrollTop: $('#root').offset().top,
      },
      0
    );
  }, []);

  useEffect(() => {
    handleResize();

    function handleResize() {
      let containerWidth = $('.profil-container').width();
      $('.js-nav-link').outerWidth(containerWidth / 3 - 6);
      let itemLength = $('.js-nav-link').length;
      let itemWidth = $('.js-nav-link').outerWidth(true);
      let numberSlideCalc = containerWidth / itemWidth;
      setNumberSlide(
        numberSlideCalc > itemLength ? itemLength : numberSlideCalc
      );
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="py-3 profil-container col-md-8 container">
      {location?.state?.pathname && (
        <Link
          to={location?.state?.pathname}
          className="d-flex align-items-center mb-4 fw-bold text-primary"
        >
          <ArrowLeft className="me-2" />
          Retour
        </Link>
      )}

      <h4 className="fw-bold mb-4 ms-0 title_page">
        Effectuer une demande de résiliation
      </h4>
      <Slider
        {...settings}
        className={`slider slider-profil slider-ec nav nav-tabs`}
      >
        <li className="nav-item">
          <a
            className={classNames(
              'js-nav-link nav-link bg-primary',
              currentSlide === 0 && 'active'
            )}
            id="infos-tab"
            data-bs-toggle={currentSlide !== 2 ? 'tab' : null}
            href={currentSlide !== 2 ? '#infos' : null}
            role={currentSlide !== 2 ? 'tab' : null}
            aria-controls={currentSlide !== 2 ? 'infos' : null}
            aria-selected="true"
            onClick={() => currentSlide !== 2 && setCurrentSlide(0)}
          >
            Vos informations
          </a>
        </li>
        <li className="nav-item">
          <div
            className={classNames(
              'js-nav-link nav-link',
              currentSlide >= 1 && 'active'
            )}
            id="recapitulatif-tab"
            data-bs-toggle=""
            role={currentSlide !== 2 ? 'tab' : null}
            aria-controls={currentSlide !== 2 ? 'recapitulatif' : null}
            aria-selected="false"
            onClick={() => {
              if (dataForm !== null && currentSlide !== 2) {
                setCurrentSlide(1);
              }
            }}
          >
            Récapitulatif
          </div>
        </li>
        <li className="nav-item">
          <div
            className={classNames(
              'js-nav-link nav-link',
              currentSlide === 2 && 'active'
            )}
            id="send-tab"
            data-bs-toggle=""
            role="tab"
            aria-controls="send"
            aria-selected="false"
          >
            Demande enregistrée
          </div>
        </li>
      </Slider>
      <div className="tab-content rect_info" id="myTabContent">
        <>
          <div
            className={classNames(
              'tab-pane fade',
              currentSlide === 0 && 'show active'
            )}
            id="infos"
            role="tabpanel"
            aria-labelledby="infos-tab"
          >
            <Informations
              setDataForm={setDataForm}
              setCurrentSlide={setCurrentSlide}
            />
          </div>
          <div
            className={classNames(
              'tab-pane fade',
              currentSlide === 1 && 'show active'
            )}
            id="recapitulatif"
            role="tabpanel"
            aria-labelledby="recapitulatif-tab"
          >
            <Recapitulatif
              dataForm={dataForm}
              setCurrentSlide={setCurrentSlide}
            />
          </div>
          <div
            className={classNames(
              'tab-pane fade',
              currentSlide === 2 && 'show active'
            )}
            id="send"
            role="tabpanel"
            aria-labelledby="send-tab"
          >
            <Send />
          </div>
        </>
      </div>
    </div>
  );
}
