import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';
import dayjs from 'dayjs';
import $ from 'jquery';
import { createRoot } from 'react-dom/client';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { SelectList, TextField } from '@molecules';

import Table from '@components/Table/TableDefault';

import { NewDocumentContext } from '@context/NewDocumentContext';

import { ReactComponent as Delete } from '@img/icons/delete.svg';
import { ReactComponent as Search } from '@img/icons/search.svg';

export default function OtherDocuments({
  loading,
  error,
  data,
  setLoadingDownload,
}) {
  const [searchBarInput, setSearchBarInput] = useState('');
  const [selectValues, setSelectValues] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [datatableState, setDatatableState] = useState(null); // eslint-disable-line no-unused-vars
  const { nbNotView, decreaseNbNotView } = useContext(NewDocumentContext);

  useEffect(() => {
    if (data) {
      let tab = [];
      data.forEach((item) => {
        tab.push(item.typeName);
      });
      const newTab = [];

      tab
        .filter((value, index, self) => {
          return self.indexOf(value) === index && value !== null;
        })
        .forEach((item) => {
          newTab.push({ value: item, label: item });
        });

      setSelectValues(newTab);
    }
  }, [data]);

  const columns = [
    {
      title: '',
      data: null,
      defaultContent: '',
      orderable: false,
      className: 'control',
    },
    {
      title: 'Libellé',
      data: 'filename',
      className: 'dt-left libelle',
      orderable: false,
      // width: "125px",
      render: function (data, type, row) {
        if (data) {
          let renderReturn = '<span class="me-1">' + data + '</span>';

          if (!row.view)
            renderReturn +=
              '<span class="badge bg-new text-light">NOUVEAU !</span>';

          return renderReturn;
        }
        return '';
      },
      createdCell: function (td, cellData, rowData, row, col) {
        $(td).attr('data-search', cellData);
      },
    },
    {
      title: 'Type',
      data: 'typeName',
      className: '',
      visible: false,
      searchable: true,
    },
    {
      title: 'Date',
      data: 'createdAt',
      className: 'dt-date dt-right',
      type: 'date-euro',
      render: function (data, type, row) {
        const date = dayjs(data.date).format('DD/MM/YYYY');
        return date !== 'Invalid date'
          ? '<span class="d-none">' + data.date + '</span>' + date
          : '';
      },
    },
    {
      title: 'Action',
      data: null,
      orderable: false,
      className: 'dt-right',
      width: '210px',
      createdCell: (td, cellData, rowData, row, col) =>
        createRoot(td).render(
          <div className="d-flex justify-content-end bill-actions">
            <button
              type="button"
              onClick={() => goPdfViewer(rowData.id, td.closest('tr'))}
              className="btn btn-primary"
            >
              Consulter
            </button>
            <button
              type="button"
              onClick={() =>
                downloadDocument(
                  [rowData.id],
                  rowData.filename,
                  td.closest('tr')
                )
              }
              className="ms-2 btn btn-primary"
            >
              Télécharger
            </button>
          </div>
        ),
    },
  ];

  const onChangeSearchBar = (e) => {
    e.preventDefault();
    setSearchBarInput(e.target.value);
  };

  const onChangeSelect = (e) => {
    setSelectedType(e);
  };

  const goPdfViewer = (id, tr) => {
    deleteNewBadge(tr);
    let win = window.open(`/pdfViewer/${id}?isDocument=true`, '_blank');
    win.focus();
    // history.push(`/pdfViewer/${id}`);
  };

  const downloadDocument = (documents, name, tr) => {
    deleteNewBadge(tr);
    setLoadingDownload(true);
    axios
      .get(
        `${process.env.REACT_APP_BACK_URL}/api/skynet/document/${documents}`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem('REACT_TOKEN_AUTH_KEY')
            )}`,
          },
        }
      )
      .then((res) => {
        const linkSource = `${res.data.document}`;
        const downloadLink = document.createElement('a');
        let fileName = '';
        name
          ? (fileName = name.split(' ').join('_'))
          : (fileName = `document_${dayjs()
              .locale('fr')
              .format('DDMMYYYY')}.pdf`);

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        setLoadingDownload(false);
      })
      .catch((error) => {
        setLoadingDownload(false);
        // setError(true)
      });
  };

  const deleteNewBadge = (tr) => {
    decreaseNbNotView($(tr).closest('table').data('nb-view'));
    $('.libelle .badge', tr).addClass('d-none');
  };

  useEffect(() => {
    $('table.otherDoc').data('nb-view', nbNotView);
  }, [nbNotView]);

  return (
    <div>
      <div className="row">
        <div className="col-12 rect_info rect_info_search">
          {loading ? (
            <div className="col-12 skeleton">
              <SkeletonTheme width="100%">
                <section className="d-flex flex-column align-items-center justify-center w-100">
                  <Skeleton height={20} width="30%" className="mb-4" />
                  <Skeleton
                    height={20}
                    width="100%"
                    className="mb-0 search-bar"
                  />
                </section>
              </SkeletonTheme>
            </div>
          ) : (
            <div className="row mx-0 d-flex search-bar-container flex-column flex-md-row">
              <div className="col-12 col-md-6">
                <h5 className="rect_info_title">Rechercher par nom</h5>
                <form className="form-inline form-search d-flex flex-nowrap">
                  <TextField
                    className="form-control-sm me-3 w-100 border-0"
                    placeholder="Ex: CGV"
                    aria-label="Search"
                    onChange={(e) => onChangeSearchBar(e)}
                    value={searchBarInput}
                    data-cy="doc-search"
                  />

                  <Delete
                    className={`delete-icon ${
                      searchBarInput !== '' ? 'active' : ''
                    }`}
                    onClick={() => setSearchBarInput('')}
                  />
                  <Search className="search-icon" />
                </form>
              </div>
              <div className="col-12 col-md-6 select-filter-document mt-md-0">
                <h5 className="rect_info_title mb-0">Filtrer par type</h5>
                <form className="form-inline form-search d-flex flex-nowrap">
                  <SelectList
                    options={selectValues}
                    onChange={onChangeSelect}
                    isMulti
                    placeholder="Sélectionner un type"
                    className="text-primary "
                  />
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 rect_info rect_info_document">
          <div className="col-12">
            {loading ? (
              <div className="col-12 skeleton">
                <SkeletonTheme width="100%">
                  <section className="d-flex flex-column align-items-center justify-center w-100">
                    <div className="text-end w-100">
                      <Skeleton
                        // height={60}
                        height={30}
                        // width="55%"
                        width={300}
                        className="mb-4 rounded-pill button"
                      />
                    </div>
                    <Skeleton height={60} className="mb-2" />
                    <Skeleton
                      height={34}
                      count={10}
                      className="mb-1 line-table"
                    />
                  </section>
                </SkeletonTheme>
              </div>
            ) : error ? (
              <h5 className="mb-0 text-error">
                <span className="fw-bold">
                  <i className="fas fa-exclamation-triangle text-error me-2"></i>
                  Erreur :
                </span>{' '}
                Un problème est survenu lors de la récupération des données.
              </h5>
            ) : data.length > 0 ? (
              <div className="">
                <Table
                  name="otherDoc"
                  data={data}
                  columns={columns}
                  searchBarInput={searchBarInput}
                  selectedType={selectedType}
                  setDatatable={setDatatableState}
                  downloadDocument={downloadDocument}
                  nbView={nbNotView}
                  dataCy="doc-table"
                />
                <div className="contact">
                  <h5 className="fw-bold">
                    Vous avez une question sur vos documents ?
                  </h5>
                  <Link
                    to={{ pathname: '/help/contact' }}
                    className="btn btn-primary rounded-pill"
                  >
                    Contactez-nous
                  </Link>
                </div>
              </div>
            ) : (
              <h5 className="fw-bold text-primary">
                Vous n'avez aucun document à disposition
              </h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
