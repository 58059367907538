import React from 'react';

export default function CarouselItem({ title, number, isActive, isPassed }) {
  return (
    <div className={`nav-item`}>
      <a
        className={`js-nav-link nav-link ${
          isActive ? 'bg-primary' : 'bg-gris'
        } ${isPassed ? 'text-gris passed' : 'text-white'}`}
        id="infos-tab"
        data-bs-toggle="tab"
        href="#infos"
        role="tab"
        aria-controls="infos"
        aria-selected="true"
      >
        <span>{number + 1}</span>
        {title}
      </a>
    </div>
  );
}
